export default function () {
    return [
        // {
        //     title: "Blog Dashboard",
        //     to: "/blog-overview",
        //     htmlBefore: '<i class="material-icons">edit</i>',
        //     htmlAfter: "",
        //     display: false
        // },
        {
            title: "Home",
            to: "/edit-homepage",
            htmlBefore: '<i class="material-icons">home</i>',
            htmlAfter: "",
            display: false
        },
        {
            title: "Products",
            to: "/edit-products",
            htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
            htmlAfter: "",
            display: false
        },
        {
            title: "News & Event",
            htmlBefore: '<i class="material-icons">note_add</i>',
            to: "/edit-news",
            display: false
        },
        {
            title: "Projects",
            htmlBefore: '<i class="material-icons">work</i>',
            to: "/projects",
            display: false
        },{
            title: "Register project",
            htmlBefore: '<i class="material-icons">app_registration</i>',
            to: "/register-project",
            display: true
        },
        {
            title: "Solutions",
            htmlBefore: '<i class="material-icons">assignment_turned_in</i>',
            to: "/solutions",
            display: false
        },
        {
            title: "Downloads",
            htmlBefore: '<i class="material-icons">get_app</i>',
            to: "/downloads",
            display: false
        },
        {
            title: "Contacts",
            htmlBefore: '<i class="material-icons">contact_page</i>',
            to: "/contact",
            display: false
        },
        {
            title: "User Manager",
            htmlBefore: '<i class="material-icons">person</i>',
            to: "/users-manager",
            display: false
        },
        {
            title: "Warranty",
            htmlBefore: '<i class="material-icons">description</i>',
            to: "/warranty",
            display: false
        },
        {
            title: "About",
            htmlBefore: '<i class="material-icons">announcement</i>',
            to: "/about",
            display: false
        },
        {
            title: "Terms & Conditions",
            htmlBefore: '<i class="material-icons">security</i>',
            to: "/term",
            display: false
        },
    ];
}
