import React, {useState, useEffect, useContext} from 'react';
import Editor from '../@editor/@editor';
import {
    Button,
    Card,
    CardBody, CardFooter,
    Col,
    FormGroup,
    FormInput,
    Row,
    DatePicker
} from "shards-react";
import CustomFileUpload from "../components-overview/CustomFileUpload";
import Client from '../../shared/client';
import {store} from "react-notifications-component";
import {AuthContext} from "../../store/contexts/AuthContext";
import moment from "moment";
import SolutionState from '../../store/states/SolutionState';

const SolutionComponent = ({solution}) => {
    const [user,] = useContext(AuthContext);
    const [solutionData, setSolutionData] = useState(SolutionState);
    const [editor, setEditor] = useState(null);
    const [isBtnCreateDisabled, setBtnCreateDisabled] = useState(true);

    useEffect(() => {
        setSolutionData(solution);
    }, [solution])

    useEffect(() => {
    }, [solutionData])

    const onChange = ({target}) => {
        const {name, value} = target;
        setSolutionData({
            ...solutionData,
            [name]: value
        })
        if (editor === null || solutionData.title.trim() === '' || editor.getData().trim() === '') {
            setBtnCreateDisabled(true);
        } else setBtnCreateDisabled(false);
    }

    const onSubmit = () => {
        const _solutionData = {...solutionData};
        delete _solutionData.creator;
        delete _solutionData.id;
        _solutionData.content = editor.getData();
        if (!solutionData.id) {
            Client('solution', 'POST', _solutionData).then(({data}) => {
                store.addNotification({
                    title: "Create successful",
                    message: `Solution: ${data.title} was created successfully.`,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            }).catch(e => {
                store.addNotification({
                    title: "Error!",
                    message: `Failed to create solution: ${solutionData.title}.`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            })
        } else {
            Client(`solution/${solutionData.id}`, 'PATCH', _solutionData).then(({data}) => {
                store.addNotification({
                    title: "Update successful",
                    message: `Solution: ${data.title} was updated successfully. You will be redirect to Projects page in 3s.`,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
                setTimeout(() => {
                    window.location.assign('/solutions');
                }, 3000)
            })
        }
    }

    const isAllowed = () => {
        if (!solutionData.id) return true;
        if (user.roles.includes('ADMIN')) return true;
        else return solution.creator.id === user.id;
    }

    const onDel = () => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            Client(`solution/${solutionData.id}`, 'DELETE').then(data => {
                store.addNotification({
                    title: "Delete successful",
                    message: `Solution: ${solutionData.title} was deleted successfully. You will be redirect to Projects page in 3s.`,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true
                    }
                });
                setTimeout(() => {
                    window.location.assign('/solutions');
                }, 3000)
            }).catch(e => {
                store.addNotification({
                    title: "Error!",
                    message: `Failed to delete solution: ${solutionData.title}. Message: ${e}`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            })
        }
    }

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col md={12} sm={12}>
                        <Row>
                            <Col md={8} sm={12}>
                                <FormGroup>
                                    <label htmlFor="p-title">Title</label>
                                    <FormInput placeholder="Solution title"
                                               name="title"
                                               className="mb-2" id="p-title"
                                               value={solutionData.title}
                                               onChange={e => {
                                                   onChange(e)
                                               }}/>
                                </FormGroup>
                            </Col>
                            <Col md={4} sm={12} style={{marginTop: 'auto'}}>
                                <FormGroup>
                                    <label htmlFor="p-date">Start Date</label>
                                    <br/>
                                    <DatePicker
                                        selected={moment(solutionData.startDate).toDate()}
                                        id="p-date"
                                        onChange={(value) => onChange({
                                            target: {
                                                name: 'startDate',
                                                value: moment(value).format('YYYY-MM-DD')
                                            }
                                        })}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <label htmlFor="p-image">Image</label>
                            <CustomFileUpload id="p-image" marginText="mb-2"
                                              link={solutionData.image.fileUrl}
                                              fileName={solutionData.image.originalName}
                                              onFileChange={(fileUrl, originalName) => {
                                                  const target = {
                                                      name: 'image',
                                                      value: {
                                                          fileUrl, originalName
                                                      }
                                                  };
                                                  onChange({target})
                                              }}/>
                        </FormGroup>
                        <Editor value={solutionData.content}
                                onChange={(editor) => {
                                    if (solutionData.title.trim() === '' || editor.getData().trim() === '') {
                                        setBtnCreateDisabled(true);
                                    } else setBtnCreateDisabled(false);
                                    setEditor(editor);
                                }}/>
                    </Col>
                </Row>
            </CardBody>
            <CardFooter style={{display: 'flex', justifyContent: 'flex-end'}}>
                {solutionData.id ?
                    <Button theme={'danger'} disabled={!isAllowed()}
                            onClick={() => onDel()}>Delete</Button> : <></>}
                <Button theme={'success'} disabled={!isAllowed() || isBtnCreateDisabled}
                        className="ml-5"
                        onClick={() => onSubmit()}>{solutionData.id ? 'Update' : 'Create'}</Button>
            </CardFooter>
        </Card>
    )
}

export default SolutionComponent;
