import React, { useState, useEffect } from 'react';
import { Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import UtProjectState from "../store/states/ProjectState";
import Client from '../shared/client';
import qs from 'query-string';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ProjectComponent from "../components/@project/@project-component";
import { store } from "react-notifications-component";
import UtProjectComponent from '../components/@ut-projects/@ut-project-component';

export const UtEditProjectPage = ({ location }) => {
    const [utProjectData, setUtProjectData] = useState(UtProjectState);

    useEffect(() => {
        const { id } = qs.parse(location.search);
        if (id) {
            Client(`business/${id}`, 'GET', undefined, true).then(({ data }) => {
                setUtProjectData(data);
            }).catch(e => {
                store.addNotification({
                    title: "Error!",
                    message: `Failed to refresh projects: ${e}.`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            })
        }
    }, [location])

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center'
            }}>
                <PageTitle subtitle="Cập nhật"
                    title="Dự án"
                    className="text-sm-left mb-3" />
                {/* Fix max width editor */}
                <div style={{ width: '100%' }}>
                    <Tabs>
                        <TabList>
                            <Tab>Update project</Tab>
                        </TabList>
                        <TabPanel>
                            {/* <ProjectComponent project={utProjectState} /> */}
                            <UtProjectComponent project={utProjectData} />
                        </TabPanel>
                    </Tabs>
                </div>
            </Row>
        </Container>
    )
}
