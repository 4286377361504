/*
 * Created by @tranphuquy19 on 28/12/2020
 * @author: tranphuquy19@gmail.com
 */
import React, {useContext, useState, useEffect} from 'react';
import {
    Button,
    Col,
    FormInput,
    FormSelect,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row, Tooltip
} from "shards-react";
import {ProductsContext} from "../../store/contexts/ProductsContext";
import {ProductState} from "../../store/states/ProductState";
import {HotKeys, configure} from 'react-hotkeys';
import Client from '../../shared/client';
import _ from 'lodash';
import {store} from 'react-notifications-component';
import CustomFileUpload from "../components-overview/CustomFileUpload";
import imageChecker from 'is-image';
import DataTransfer from 'fbjs/lib/DataTransfer';

configure({
    ignoreTags: ['input', 'select', 'textarea'],
    ignoreEventsCondition: function () {
    }
});

export const ModalProduct = ({product, state, toggle}) => {
    const [productsData,] = useContext(ProductsContext);
    const [productModal, setProductModal] = useState(product || ProductState);

    const {productCategories} = productsData;

    const [categorySelected, setCategorySelected] = useState('');

    const [kindsDropdown, setKindsDropdown] = useState([]);

    const [tooltipState, setTooltipState] = useState({open: false});

    const [filesState, setFilesState] = useState([]);

    useEffect(() => {
        setProductModal(product);
        onCategoryChange(product.kind.category.id);
        setFilesState([...product.images, ...product.files]);
    }, [product])

    const toggleTooltipState = () => {
        setTooltipState({open: !tooltipState.open});
    }

    const onChange = ({target: {name, value}}) => {
        const {productKinds} = productsData;
        if (name === 'kind' && !!value) value = productKinds.find(k => k.id === value);
        else if (name === 'kind') return;
        setProductModal({
            ...productModal,
            [name]: value
        })
    }

    useEffect(() => {
        const {productKinds} = productsData;
        setKindsDropdown(productKinds);
    }, [productsData])

    const onCategoryChange = (value) => {
        const {productKinds, productCategories} = productsData;
        if (!value) {
            setKindsDropdown(productKinds);
        } else {
            const {productKinds} = productCategories.find(c => c.id === value);
            setKindsDropdown([...productKinds]);
        }
        setCategorySelected(value);
    }

    const onDescriptionChange = ({target: {name, value}}, index) => {
        const _description = productModal.description;
        _description[index][name] = value;
        setProductModal({
            ...productModal,
            description: [..._description]
        })
    }

    const onDelDescription = (index) => {
        let _description = productModal.description
        _description.splice(index, 1);
        setProductModal({
            ...productModal,
            description: [..._description]
        })
    }

    const removeFile = (index) => {
        const _files = filesState;
        _files.splice(index, 1);
        setFilesState([..._files]);
    }

    const onDelete = async () => {
        try {
            const isConfirmed = window.confirm('Do you want to delete this!');
            if (!isConfirmed) return;
            await Client(`p/${productModal.id}`, 'DELETE');
            toggle();
            store.addNotification({
                title: "Delete successful",
                message: `Product ${productModal.name} has been successfully deleted`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        } catch (e) {
            store.addNotification({
                title: "Delete failed",
                message: `Product: ${productModal.name || 'Unknown'} has failed to delete`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        }
    }

    const onSave = async () => {
        const files = [];
        const images = [];
        filesState.forEach(f => {
            if (imageChecker(f.originalName || f.fileUrl)) images.push(f);
            else files.push(f);
        })
        const _product = _.pick(productModal, ['name', 'model', 'description', 'price', 'quantity', 'images', 'files'])
        _product.kindId = productModal.kind.id;
        _product.images = images;
        _product.files = files;
        if (!productModal.id) {
            try {
                await Client('p', 'POST', _product);
                store.addNotification({
                    title: "Create successful",
                    message: `Product ${_product.name} has been successfully created`,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
                setProductModal(ProductState);
            } catch (e) {
                store.addNotification({
                    title: "Create failed",
                    message: `Failed to create product: ${_product.name || 'Unknown'}`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            }
        } else {
            try {
                await Client(`p/${productModal.id}`, 'PATCH', _product);
                store.addNotification({
                    title: "Create successful",
                    message: `Product: ${_product.name} has been successfully updated`,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            } catch (e) {
                store.addNotification({
                    title: "Create failed",
                    message: `Product: ${_product.name || 'Unknown'} has failed to update`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            }
        }
        toggle();
    }

    const onPaste = (e, i) => {
        const data = new DataTransfer(e.clipboardData);
        const _text = data.getText();
        const result = [{key: "", value: ""}];

        _text.split(/\r?\n/).forEach((row) => {
            const trimmed = row.trim();
            if (trimmed) {
                const [key, value] = trimmed.split(/\t+/);
                if (!!key && !!value) result.push({key, value});
            }
        });
        const {description} = productModal;
        description.splice(i, 1, ...result);
        setProductModal({
            ...productModal,
            description
        });
    }

    const onFileChange = (fileUrl, originalName, index) => {
        const _files = filesState;
        _files[index] = {fileUrl, originalName};
        setFilesState([..._files]);
    }

    const onInsertNewLine = (i) => {
        const {description} = productModal;
        description.splice(i, 0, ...[{key: '', value: ''}]);
        setProductModal({
            ...productModal,
            description
        });
    }

    const onDeleteAll = () => {
        setProductModal({
            ...productModal,
            description: []
        });
    }

    return (
        <Modal open={state} toggle={toggle} size={'lg'} fade={false}>
            <ModalHeader>{product.id ? 'Edit' : 'Create new product'}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={4} sm={12}>
                        <FormInput placeholder="Product model name" name="model"
                                   value={productModal.model}
                                   onChange={onChange}/>
                    </Col>
                    <Col md={8} sm={12}>
                        <FormInput placeholder="Product name" name="name"
                                   value={productModal.name}
                                   onChange={onChange}/>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={4} sm={12}>
                        <FormSelect name="category"
                                    onChange={({target}) => onCategoryChange(target.value)}
                                    value={categorySelected}>
                            <option value={''}>--- Product Types ---</option>
                            {productCategories.map(k => {
                                return (
                                    <option value={k.id}
                                            key={k.id}>{k.name}
                                    </option>
                                )
                            })}
                        </FormSelect>
                    </Col>
                    <Col md={4} sm={12}>
                        <FormSelect name="kind"
                                    onChange={e => onChange(e)}
                                    value={productModal.kind.id}
                        >
                            <option value={''}>--- Product Types ---</option>
                            {kindsDropdown.map(k => {
                                return (
                                    <option value={k.id}
                                            key={k.id}>{k.name}</option>
                                )
                            })}
                        </FormSelect>
                    </Col>
                </Row>
                <Row className="mt-3">
                    {/*Properties Editor*/}
                    <Col md={6} sm={12}
                         style={{
                             borderWidth: '1px',
                             borderStyle: 'solid',
                             borderColor: '#eee',
                             borderRadius: '5px',
                             padding: '5px 3px'
                         }}>
                        <Row style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '5px 3px',
                            borderBottom: '1px solid #eee',
                            paddingBottom: '3px'
                        }}>
                            <span style={{
                                fontSize: '1.3rem',
                                fontWeight: 'bold'
                            }}>Properties</span>
                            <Button size={'sm'} onClick={() => {
                                setProductModal({
                                    ...productModal,
                                    description: [...productModal.description, {
                                        key: '',
                                        value: ''
                                    }]
                                })
                            }} id="tooltip">Add new row</Button>
                        </Row>
                        <Row style={{margin: '5px 3px'}}>
                            <Col md={12} sm={12} style={{padding: '0'}}>
                                <div style={{
                                    height: '35vh',
                                    overflowY: 'scroll'
                                }}>
                                    <table style={{width: '100%'}}>
                                        <tbody>
                                        {productModal.description.map((d, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <HotKeys
                                                            keyMap={{
                                                                onDelete: 'alt+x',
                                                                onInsertNewLine: 'alt+enter',
                                                                onDeleteAll: 'alt+del'
                                                            }}
                                                            handlers={{
                                                                onDelete: () => onDelDescription(i),
                                                                onInsertNewLine: () => onInsertNewLine(i),
                                                                onDeleteAll: () => onDeleteAll()
                                                            }}>
                                                            <FormInput
                                                                size={'sm'}
                                                                value={d.key}
                                                                name="key"
                                                                onPaste={(e) => onPaste(e, i)}
                                                                onChange={e => onDescriptionChange(e, i)}
                                                                placeholder="Property name"/>
                                                        </HotKeys>
                                                    </td>
                                                    <td>
                                                        <HotKeys
                                                            keyMap={{
                                                                onDelete: 'alt+x',
                                                                onInsertNewLine: 'alt+enter',
                                                                onDeleteAll: 'alt+del'
                                                            }}
                                                            handlers={{
                                                                onDelete: () => onDelDescription(i),
                                                                onInsertNewLine: () => onInsertNewLine(i),
                                                                onDeleteAll: () => onDeleteAll()
                                                            }}>
                                                            <FormInput
                                                                size={'sm'}
                                                                value={d.value}
                                                                name="value"
                                                                onPaste={(e) => onPaste(e, i)}
                                                                onChange={e => onDescriptionChange(e, i)}
                                                                placeholder="Value"/>
                                                        </HotKeys>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {/*Files Editor*/}
                    <Col md={6} sm={12}
                         style={{
                             borderWidth: '1px',
                             borderStyle: 'solid',
                             borderColor: '#eee',
                             borderRadius: '5px',
                             padding: '5px 3px'
                         }}>
                        <Row style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '5px 3px',
                            borderBottom: '1px solid #eee',
                            paddingBottom: '3px'
                        }}>
                            <span style={{
                                fontSize: '1.3rem',
                                fontWeight: 'bold'
                            }}>Files & Images</span>
                            <Button size={'sm'} onClick={() => {
                                setFilesState([
                                    ...filesState,
                                    {
                                        fileUrl: '',
                                        originalName: ''
                                    }
                                ])
                            }} id="tooltip">Add new item</Button>
                        </Row>
                        <Row style={{margin: '5px 3px'}}>
                            <Col md={12} sm={12} style={{padding: '0'}}>
                                <div style={{
                                    height: '35vh',
                                    overflowY: 'scroll'
                                }}>
                                    <table style={{width: '100%'}}>
                                        <tbody>
                                        {filesState.map(({
                                                             fileUrl,
                                                             originalName
                                                         }, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <CustomFileUpload
                                                            marginText={`mb-1`}
                                                            onFileChange={(fileUrl, originalName) => onFileChange(fileUrl, originalName, i)}
                                                            fileName={originalName}
                                                            link={fileUrl}/>
                                                    </td>
                                                    <td>
                                                        <Button outline pill
                                                                theme="danger"
                                                                className={'mb-1'}
                                                                onClick={() => removeFile(i)}>
                                                            Remove
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Row>
                    <Button theme={'success'}
                            onClick={() => onSave()}>Save &amp; Close</Button>
                    <Button theme={'secondary'} className="ml-5"
                            onClick={() => toggle()}>Cancel</Button>
                    <Button theme={'danger'} className="ml-5"
                            disabled={!productModal.id}
                            // disabled={true}
                            onClick={() => onDelete()}>Delete</Button>
                </Row>
            </ModalFooter>
            <Tooltip
                open={tooltipState.open}
                target="#tooltip"
                toggle={toggleTooltipState}
            >
                <b style={{fontSize: '1em'}}>Alt+X to delete the focused
                    row;&#13;Alt+Enter to insert new line;&#13;Ctrl+V to paste
                    specific document;&#13;Alt+Delete to delete all properties</b>
            </Tooltip>
        </Modal>
    )
}
