/*
 * Created by @tranphuquy19 on 05/12/2020
 * @author: tranphuquy19@gmail.com
 */

import React, { useContext } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormInput,
    ListGroupItem,
    Row,
} from 'shards-react';
import { UtHomeContext } from '../../store/contexts/UtHomeContext';
import {
    UT_ADD_SLIDER_IMAGE,
    UT_EDIT_ITEM_IMAGE_AT_INDEX,
} from '../../store/reducers/UtHomeReducer';
import CustomFileUpload from '../components-overview/CustomFileUpload';

export function UtEditSliderImagesHomePage() {
    const [homeData, dispatchHomeData] = useContext(UtHomeContext);
    const { sliderImages } = homeData;

    const onChange = (e, index) => {
        const { value, name } = e.target;
        dispatchHomeData({
            type: UT_EDIT_ITEM_IMAGE_AT_INDEX,
            payload: {
                index,
                key: name,
                value: value,
            },
        });
    };

    return (
        <Card small className="h-100">
            <CardHeader className="border-bottom">
                <Row
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 25px',
                        alignItems: 'center',
                    }}
                >
                    <h5 className="m-0">Image slides</h5>
                    <Button
                        theme="success"
                        onClick={() => {
                            dispatchHomeData({
                                type: UT_ADD_SLIDER_IMAGE,
                            });
                        }}
                    >
                        Add new item
                    </Button>
                </Row>
            </CardHeader>
            <CardBody className="pt-0">
                <ListGroupItem
                    className="px-1"
                    style={{
                        maxHeight: '400px',
                        minHeight: '200',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        paddingTop: 0,
                    }}
                >
                    {sliderImages.map((item, index) => {
                        return (
                            <div key={index}>
                                <Card small className="mb-1">
                                    <CardHeader className="border-bottom">
                                        <h6 className="m-0">
                                            Slide {index + 1}
                                        </h6>
                                    </CardHeader>
                                    <CardBody className="pt-0">
                                        <FormInput
                                            placeholder="Title"
                                            className="mb-2 mt-1"
                                            name="head1"
                                            value={item.head1}
                                            onChange={e => {
                                                onChange(e, index);
                                            }}
                                        />
                                        <FormInput
                                            placeholder="Sub-title 1"
                                            className="mb-2 mt-1"
                                            name="head2"
                                            value={item.head2}
                                            onChange={e => {
                                                onChange(e, index);
                                            }}
                                        />
                                         <FormInput
                                            placeholder="Sub-title 2"
                                            className="mb-2 mt-1"
                                            name="head3"
                                            value={item.head3}
                                            onChange={e => {
                                                onChange(e, index);
                                            }}
                                        />
                                        <div className="row mt-2">
                                            <div className="col-md-6">
                                                <FormInput
                                                    placeholder="Link text 1"
                                                    name="url1Text"
                                                    value={item.url1Text}
                                                    onChange={e => {
                                                        onChange(e, index);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <FormInput
                                                    placeholder="Link url 1"
                                                    name="url1"
                                                    value={item.url1}
                                                    onChange={e => {
                                                        onChange(e, index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-6">
                                                <FormInput
                                                    placeholder="Link text 2"
                                                    name="url2Text"
                                                    value={item.url2Text}
                                                    title="Để trống để không hiển thị"
                                                    onChange={e => {
                                                        onChange(e, index);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <FormInput
                                                    placeholder="Link url 2"
                                                    name="url1"
                                                    value={item.url2}
                                                    onChange={e => {
                                                        onChange(e, index);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <CustomFileUpload
                                            {...item}
                                            endpoint="ut-uploader"
                                            onFileChange={fileUrl => {
                                                onChange(
                                                    {
                                                        target: {
                                                            name: 'link',
                                                            value: fileUrl,
                                                        },
                                                    },
                                                    index,
                                                );
                                            }}
                                        />
                                        <Button
                                            className="mr-1"
                                            theme="danger"
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        'Are you sure you want to delete this. Nothing changes until you hit Save changes button',
                                                    )
                                                ) {
                                                    dispatchHomeData({
                                                        type: UT_EDIT_ITEM_IMAGE_AT_INDEX,
                                                        payload: { index },
                                                    });
                                                }
                                            }}
                                        >
                                            Remove
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                dispatchHomeData({
                                                    type: UT_EDIT_ITEM_IMAGE_AT_INDEX,
                                                    payload: { index },
                                                })
                                            }
                                        >
                                            Move to Top
                                        </Button>
                                    </CardBody>
                                </Card>
                            </div>
                        );
                    })}
                </ListGroupItem>
            </CardBody>
        </Card>
    );
}
