/*
 * Created by @tranphuquy19 on 20/02/2021
 * @author: tranphuquy19@gmail.com
 */
import React, {useContext} from 'react';

import {HomeContext} from "../../store/contexts/HomeContext";
import {
    Card,
    CardHeader,
    Col, Form, FormInput,
    ListGroup,
    ListGroupItem,
    Row
} from "shards-react";
import CustomFileUpload from "../components-overview/CustomFileUpload";
import {EDIT_OUR_STORY} from "../../store/reducers/HomeReducer";

const OurStoryCard = () => {
    const [homeData, dispatchHomeData] = useContext(HomeContext);

    const onStoryTextChange = (e) => {
        const {target: {name, value}} = e;
        dispatchHomeData({
            type: EDIT_OUR_STORY,
            payload: {
                ...homeData.ourStory,
                [name]: value,
            }
        })
    }

    const onFileChange = (fileUrl, originalName) => {
        dispatchHomeData({
            type: EDIT_OUR_STORY,
            payload: {
                ...homeData.ourStory,
                storyImage: {fileUrl, originalName}
            }
        })
    }

    return (
        <Card className="mb-4">
            <CardHeader className="border-bottom">
                <h6 className="m-0">Our Story</h6>
            </CardHeader>
            <ListGroup flush>
                <ListGroupItem className="p-3">
                    <Row>
                        <Col>
                            <Form>
                                <Row form>
                                    <Col md="12" className="form-group">
                                        <label htmlFor="storyText">Our story
                                            text</label>
                                        <FormInput
                                            name="storyText"
                                            id="storyText"
                                            type="tel"
                                            placeholder="We believe in creativity as one of the major forces of progress..."
                                            value={homeData.ourStory.storyText}
                                            onChange={e => onStoryTextChange(e)}
                                            required
                                        />
                                    </Col>
                                    <Col md="12" className="form-group">
                                        <label htmlFor="feHomeTitle">Our story
                                            image</label>
                                        <CustomFileUpload
                                            marginText={`mb-1`}
                                            onFileChange={(fileUrl, originalName) => onFileChange(fileUrl, originalName)}
                                            fileName={homeData.ourStory.storyImage.originalName}
                                            link={homeData.ourStory.storyImage.fileUrl}/>
                                    </Col>
                                    <Col md="12" className="form-group">
                                        <label htmlFor="storyLink">Our story
                                            reference link (when client click Read-full-story button)</label>
                                        <FormInput
                                            name="storyLink"
                                            id="storyLink"
                                            type="tel"
                                            placeholder="https://rizo-light.com/our-story"
                                            value={homeData.ourStory.storyLink}
                                            onChange={e => onStoryTextChange(e)}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </ListGroupItem>
            </ListGroup>
        </Card>
    )
}

export default OurStoryCard;
