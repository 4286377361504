/*
 * Created by @tranphuquy19 on 23/01/2021
 * @author: tranphuquy19@gmail.com
 */
import React, {useEffect, useState, useContext} from 'react';
import {HomeContext} from "../../store/contexts/HomeContext";
import Client from "../../shared/client";
import Select from 'react-select';
import {
    EDIT_TOP_PROJECTS
} from "../../store/reducers/HomeReducer";
import _ from 'lodash';

export const EditTopProject = () => {
    const [homeData, dispatchHomeData] = useContext(HomeContext);
    const [optionSelected, setOptionSelected] = useState([]);
    const [options, setOptions] = useState([]);

    const fetchProjects = async () => {
        const {data} = await Client('project', 'GET');
        return data;
    }

    useEffect(() => {
        const _fetchProjects = async () => {
            const _projects = await fetchProjects();
            const _options = _projects.map(({id, title}) => {
                return {value: id, label: title};
            });
            setOptions(_options);
        }
        _fetchProjects();
    }, [])

    useEffect(() => {
        const _optionSelected = homeData.topProjects.map(({value, label}) => {
            return {value, label};
        })
        setOptionSelected((_optionSelected));
    }, [options, homeData])

    const onOptionsChange = (selectedOptions) => {
        selectedOptions = selectedOptions || []; // selectedOptions === null nếu ko có phần tử được selected
        const results = selectedOptions.map(({value}) => {
            return _.find(options, {value});
        })
        dispatchHomeData({
            type: EDIT_TOP_PROJECTS,
            payload: results
        })
    }

    return(
        <>
            <h5>Select projects to show on the Home page!</h5>
            <Select options={options} isMulti={true} onChange={onOptionsChange}
                    value={optionSelected}/>
        </>
    )
}
