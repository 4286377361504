// import { newsState, NewsState } from "../states/NewsState";

export const FETCH_NEWS = "FETCH_NEWS";
export const FETCH_NEWS_BY_ID = "FETCH_NEWS_BY_ID";
export const ADD_NEWS = "ADD_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";

export const newsReducer = (state, { type, payload }) => {
    switch (type) {
        case FETCH_NEWS:
            state = payload;
            return state;
        case FETCH_NEWS_BY_ID:
            return {
                ...state
            }
        case ADD_NEWS:
            return {
                ...state
            };
        case UPDATE_NEWS:
            return {
                ...state
            };
        case DELETE_NEWS:
            return {
                ...state
            };
        default:
            return state;
    }
}
