/*
 * Created by @tranphuquy19 on 07/01/2021
 * @author: tranphuquy19@gmail.com
 */
import React, { useState, useContext } from 'react';
import { Button, Card, CardBody, CardHeader, Tooltip } from "shards-react";
import UtServicesContext from "../../store/contexts/UtServicesContext";
import { FETCH_UT_SERVICES } from "../../store/reducers/UtServicesReducer";
import Client from '../../shared/client';
import { AuthContext } from "../../store/contexts/AuthContext";
import config from "../../shared/config";
import moment from "moment";
import { store } from "react-notifications-component";

export const UtServiceTable = ({ history, ...e }) => {
    const [servicesData, dispatchServicesData] = useContext(UtServicesContext);
    const [user,] = useContext(AuthContext);
    const [tooltipState, setTooltipState] = useState(false);
    const [itemHovered, setItemHovered] = useState('');

    const stripHtml = (html) => {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    const onRefresh = () => {
        Client('business-type', 'GET', undefined, true).then(({ data }) => {
            dispatchServicesData({
                type: FETCH_UT_SERVICES,
                payload: [...data]
            })
            store.addNotification({
                title: "Làm mới dịch vụ thành công",
                message: `Bảng dịch vụ đã được cập nhật`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        }).catch(e => {
            store.addNotification({
                title: "Lỗi!",
                message: `Không thể làm mới bảng dịch vụ: ${e}.`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        })
    }

    const onEdit = (id) => {
        history.push(`/edit-ut-service?id=${id}`)
    }

    const renderEditBtn = ({ id }) => {
        // if (user.roles.includes('ADMIN') || (user.roles.includes('MANAGER') && (user.id === creatorId))) {
        //     return (
        //         <Button
        //             onClick={() => {
        //                 onEdit(id)
        //             }}>Edit</Button>
        //     )
        // }
        // return (
        //     <Button
        //         disabled={true}
        //         onClick={() => {
        //             onEdit(id)
        //         }}>Edit</Button>
        // )
        return (
            <Button
                onClick={() => {
                    onEdit(id)
                }}>Edit</Button>
        )
    }

    const viewService = (model) => {
        const win = window.open(`${config.UT_API_URL}/dich-vu/${encodeURIComponent(model)}`, '_blank');
        win.focus();
    }

    const getTableBody = () => {
        return servicesData.map((p, i) => {
            return (
                <tr key={i}>
                    <td>{i + 1}</td>
                    <td title={p.title}>{p.title.slice(0, 15)}</td>
                    <td title={stripHtml(p.content).slice(0, 63)}>{stripHtml(p.content).slice(0, 50) + '...'}</td>
                    <td>{p.utBusinesses.length}</td>
                    <td>{moment(p.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td id={`tt-${p.id}`}
                        onMouseEnter={() => setItemHovered(p.id)}
                        onMouseLeave={() => setItemHovered('')}
                    >{moment(p.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td>
                        {renderEditBtn(p)}

                        <Button theme={'success'}
                            className="ml-2"
                            onClick={() => {
                                viewService(p.id)
                            }}>View</Button>
                    </td>
                    <Tooltip target={`#tt-${p.id}`}
                        open={itemHovered === p.id && tooltipState}
                        toggle={() => setTooltipState(!tooltipState)}
                    >
                        <b>Created
                            at: {moment(p.createdAt).format('YYYY-MM-DD HH:mm:ss')}</b>
                    </Tooltip>
                </tr>
            )
        })
    }

    return (
        <Card small className="mb-4">
            <CardHeader className="border-bottom" style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <h6 className="m-0">Dịch vụ</h6>
                <Button theme={'warning'}
                    onClick={() => onRefresh()}>Làm mới</Button>
            </CardHeader>
            <CardBody className="p-0 pb-3">
                <table className="table mb-0"
                    style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                    <thead className="bg-light">
                        <tr>
                            <th scope="col" className="border-0">
                                #
                            </th>
                            <th scope="col" className="border-0">
                                Tiêu đề
                            </th>
                            <th scope="col" className="border-0">
                                Nội dung
                            </th>
                            <th scope="col" className="border-0">
                                Số Dự án
                            </th>
                            <th scope="col" className="border-0">
                                Ngày tạo
                            </th>
                            <th scope="col" className="border-0">
                                Ngày cập nhật
                            </th>
                            <th scope="col" className="border-0">
                                &nbsp;
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {getTableBody()}
                    </tbody>
                </table>
            </CardBody>
        </Card>
    )
}
