/*
 * Created by @tranphuquy19 on 05/12/2020
 * @author: tranphuquy19@gmail.com
 */

import React, { useContext } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormInput,
    ListGroupItem, Row
} from "shards-react";
import { HomeContext } from "../../store/contexts/HomeContext";
import {
    ADD_SLIDER_PRODUCT, EDIT_ITEM_PRODUCT_AT_INDEX, MOVE_TO_TOP_SLIDER_PRODUCT,
    REMOVE_SLIDER_PRODUCT
} from "../../store/reducers/HomeReducer";

function EditSliderProductsHomePage() {
    const [homeData, dispatchHomeData] = useContext(HomeContext);
    const { sliderProducts } = homeData;

    const onChange = (e, index) => {
        const { value, name } = e.target;
        dispatchHomeData({
            type: EDIT_ITEM_PRODUCT_AT_INDEX,
            payload: {
                index,
                key: name,
                value: value
            },
        })
    }

    return (
        <Card small className="h-100">
            <CardHeader className="border-bottom">
                <Row style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 25px',
                    alignItems: 'center'
                }}>
                    <h5 className="m-0">Slider Products</h5>
                    <Button theme="success"
                        onClick={() => dispatchHomeData({ type: ADD_SLIDER_PRODUCT })}>Add
            new item</Button>
                    {/*<Col sm={12} md={12} lg={5}>*/}
                    {/*</Col>*/}
                    {/*<Col sm={12} md={12} lg={5}>*/}
                    {/*</Col>*/}
                </Row>
            </CardHeader>
            <CardBody className="pt-0">
                <ListGroupItem className="px-1" style={{
                    maxHeight: '400px',
                    minHeight: '200',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    paddingTop: 0
                }}>

                    {sliderProducts.map((product, index) => {
                        return <div key={index}>
                            <Card small className="mb-1">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0">Product {index + 1}</h6>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    {/*<CustomFileUpload {...file}/>*/}
                                    <FormInput className="mt-1 mb-2" name="model"
                                        placeholder="#PRODUCT-MODEL" value={product.model}
                                        onChange={(e) => {
                                            onChange(e, index)
                                        }} />
                                    <Button className="mr-1" theme="danger"
                                        onClick={() => {
                                            if (window.confirm('Are you sure you want to delete this. Nothing changes until you hit Save changes button')) {
                                                dispatchHomeData({
                                                    type: REMOVE_SLIDER_PRODUCT,
                                                    payload: { index }
                                                })
                                            }
                                        }}>Remove</Button>
                                    <Button onClick={() => dispatchHomeData({
                                        type: MOVE_TO_TOP_SLIDER_PRODUCT,
                                        payload: { index }
                                    })}>Move to Top</Button>
                                </CardBody>
                            </Card>
                        </div>
                    })}
                </ListGroupItem>
            </CardBody>
        </Card>
    );
}

export default EditSliderProductsHomePage;
