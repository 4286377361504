/*
 * Created by @tranphuquy19 on 05/12/2020
 * @author: tranphuquy19@gmail.com
 */
const userState = {
  username: '',
  name: '',
  phoneNumber: '',
  address: '',
  email: '',
  avatarUrl: '',
  password: '',
  roles: [],
  createdAt: new Date(),
  updatedAt: new Date(),
};

export default userState;
