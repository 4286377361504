/*
 * Created by @tranphuquy19 on 05/12/2020
 * @author: tranphuquy19@gmail.com
 */

import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Container, Row } from 'shards-react';
import PageTitle from '../components/common/PageTitle.js';
import EditSliderImagesHomePage from '../components/@edit-image-slider-home-page/@EditSliderImagesHomePage.js';
import EditSliderProductsHomePage from '../components/@edit-image-slider-home-page/@EditSliderProductsHomePage.js';
import { UtHomeContext } from '../store/contexts/UtHomeContext.js';
// import { FETCH_DATA, homeReducer } from "../store/reducers/HomeReducer.js";
import {
    UT_EDIT_TOP_NEWS,
    UT_FETCH_DATA,
    utHomeReducer,
} from '../store/reducers/UtHomeReducer.js';
import { homeState } from '../store/states/HomeState.js';
import Client from '../shared/client.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { EditTopProduct } from '../components/@edit-top-product-home-page/@EditTopProductComponent.js';
import { store } from 'react-notifications-component';
import TagPageInfo from './@TagPageInfo.js';
import { ProductNavbarComponent } from '../components/@product-nav-bar/@ProductNavbarComponent.js';
import { EditTopNews } from '../components/@edit-top-news/@EditTopNewsComponent.js';
import { EditTopProject } from '../components/@edit-top-projects/@edit-top-projects.js';
import OurStoryCard from '../components/@out-story/@our-story-card.js';
import { HeaderPageImagesCard } from '../components/@header-page-images/HeaderPageImagesCard.js';
import { EditTopSolution } from '../components/@edit-top-solutions/@edit-top-solutions.js';
import { UtEditSliderImagesHomePage } from '../components/@ut-image-slider-home-page/@UtEditSliderImagesHomePage.js';
import { UtTagPageInfo } from './@UtTagPageInfo.js';
import { UtOurStoryCard } from '../components/@out-story/@ut-our-story-card.js';
import { UtHeaderPageImagesCard } from '../components/@header-page-images/UtHeaderPageImagesCard.js';
import { UtAboutUs } from '../components/@out-story/@ut-about-us.js';
import { UtOurValues } from '../components/@out-story/@ut-our-values.js';
import { UtOtherValues } from '../components/@out-story/@ut-other-values.js';
import { UtEditTopProject } from '../components/@edit-top-projects/@ut-edit-top-projects.js';
import { UtEditTopServices } from '../components/@edit-top-solutions/@ut-edit-top-services.js';
import { UtTestimonials } from '../components/@ut-testimonials/@ut-testimonials.js';
import { UtHappyClients } from '../components/@ut-happy-clients/@ut-happy-clients.js';
import { UtEditTopNews } from '../components/@edit-top-news/@UtEditTopNewsComponent.js';
import { UtEditTopProduct } from '../components/@edit-top-product-home-page/@UtEditTopProductComponent.js';

let counter = 0;

const UtEditHomePage = () => {
    const [utHomeData, dispatchUtHomeData] = useReducer(
        utHomeReducer,
        homeState,
    );
    const [isDisableSave, setDisableSave] = useState(true);

    useEffect(() => {
        Client('meta-data', 'GET', undefined, true)
            .then(({ data }) => {
                dispatchUtHomeData({
                    type: UT_FETCH_DATA,
                    payload: data,
                });
                counter++;
            })
            .catch(e => {
                store.addNotification({
                    title: 'Fetch data failed',
                    message: `Error when fetching data`,
                    type: 'danger',
                    insert: 'bottom',
                    container: 'bottom-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    },
                });
            });

        return () => {};
    }, []);

    useEffect(() => {
        if (counter++ > 2) setDisableSave(false);
    }, [utHomeData]);

    const onSave = () => {
        Client('meta-data', 'POST', utHomeData, true)
            .then(({ data }) => {
                dispatchUtHomeData({
                    type: UT_FETCH_DATA,
                    payload: data,
                });
                setDisableSave(true);
                store.addNotification({
                    title: 'Update successful',
                    message: `Home page has been successfully updated`,
                    type: 'success',
                    insert: 'bottom',
                    container: 'bottom-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    },
                });
            })
            .catch(e => {
                store.addNotification({
                    title: 'Update failed',
                    message: `Home page has failed to update`,
                    type: 'danger',
                    insert: 'bottom',
                    container: 'bottom-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    },
                });
            });
    };

    return (
        <UtHomeContext.Provider value={[utHomeData, dispatchUtHomeData]}>
            <Container fluid className="main-content-container px-4">
                <Row
                    noGutters
                    className="page-header py-4"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                    }}
                >
                    <PageTitle
                        title="Home"
                        subtitle="Page Manager"
                        className="text-sm-left mb-3"
                    />
                    <Button
                        theme="primary"
                        pill
                        className="mr-3"
                        disabled={isDisableSave}
                        onClick={onSave}
                    >
                        Save changes
                    </Button>
                </Row>
                <Tabs>
                    <TabList>
                        <Tab>Slider</Tab>
                        <Tab>Top Products</Tab>
                        <Tab>Top Projects</Tab>
                        <Tab>Top Services</Tab>
                        <Tab>Top News</Tab>
                        <Tab>Navigation Bar</Tab>
                        <Tab>Page Info.</Tab>
                    </TabList>
                    <TabPanel>
                        <Row>
                            <Col lg="6" md="12" sm="12" className="mb-4">
                                <UtEditSliderImagesHomePage />
                            </Col>
                            <Col lg="6" md="12" sm="12" className="mb-4">
                                {/* <EditSliderProductsHomePage {...utHomeData} /> */}
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-4">
                                <UtEditTopProduct />
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-4">
                                <UtEditTopProject />
                                {/* <EditTopProject /> */}
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-4">
                                {/* <EditTopSolution /> */}
                                <UtEditTopServices />
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-4">
                                {/* <EditTopNews /> */}
                                <UtEditTopNews />
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-4">
                                {/* <ProductNavbarComponent /> */}
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-1">
                                <UtTagPageInfo />
                                {/* <UtOurStoryCard /> */}
                                <UtAboutUs />
                                <UtOurValues />
                                <UtOtherValues />
                                <UtTestimonials />
                                <UtHappyClients />
                                <UtHeaderPageImagesCard />
                            </Col>
                        </Row>
                    </TabPanel>
                </Tabs>
            </Container>
        </UtHomeContext.Provider>
    );
};

export default UtEditHomePage;
