import React, {useState, useReducer, useEffect} from 'react';
import {
    FETCH_CATEGORIES, FETCH_KINDS, FETCH_PRODUCTS,
    productsReducer
} from "../store/reducers/ProductsReducer";
import {ProductsState} from "../store/states/ProductsState";
import {ProductsContext} from "../store/contexts/ProductsContext";
import Client from '../shared/client'
import {Alert, Col, Container, Row} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {
    EditCategories,
    EditKinds,
    ProductsTable
} from "../components/@edit-products";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

const EditProductsPage = () => {
    const [productsData, dispatchProductsData] = useReducer(productsReducer, ProductsState);
    const [isAlertVisible,] = useState(false);

    useEffect(() => {
        Client('pcategory', 'GET').then(({data}) => {
            dispatchProductsData({
                type: FETCH_CATEGORIES,
                payload: data
            })
        }).catch(e => alert(e));
        Client('pkind', 'GET').then(({data}) => {
            dispatchProductsData({
                type: FETCH_KINDS,
                payload: data
            })
        }).catch(e => alert(e));
        Client('p', 'GET').then(({data}) => {
            dispatchProductsData({
                type: FETCH_PRODUCTS,
                payload: data
            })
        }).catch(e => alert(e));
    }, [])

    return (
        <ProductsContext.Provider
            value={[productsData, dispatchProductsData]}>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <PageTitle title="Products & Product detail"
                               subtitle="Page Manager"
                               className="text-sm-left mb-3"/>
                </Row>
                <Row noGutters>
                    <Alert className="mb-3" open={isAlertVisible}
                           theme="success"
                           style={{marginLeft: 'auto'}}>
                        Update successful!
                    </Alert>
                </Row>
                <Tabs>
                    <TabList>
                        <Tab>Categories &amp; Types</Tab>
                        <Tab>Products</Tab>
                    </TabList>
                    <TabPanel>
                        <Row>
                            <Col lg="6" md="12" sm="12" className="mb-4">
                                <EditCategories/>
                            </Col>
                            <Col lg="6" md="12" sm="12" className="mb-4">
                                <EditKinds/>
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-4">
                                <ProductsTable/>
                            </Col>
                        </Row>
                    </TabPanel>
                </Tabs>
            </Container>
        </ProductsContext.Provider>
    )
}

export default EditProductsPage;
