const AccountState = {
  name: '',
  phoneNumber: null,
  address: null,
  email: '',
  password: '',
  roles: ["MANAGER"]
};

export default AccountState;
