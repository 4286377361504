import React, {useEffect, useReducer} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {Container, Row} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import 'react-tabs/style/react-tabs.css';
import {
    FETCH_SOLUTIONS,
    solutionsReducer
} from "../store/reducers/SolutionsReducer";
import SolutionsContext from "../store/contexts/SolutionsContext";
import Client from "../shared/client";
import SolutionState from "../store/states/SolutionState";
import SolutionComponent from '../components/@solution/@solution-component';
import { SolutionTable } from '../components/@solution/@solution-table';

const Solution = ({history}) => {
    const [solutionData, setSolutionData] = useReducer(solutionsReducer, []);

    useEffect(() => {
        const _fetchData = async () => {
            const {data} = await Client('solutions', 'GET');
            setSolutionData({
                type: FETCH_SOLUTIONS,
                payload: [...data]
            });
        }
        _fetchData();
    }, [])

    return (
        <SolutionsContext.Provider value={[solutionData, setSolutionData]}>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <PageTitle title="Solution" subtitle="Page Manager"
                               className="text-sm-left mb-3"/>
                    {/* <Button theme="primary" pill className="mr-3"
                    disabled={isDisableSave}
                    onClick={onSave}>Save
                        changes</Button> */}
                </Row>
                <Tabs>
                    <TabList>
                        <Tab>Solution</Tab>
                        <Tab>Create new solution</Tab>
                    </TabList>
                    <TabPanel>
                        <SolutionTable history={history}/>
                    </TabPanel>
                    <TabPanel>
                        <SolutionComponent solution={SolutionState}/>
                    </TabPanel>
                </Tabs>
            </Container>
        </SolutionsContext.Provider>
    )
}

export default Solution;
