/*
 * Created by @tranphuquy19 at 07/03/2021
 * @author: tranphuquy19@gmail.com
 */

import DownloadState from "../states/DownloadState";

export const FETCH_DOWNLOADS = 'FETCH_DOWNLOADS';
export const ADD_NEW_CATALOG = 'ADD_NEW_CATALOG';
export const REMOVE_CATALOG_AT_INDEX = 'REMOVE_CATALOG';
export const UPDATE_CATALOG_AT_INDEX = 'UPDATE_CATALOG_AT_INDEX';

export const downloadsReducer = (state, {type, payload}) => {
    switch (type) {
        case FETCH_DOWNLOADS: {
            return [...payload];
        }
        case UPDATE_CATALOG_AT_INDEX: {
            const {value, index} = payload;
            const _data = [...state];
            _data[index] = value;
            return _data;
        }
        case ADD_NEW_CATALOG: {
            return [...state, DownloadState]
        }
        case REMOVE_CATALOG_AT_INDEX: {
            const index = payload;
            const _state = state;
            _state.splice(index, 1);
            return [..._state];
        }
        default: {
            return state;
        }
    }
}
