/*
 * Created by @tranphuquy19 on 08/12/2020
 * @author: tranphuquy19@gmail.com
 */

export const utHomeState = {
    sliderImages: [],
    sliderProducts: [],
    homeTitle: "",
    homeDescription: "",
    offices: [
        {
            organizationName: "",
            email: "",
            phoneNumber: "",
            address: "",
            fax: "",
            openTime: ""
        }
    ],
    tags: [],
    topProducts: [],
    topProjects: [],
    topSolutions: [],
    topNews: [],
    productsNavbar: {
        col1: {
            categories: [],
            kinds: []
        },
        col2: {
            categories: [],
            kinds: []
        },
        col3: {
            categories: [],
            kinds: []
        }
    },
    ggAddressLink: '',
    fbLink: '',
    ytLink: '',
    twitterLink: '',
    vietnameseTags: '',
    ourStory: {
        storyText: '',
        storyImage: { fileUrl: '', originalName: '' },
        storyLink: ''
    },
    headerImages: {
        productPage: { fileUrl: '', originalName: '' },
        projectPage: { fileUrl: '', originalName: '' },
        downloadPage: { fileUrl: '', originalName: '' },
        newsPage: { fileUrl: '', originalName: '' },
        contactPage: { fileUrl: '', originalName: '' },
        solutionPage: { fileUrl: '', originalName: '' }
    },
    otherValues: {
        totalProjects: '1',
        staffMembers: '1',
        totalClients: '1',
        totalHours: '1',
        totalAwards: '1',
        whatWeDo: {
            item1Icon: '',
            item1Title: '',
            item1Description: '',
            item2Icon: '',
            item2Title: '',
            item2Description: '',
            item3Icon: '',
            item3Title: '',
            item3Description: '',
            item4Icon: '',
            item4Title: '',
            item4Description: '',
            item5Icon: '',
            item5Title: '',
            item5Description: '',
            item6Icon: '',
            item6Title: '',
            item6Description: '',
            serviceImageCenterLink: ''
        },
    },
    isShowHappyClients: true,
    isShowOurValues: true,
    isShowOtherValues: true,
    isShowOurStory: true,
    isShowTestimonials: true,
    isShowMetrics: true,
    isShowAboutUs: true,
}
