import React, { useEffect, useState } from 'react';
import {
	Button, Card,
	CardBody, CardFooter,
	Col,
	Container,
	FormGroup,
	FormInput,
	Row
} from "shards-react"; import PageTitle from '../components/common/PageTitle';

import Client from '../shared/client';
import { store } from "react-notifications-component";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Editor from "../components/@editor/@editor";
import { WarrantyState } from '../store/states/WarrantyState';


const TermComponent = () => {
	const [term, setTerm] = useState(WarrantyState);
	const [editor, setEditor] = useState(null);

	useEffect(() => {
		Client('term', 'GET').then(({ data }) => {
			const _data = data ? data : WarrantyState
			setTerm(_data);
		}).catch(e => {
			store.addNotification({
				title: "Fetch terms&conditions failed",
				message: `Has some error while fetching the terms`,
				type: "danger",
				insert: "bottom",
				container: "bottom-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 2000,
					// onScreen: true
				}
			});
		})
	}, []);

	const onChange = ({ target }) => {
		const { name, value } = target;
		const updateTerm = {
			...term,
			[name]: value
		}
		setTerm(updateTerm);
	}

	const onSubmit = () => {
		const content = editor.getData();
		const updateTerm = {
			...term,
			content
		}
		delete updateTerm.id
		Client('term', 'POST', updateTerm).then(({ data }) => {
			setTerm(data);
			store.addNotification({
				title: "Update successful",
				message: `Term: ${data.title} was updated successfully.`,
				type: "success",
				insert: "bottom",
				container: "bottom-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true
				}
			})
		}).catch(e => {
			store.addNotification({
				title: "Error!",
				message: `Failed to update term: ${term.title}.`,
				type: "danger",
				insert: "bottom",
				container: "bottom-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 2000,
					// onScreen: true
				}
			});
		});;
	}

	return (
		<Container fluid className="main-content-container px-4">
			<Row noGutters className="page-header py-4" style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignContent: 'center'
			}}>
				<PageTitle title="Edit Terms&amp;Conditions"
					subtitle="Page Manager"
					className="text-sm-left mb-3" />
			</Row>
			<Tabs>
				<TabList>
					<Tab>Editor</Tab>
				</TabList>
				<TabPanel>
					<Card>
						<CardBody>
							<Row>
								<Col md={12} sm={12}>
									<FormGroup>
										<label htmlFor="p-title">Title</label>
										<FormInput placeholder="Term title"
											name="title"
											className="mb-2" id="p-title"
											value={term.title}
											onChange={e => {
												onChange(e)
											}} />
									</FormGroup>
									<Editor value={term.content}
										onChange={(editor) => {
											setEditor(editor);
										}} />
								</Col>
							</Row>
						</CardBody>
						<CardFooter style={{
							display: 'flex',
							justifyContent: 'flex-end'
						}}>
							<Button theme={'success'}
								onClick={() => onSubmit()}>Update</Button>
						</CardFooter>
					</Card>
				</TabPanel>
			</Tabs>
		</Container>
	)
}

export default TermComponent;