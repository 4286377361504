/*
 * Created by @tranphuquy19 on 06/01/2021
 * @author: tranphuquy19@gmail.com
 */
import User from "./User";

const ProjectState = {
    id: '',
    title: '',
    image: {
        fileUrl: '',
        originalName: ''
    },
    content: '',
    startDate: new Date(),
    creator: User,
}

export default ProjectState;
