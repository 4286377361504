import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  Button
} from "shards-react";
import { AuthContext } from "../../store/contexts/AuthContext";
import AccountState from "../../store/states/AccountState";
import Client from "../../shared/client";
import { store } from "react-notifications-component";

const UserForm = ({ title, u, btnName }) => {
  const [userData, setUserData] = useState(AccountState);
  const [user,] = useContext(AuthContext);

  useEffect(() => {
    if (u) {
      setUserData({ ...u, password: null });
    }
  }, [])

  const onChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  }

  const onChangeRoles = e => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: [value] });
  }

  const checkValidator = () => {
    if (!userData.password || userData.password == ''
      || userData.name == '' || userData.email == '') {
      store.addNotification({
        title: "Error!",
        message: `Please filter required text includes (name, email, password).`,
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          // onScreen: true
        }
      })
      return false;
    }
    return true;
  }
  const onSubmit = () => {
    if(!checkValidator())
      return;
    if (u) {
      Client('user/' + u.id, 'PATCH', userData).then((res) => {
        store.addNotification({
          title: "Updated account successful",
          message: `The accounts table was updated`,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        });
        setTimeout(() => {
          window.location.assign('/users-manager');
        }, 3000)
      }).catch(e => {
        store.addNotification({
          title: "Error!",
          message: `Failed to updated account: ${e.response.data.message.join("\n")}.`,
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            // onScreen: true
          }
        })
      })
    } else {
      Client('users', 'POST', userData).then(({ data }) => {
        store.addNotification({
          title: " Created account successful",
          message: `The accounts table was updated`,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        });
        setTimeout(() => {
          window.location.assign('/users-manager');
        }, 3000)
      }).catch(e => {
        store.addNotification({
          title: "Error!",
          message: `Failed to created account: ${e.response.data.message.join("\n")}.`,
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            // onScreen: true
          }
        })
      })
    }
  }

  const renderBtnSubmit = () => {
    if (user.roles.includes("ADMIN") || u) {
      return (
        <Button theme="accent" onClick={() => onSubmit()} >{btnName}</Button>
      )
    }
    return (
      <Button disabled={true} theme="accent" onClick={() => onSubmit()} >{btnName}</Button>
    )
  }

  const renderFieldPassWord = () => {
    return (
      <Col md="6" className="form-group">
        <label htmlFor="fePassword">Password</label>
        <FormInput
          type="password"
          id="fePassword"
          name="password"
          placeholder="Password"
          value={userData.password}
          onChange={onChange}
          autoComplete="current-password"
        />
      </Col>
    )
  }

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  {/* Email */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feEmail">Email</label>
                    <FormInput
                      type="email"
                      id="feEmail"
                      name="email"
                      placeholder="Email Address"
                      value={userData.email}
                      onChange={onChange}
                      autoComplete="email"
                    />
                  </Col>
                  {/* Password */}
                  {renderFieldPassWord()}
                </Row>
                <Row form>
                  {/* First Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feFirstName">Name</label>
                    <FormInput
                      id="feFirstName"
                      name="name"
                      placeholder="Name"
                      value={userData.name}
                      onChange={onChange}
                    />
                  </Col>
                  {/* Last Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feLastName">Phone Number</label>
                    <FormInput
                      id="feLastName"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      value={userData.phoneNumber}
                      onChange={onChange}
                    />
                  </Col>
                </Row>
                <FormGroup>
                  <label htmlFor="feAddress">Address</label>
                  <FormInput
                    id="feAddress"
                    name="address"
                    placeholder="Address"
                    value={userData.address}
                    onChange={onChange}
                  />
                </FormGroup>
                <Row form>
                  {/* State */}
                  <Col md="4" className="form-group">
                    <label htmlFor="feInputState">Roles</label>
                    <FormSelect id="feInputState" name="roles" value={userData.roles[0]} onChange={onChangeRoles}>
                      <option>MANAGER</option>
                      <option>ADMIN</option>
                    </FormSelect>
                  </Col>
                </Row>
              </Form>
              {renderBtnSubmit()}
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  )
}
UserForm.propTypes = {
  title: PropTypes.string,
  btnName: PropTypes.string
};

UserForm.defaultProps = {
  title: "Account Create",
  btnName: "Create Account"
};

export default UserForm;