/*
 * Created by @tranphuquy19 on 24/12/2020
 * @author: tranphuquy19@gmail.com
 */

export const ProductState = {
    id: '',
    name: '',
    model: '',
    description: [],
    price: 0,
    quantity: 100,
    files: [],
    images: [],
    kind: {
        id: '',
        name: '',
        description: '',
        category: {
            id: '',
            name: '',
            description: ''
        }
    },
}
