import React, { useContext } from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";
import { AuthContext } from "../../../store/contexts/AuthContext";

const SidebarNavItem = ({ item }) => {
  const [auth,] = useContext(AuthContext);

  const checkDisplay = () => {
    if (auth.roles.includes("ADMIN") || item.display)
      return true;
    return false;
  }

  return (
    <NavItem style={{ display: checkDisplay() ? "block" : "none" }}>
      <NavLink tag={RouteNavLink} to={item.to}>
        {item.htmlBefore && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
          />
        )}
        {item.title && <span>{item.title}</span>}
        {item.htmlAfter && (
          <div
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
          />
        )}
      </NavLink>
    </NavItem>
  )
};

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
