/*
 * Created by @tranphuquy19 on 30/12/2020
 * @author: tranphuquy19@gmail.com
 */
import React, {useState, useEffect, useContext} from 'react';
import {HomeContext} from "../../store/contexts/HomeContext";
import {Card, CardBody, CardHeader, Col, Row} from "shards-react";
import Select from "react-select";
import Client from '../../shared/client';
import _ from 'lodash';
import {EDIT_PRODUCT_NAVBAR} from "../../store/reducers/HomeReducer";

export const ProductNavbarComponent = () => {
    const [homeData, dispatchHomeData] = useContext(HomeContext);

    const [categories, setCategories] = useState([]);
    const [cateOptions, setCateOptions] = useState([]);

    const [col1KindOptions, setCol1KindOptions] = useState([]);
    const [col2KindOptions, setCol2KindOptions] = useState([]);
    const [col3KindOptions, setCol3KindOptions] = useState([]);

    const [col1, setCol1] = useState({categories: [], kinds: []});
    const [col2, setCol2] = useState({categories: [], kinds: []});
    const [col3, setCol3] = useState({categories: [], kinds: []});

    useEffect(() => {
        const _fetch = async () => {
            const {data} = await Client('pcategory', 'GET');
            setCategories(data);
            const _cateOptions = data.map(({name}) => {
                return {value: encodeURIComponent(name), label: name};
            });
            setCateOptions(_cateOptions);
            const {
                col1: _col1,
                col2: _col2,
                col3: _col3
            } = homeData.productsNavbar;
            setCol1(_col1);
            setCol2(_col2);
            setCol3(_col3);
        }
        _fetch();
    }, [])

    useEffect(() => {
        const _col1KindOptions = getKindCollections(col1);
        const _col2KindOptions = getKindCollections(col2);
        const _col3KindOptions = getKindCollections(col3);

        setCol1KindOptions(_col1KindOptions);
        setCol2KindOptions(_col2KindOptions);
        setCol3KindOptions(_col3KindOptions);

        if (!(_.isEqual(JSON.parse(JSON.stringify({
            col1,
            col2,
            col3
        })), {
            col1: homeData.productsNavbar.col1,
            col2: homeData.productsNavbar.col2,
            col3: homeData.productsNavbar.col3
        }))) {
            dispatchHomeData({
                type: EDIT_PRODUCT_NAVBAR,
                payload: {col1, col2, col3}
            })
        }
    }, [col1, col2, col3]);

    const getKindCollections = (col) => {
        const colKindOptions = [];
        col.categories.forEach(({value}) => {
            const _cate = _.find(categories, {name: decodeURIComponent(value)});
            const {productKinds} = _cate;
            const _kinds = productKinds.map(({name}) => {
                return {
                    value: encodeURIComponent(`{"category":"${value}","kind":"${name}"}`),
                    label: name
                };
            });
            colKindOptions.push(..._kinds);
        });
        return colKindOptions;
    }

    const onCateChange = (selectedCategories, colIndex) => {
        selectedCategories = selectedCategories || [];
        switch (colIndex) {
            case 1:
                const _kinds1 = getKindCollections({categories: selectedCategories});
                setCol1({
                    categories: selectedCategories,
                    kinds: _.intersectionBy(_kinds1, col1.kinds, 'value')
                })
                break;
            case 2:
                const _kinds2 = getKindCollections({categories: selectedCategories});
                setCol2({
                    categories: selectedCategories,
                    kinds: _.intersectionBy(_kinds2, col2.kinds, 'value')
                })
                break;
            case 3:
                const _kinds3 = getKindCollections({categories: selectedCategories});
                setCol3({
                    categories: selectedCategories,
                    kinds: _.intersectionBy(_kinds3, col3.kinds, 'value')
                })
                break;
            default:
                break;
        }
    }

    const onKindChange = (selectedKinds, colIndex) => {
        selectedKinds = selectedKinds || [];
        switch (colIndex) {
            case 1:
                setCol1({
                    ...col1,
                    kinds: selectedKinds
                })
                break;
            case 2:
                setCol2({
                    ...col2,
                    kinds: selectedKinds
                })
                break;
            case 3:
                setCol3({
                    ...col3,
                    kinds: selectedKinds
                })
                break;
            default:
                break;
        }
    }

    return (
        <>
            <h5>Select product categories &amp; product types to show on the
                Navigation bar!</h5>
            <Row>
                <Col md={6} className="mt-1 mb-2">
                    <Card>
                        <CardHeader>
                            <h6>Column 1th</h6>
                        </CardHeader>
                        <CardBody style={{
                            marginTop: 0,
                            marginBottom: 0,
                            paddingTop: 0
                        }}>
                            <Select
                                options={cateOptions}
                                value={col1.categories}
                                onChange={(selectedCategories) => onCateChange(selectedCategories, 1)}
                                isMulti={true}
                                placeholder={'categories'}
                                className="mb-3"/>
                            <Select
                                options={col1KindOptions}
                                value={col1.kinds}
                                onChange={(selectedKinds) => onKindChange(selectedKinds, 1)}
                                isMulti={true}
                                placeholder={'types'}/>
                        </CardBody>
                    </Card>
                </Col>

                <Col md={6} className="mt-1 mb-2">
                    <Card>
                        <CardHeader>
                            <h6>Column 2nd</h6>
                        </CardHeader>
                        <CardBody style={{
                            marginTop: 0,
                            marginBottom: 0,
                            paddingTop: 0
                        }}>
                            <Select
                                options={cateOptions}
                                value={col2.categories}
                                onChange={(selectedCategories) => onCateChange(selectedCategories, 2)}
                                isMulti={true}
                                placeholder={'categories'}
                                className="mb-3"/>
                            <Select
                                options={col2KindOptions}
                                value={col2.kinds}
                                onChange={(selectedKinds) => onKindChange(selectedKinds, 2)}
                                isMulti={true}
                                placeholder={'types'}/>
                        </CardBody>
                    </Card>
                </Col>

                <Col md={6} className="mt-1 mb-2">
                    <Card>
                        <CardHeader>
                            <h6>Column 3rd</h6>
                        </CardHeader>
                        <CardBody style={{
                            marginTop: 0,
                            marginBottom: 0,
                            paddingTop: 0
                        }}>
                            <Select options={cateOptions}
                                    value={col3.categories}
                                    onChange={(selectedCategories) => onCateChange(selectedCategories, 3)}
                                    isMulti={true}
                                    placeholder={'categories'}
                                    className="mb-3"/>
                            <Select options={col3KindOptions}
                                    value={col3.kinds}
                                    onChange={(selectedKinds) => onKindChange(selectedKinds, 3)}
                                    isMulti={true}
                                    placeholder={'types'}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
