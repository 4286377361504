import React, {useState, useEffect} from 'react';
import {Container, Row} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import SolutionState from "../store/states/SolutionState";
import Client from '../shared/client';
import qs from 'query-string';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {store} from "react-notifications-component";
import SolutionComponent from '../components/@solution/@solution-component';

const EditSolutionsPage = ({location}) => {
    const [solutionState, setSolutionState] = useState(SolutionState);

    useEffect(() => {
        const {id} = qs.parse(location.search);
        if (id) {
            Client(`solution/${id}`, 'GET').then(({data}) => {
                setSolutionState(data);
            }).catch(e => {
                store.addNotification({
                    title: "Error!",
                    message: `Failed to refresh Solution: ${e}.`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            })
        }
    }, [location])

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center'
            }}>
                <PageTitle title="Edit project"
                           subtitle="Page Manager"
                           className="text-sm-left mb-3"/>
                <Tabs>
                    <TabList>
                        <Tab>Update solution</Tab>
                    </TabList>
                    <TabPanel>
                        <SolutionComponent solution={solutionState}/>
                    </TabPanel>
                </Tabs>
            </Row>
        </Container>
    )
}

export default EditSolutionsPage;
