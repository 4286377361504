import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "shards-react";
import UserForm from "./@user-form";

const UserEditModal = ({ user, open, toggle }) => {
  return (
    <Modal size="lg" open={open} toggle={toggle}>
      <ModalHeader>Update User {user.name}</ModalHeader>
      <ModalBody>
        <UserForm title={"Account Edit"} btnName={"Update user"} u={user}/>
      </ModalBody>
    </Modal>
  )
}

export default UserEditModal;