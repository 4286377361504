import User from "./User";

const SolutionState = {
    id: '',
    title: '',
    image: {
        fileUrl: '',
        originalName: ''
    },
    content: '',
    startDate: new Date(),
    creator: User,
}

export default SolutionState;
