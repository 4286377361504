/*
 * Created by @tranphuquy19 at 13/03/2021
 * @author: tranphuquy19@gmail.com
 */
import moment from "moment";

const InternalProject = {
    id: '',
    name: '',
    location: '',
    investor: '',
    designConsultant: '',
    contractor: '',
    status: '',
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    createdAt: new Date(),
    updatedAt: new Date(),
}

export default InternalProject;
