import React, {useContext, useEffect, useState} from "react"
import {HomeContext} from "../../store/contexts/HomeContext"
import Select from 'react-select';
import Client from "../../shared/client";
import _ from 'lodash';
import {EDIT_TOP_NEWS} from "../../store/reducers/HomeReducer";

export const EditTopNews = () => {
    const [homeData, dispatchHomeData] = useContext(HomeContext);
    const [optionSelected, setOptionSelected] = useState([]);
    const [options, setOptions] = useState([]);

    const fetchNews = async () => {
        const {data} = await Client('news', 'GET');
        return data;
    }

    useEffect(() => {
        const _fetchNews = async () => {
            const _news = await fetchNews();
            const news = _news.filter(news => news.status)
            const data = news.map((value,) => {
                if (value.status) {
                    return {
                        value: value.id,
                        label: `${value.title.length < 15 ? value.title : value.title.slice(0, 15) + "..."}`,
                        data: value
                    }
                }
                else return undefined;
            });
            setOptions(data);
        }
        _fetchNews();
    }, [])

    useEffect(() => {
        const _optionsSelected = homeData.topNews.map(({
                                                           value,
                                                           label,
                                                           data
                                                       }) => {
            return {value, label, data};
        });
        setOptionSelected(_optionsSelected);
    }, [options, homeData])

    const onOptionsChange = (selectedOptions) => {
        selectedOptions = selectedOptions || []; // selectedOptions === null nếu ko có phần tử được selected
        const results = selectedOptions.map(({value}) => {
            return _.find(options, {value});
        })
        dispatchHomeData({
            type: EDIT_TOP_NEWS,
            payload: results
        })
    }


    return (
        <>
            <h5>Select News&amp;Events to show on the Home page!</h5>
            <Select options={options} isMulti={true} onChange={onOptionsChange}
                    value={optionSelected}/>
        </>
    )
}
