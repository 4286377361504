/*
 * Created by @tranphuquy19 at 13/03/2021
 * @author: tranphuquy19@gmail.com
 */

import React from 'react';
import {
    Button,
    Col,
    DatePicker,
    FormInput, FormTextarea, Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row
} from "shards-react";
import moment from "moment";

export const InternalProjectModal = ({
                                         project,
                                         toggle,
                                         isOpen,
                                         clearProject,
                                         onProjectChange,
                                         createProject,
                                         updateProject,
                                     }) => {
    return (
        <Modal open={isOpen} toggle={() => {
            toggle();
            clearProject()
        }} size={'lg'}>
            <ModalHeader>{`${!!project.id ? `Update` : `Create new`} project ${!!project.id ? ` - [${project.creator.email}` : `- [You`} at ${moment(project.createdAt).format('YYYY-MM-DD HH:mm')}]`}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={8}>
                        <FormInput placeholder="Project name"
                                   name="name"
                                   value={project.name}
                                   onChange={onProjectChange}
                                   type="text"/>
                    </Col>
                    <Col md={4}>
                        <FormInput placeholder="Project status"
                                   name="status"
                                   value={project.status}
                                   onChange={onProjectChange}
                                   type="text"/>
                    </Col>
                    <Col md={8} className={'mt-1'}>
                        <FormInput placeholder="Location"
                                   name="location"
                                   value={project.location}
                                   onChange={onProjectChange}
                                   type="text"/>
                    </Col>
                    <Col md={4} className={'mt-1'}>
                        <DatePicker
                            selected={moment(project.startDate).toDate()}
                            dateFormat="yyyy-MM-dd"
                            id="p-date"
                            onChange={(value) => {
                                if (value) {
                                    onProjectChange({
                                        target: {
                                            name: 'startDate',
                                            value: moment(value).format('YYYY-MM-DD')
                                        }
                                    })
                                }
                            }}/>
                    </Col>
                    <Col md={12} className={'mt-1'}>
                        <FormTextarea placeholder="Investor"
                                      name="investor"
                                      value={project.investor}
                                      onChange={onProjectChange}
                                      type="text"/>
                    </Col>
                    <Col md={12} className={'mt-1'}>
                        <FormTextarea placeholder="Design consultant"
                                      name="designConsultant"
                                      value={project.designConsultant}
                                      onChange={onProjectChange}
                                      type="text"/>
                    </Col>
                    <Col md={12} className={'mt-1'}>
                        <FormTextarea placeholder="Contractor"
                                      name="contractor"
                                      value={project.contractor}
                                      onChange={onProjectChange}
                                      type="text"/>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    theme="primary" onClick={() => {
                    if (project.id)
                        updateProject(project);
                    else createProject(project);
                }}>{!!project.id ? 'Update' : 'Create'}</Button>
                <Button theme="warning"
                        onClick={() => {
                            toggle();
                            clearProject()
                        }}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}
