import React, {useState, useContext} from "react";
import {Link} from "react-router-dom";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Collapse,
    NavItem,
    NavLink
} from "shards-react";
import {AuthContext} from "../../../../store/contexts/AuthContext";
import {LOGOFF} from "../../../../store/reducers/AuthReducer";

const UserActions = () => {
    const [auth, dispatchAuth] = useContext(AuthContext);
    const [isVisible, setVisible] = useState(false);

    const toggle = () => {
        setVisible(!isVisible);
    }

    return (
        <NavItem tag={Dropdown} caret toggle={toggle}>
            <DropdownToggle caret tag={NavLink}
                            className="text-nowrap px-3 mt-2" style={{
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {/*<img*/}
                {/*    className="user-avatar mr-2"*/}
                {/*    src={require("./../../../../images/avatars/4.png")}*/}
                {/*    alt="User Avatar"*/}
                {/*/>{" "}*/}
                <span className="d-none d-md-inline-block"
                      style={{fontSize: 16}}>{auth.email}</span>
            </DropdownToggle>
            <Collapse tag={DropdownMenu} right small open={isVisible}>
                <DropdownItem tag={Link} to="user-profile">
                    <i className="material-icons">&#xE7FD;</i> Profile
                </DropdownItem>
                {/*<DropdownItem tag={Link} to="edit-user-profile">*/}
                {/*  <i className="material-icons">&#xE8B8;</i> Edit Profile*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem tag={Link} to="file-manager-list">*/}
                {/*  <i className="material-icons">&#xE2C7;</i> Files*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem tag={Link} to="transaction-history">*/}
                {/*  <i className="material-icons">&#xE896;</i> Transactions*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem divider />*/}
                <DropdownItem tag={Link} to="/" className="text-danger"
                              onClick={() => dispatchAuth({type: LOGOFF})}>
                    <i className="material-icons text-danger">&#xE879;</i> Logout
                </DropdownItem>
            </Collapse>
        </NavItem>
    );
}

export default UserActions;
