const DownloadState = {
    id : '',
    title : '',
    image : {
        fileUrl: '',
        originalName: ''
    },
    files : [{
        fileUrl: '',
        originalName: ''
    }],
    createdAt : new Date(),
    updatedAt : new Date(),
}

export default DownloadState;
