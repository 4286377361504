export const FETCH_SOLUTIONS = 'FETCH_SOLUTIONS';

export const solutionsReducer = (state, {type, payload}) => {
    switch(type) {
        case FETCH_SOLUTIONS: {
            return [...payload];
        }
        default: return state;
    }
}
