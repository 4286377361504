/*
 * Created by @tranphuquy19 on 30/12/2020
 * @author: tranphuquy19@gmail.com
 */
import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import Client from '../../shared/client';
import _ from 'lodash';
import { UT_EDIT_TOP_PRODUCTS } from '../../store/reducers/UtHomeReducer';
import { UtHomeContext } from '../../store/contexts/UtHomeContext';

export const UtEditTopProduct = () => {
    const [homeData, dispatchHomeData] = useContext(UtHomeContext);
    const [optionSelected, setOptionSelected] = useState([]);
    const [options, setOptions] = useState([]);

    const fetchProducts = async () => {
        const { data } = await Client('products', 'GET', null, true);
        return data;
    };

    useEffect(() => {
        const _fetchProducts = async () => {
            const _products = await fetchProducts();
            const _options = _products.map(({ title, id }) => {
                return { value: id, label: `${title}` };
            });
            setOptions(_options);
        };
        _fetchProducts();
    }, []);

    useEffect(() => {
        console.log('homeData', homeData);
        const _optionsSelected = homeData.topProducts.map(
            ({ value, label }) => {
                return { value, label };
            },
        );
        console.log('optionsSelected', _optionsSelected);
        setOptionSelected(_optionsSelected);
    }, [options, homeData]);

    const onOptionsChange = selectedOptions => {
        selectedOptions = selectedOptions || []; // selectedOptions === null nếu ko có phần tử được selected
        const results = selectedOptions.map(({ value }) => {
            return _.find(options, { value });
        });
        dispatchHomeData({
            type: UT_EDIT_TOP_PRODUCTS,
            payload: results,
        });
    };

    return (
        <>
            <h5>Chọn các sản phẩm để hiển thị trên thanh navigation</h5>
            <Select
                options={options}
                isMulti={true}
                onChange={onOptionsChange}
                value={optionSelected}
            />
        </>
    );
};
