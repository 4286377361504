/*
 * Created by @tranphuquy19 on 08/12/2020
 * @author: tranphuquy19@gmail.com
 */

import { homeState } from "../states/HomeState";

export const FETCH_DATA = 'FETCH_DATA';
export const ADD_SLIDER_IMAGE = 'ADD_SLIDER_IMAGE';
export const ADD_SLIDER_PRODUCT = 'ADD_SLIDER_PRODUCT';
export const MOVE_TO_TOP_SLIDER_IMAGE = 'MOVE_TO_TOP_SLIDER_IMAGE';
export const MOVE_TO_TOP_SLIDER_PRODUCT = 'MOVE_TO_TOP_SLIDER_PRODUCT';
export const EDIT_ITEM_PRODUCT_AT_INDEX = 'EDIT_ITEM_PRODUCT_AT_INDEX';
export const REMOVE_SLIDER_PRODUCT = 'REMOVE_SLIDER_PRODUCT';
export const EDIT_ITEM_IMAGE_AT_INDEX = 'EDIT_ITEM_IMAGE_AT_INDEX';
export const REMOVE_SLIDER_IMAGE = 'REMOVE_SLIDER_IMAGE';
export const EDIT_TOP_PRODUCTS = 'EDIT_TOP_PRODUCTS';
export const EDIT_OFFICES_INFO = 'EDIT_OFFICES_INFO';
export const EDIT_PRODUCT_NAVBAR = 'EDIT_PRODUCT_NAVBAR';
export const EDIT_TOP_NEWS = "EDIT_TOP_NEWS";
export const EDIT_TOP_PROJECTS = 'EDIT_TOP_PROJECTS';
export const EDIT_TOP_SOLUTIONS = 'EDIT_TOP_SOLUTIONS';
export const EDIT_SOCIAL_LINKS = 'EDIT_GG_MAPS_LINK';
export const EDIT_OUR_STORY = "EDIT_OUR_STORY";
export const EDIT_HEADER_IMAGES = 'EDIT_HEADER_IMAGES';

export const homeReducer = (state, action) => {
    switch (action.type) {
        case FETCH_DATA:
            return { ...action.payload };
        case ADD_SLIDER_IMAGE:
            return {
                ...state,
                sliderImages: [...state.sliderImages, {
                    head1: '',
                    head2: '',
                    link: ''
                }]
            };
        case ADD_SLIDER_PRODUCT:
            return {
                ...state,
                sliderProducts: [...state.sliderProducts, { code: '' }]
            };
        case MOVE_TO_TOP_SLIDER_IMAGE:
            const { payload: { index: index4 } } = action;
            return {
                ...state,
                sliderImages: [state.sliderImages[index4], ...state.sliderImages.filter((e, i) => i !== index4)]
            }
        case MOVE_TO_TOP_SLIDER_PRODUCT:
            const { payload: { index: index3 } } = action;
            return {
                ...state,
                sliderProducts: [state.sliderProducts[index3], ...state.sliderProducts.filter((e, i) => i !== index3)]
            }
        case EDIT_ITEM_PRODUCT_AT_INDEX:
            const { payload: { index, value, key } } = action;
            return {
                ...state,
                sliderProducts: state.sliderProducts.map((e, i) => {
                    if (i === index) {
                        e[key] = value;
                    }
                    return e;
                })
            };
        case REMOVE_SLIDER_PRODUCT:
            const { payload: { index: index2 } } = action;
            return {
                ...state,
                sliderProducts: state.sliderProducts.filter((e, i) => i !== index2)
            };
        case REMOVE_SLIDER_IMAGE:
            const { payload: { index: index5 } } = action;
            return {
                ...state,
                sliderImages: state.sliderImages.filter((e, i) => i !== index5)
            };
        case EDIT_ITEM_IMAGE_AT_INDEX:
            const { payload: { index: index1, value: value1, key: key1 } } = action;
            return {
                ...state,
                sliderImages: state.sliderImages.map((e, i) => {
                    if (i === index1) {
                        e[key1] = value1;
                    }
                    return e;
                })
            };
        case EDIT_TOP_PRODUCTS:
            const { payload: _topProducts } = action;
            return {
                ...state,
                topProducts: _topProducts
            }
        case EDIT_TOP_PROJECTS:
            const { payload: _topProjects } = action;
            return {
                ...state,
                topProjects: _topProjects
            }
        case EDIT_TOP_SOLUTIONS:
            const { payload: _topSolutions } = action;
            return {
                ...state,
                topSolutions: _topSolutions
            }
        case EDIT_TOP_NEWS:
            const { payload: _topNews } = action;
            return {
                ...state,
                topNews: _topNews
            }
        case EDIT_OFFICES_INFO:
            const { nameOffice, valueOffice } = action.payload;
            if (nameOffice === 'tags') {
                let _tags = { ...state.tags[0], content: valueOffice };
                return {
                    ...state, tags: [_tags]
                }
            }
            if (nameOffice === 'homeDescription' || nameOffice === 'homeTitle') {
                return {
                    ...state, [nameOffice]: valueOffice
                }
            }
            let a = { ...state.offices[0], [nameOffice]: valueOffice }
            return {
                ...state, offices: [a]
            }
        case EDIT_PRODUCT_NAVBAR:
            const { payload: productsNavbar } = action;
            return {
                ...state,
                productsNavbar
            }
        case EDIT_SOCIAL_LINKS:
            const { payload: { name: linkName, value: linkValue } } = action;
            return {
                ...state,
                [linkName]: linkValue
            }
        case EDIT_OUR_STORY: {
            const { payload } = action;
            return {
                ...state,
                ourStory: payload
            }
        }
        case EDIT_HEADER_IMAGES: {
            const { payload: { image: { fileUrl, originalName }, page } } = action;
            return {
                ...state,
                headerImages: {
                    ...state.headerImages,
                    [page]: { fileUrl, originalName }
                }
            }
        }
        default:
            return homeState;
    }
}
