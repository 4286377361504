/*
 * Created by @tranphuquy19 on 07/01/2021
 * @author: tranphuquy19@gmail.com
 */

export const FETCH_UT_SERVICES = 'FETCH_UT_SERVICES';

export const utServicesReducer = (state, {type, payload}) => {
    switch(type) {
        case FETCH_UT_SERVICES: {
            return [...payload];
        }
        default: return state;
    }
}
