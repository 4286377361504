import React, { useEffect, useReducer } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import 'react-tabs/style/react-tabs.css';
import UtServicesContext from "../store/contexts/UtServicesContext";
import Client from "../shared/client";
import { utServicesReducer, FETCH_UT_SERVICES } from '../store/reducers/UtServicesReducer';
import { UtServiceTable } from '../components/@ut-business/@ut-service-table';
import UtServiceState from '../store/states/UtServiceState';
import UtServiceComponent from '../components/@ut-business/@ut-service-component';

const UtServicePage = ({ history }) => {
    const [utServicesData, dispatchUtServiceData] = useReducer(utServicesReducer, []);

    useEffect(() => {
        const _fetchData = async () => {
            const { data } = await Client('business-type', 'GET', undefined, true);
            dispatchUtServiceData({
                type: FETCH_UT_SERVICES,
                payload: [...data]
            });
        }
        _fetchData();
    }, [])

    return (
        <UtServicesContext.Provider value={[utServicesData, dispatchUtServiceData]}>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <PageTitle title="Dịch Vụ" subtitle="Quản lí"
                        className="text-sm-left mb-3" />
                    {/* <Button theme="primary" pill className="mr-3"
                    disabled={isDisableSave}
                    onClick={onSave}>Save
                        changes</Button> */}
                </Row>
                <Tabs>
                    <TabList>
                        <Tab>DS Dịch Vụ</Tab>
                        <Tab>Tạo Mới</Tab>
                    </TabList>
                    <TabPanel>
                        <UtServiceTable history={history} />
                    </TabPanel>
                    <TabPanel>
                        <UtServiceComponent service={UtServiceState} />
                    </TabPanel>
                </Tabs>
            </Container>
        </UtServicesContext.Provider>
    )
}

export default UtServicePage;
