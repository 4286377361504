/*
 * Created by @tranphuquy19 on 18/01/2021
 * @author: tranphuquy19@gmail.com
 */

const ContactState = {
    index: '',
    name: '',
    image:'',
    detail: '',
    phoneNumber: '',
    email: '',
    location: ''
}

export default ContactState;
