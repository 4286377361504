import React, {useContext} from "react";
import {Button, Card, CardBody, CardHeader, Row} from "shards-react";
import FileItem from "./@file-item";
import {DownloadsContext} from "../../store/contexts/DowloadsContext";
import {ADD_NEW_CATALOG} from "../../store/reducers/DownloadsReducer";

export const DownloadsComponent = ({location}) => {
    const [downloads, dispatchDownloads] = useContext(DownloadsContext);

    const renderDownloadItems = () => {
        try {
            return downloads.map((download, index) => <FileItem key={index} download={download} index={index}/>)
        } catch (e) {
            return <></>;
        }
    }

    return (
        <Card>
            <CardHeader className="border-bottom">
                <Row style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 25px',
                    alignItems: 'center'
                }}>
                    <h5 className="m-0">Files</h5>
                    <Button theme="success" onClick={() => {
                        dispatchDownloads({
                            type: ADD_NEW_CATALOG
                        })
                    }}>Add new item</Button>
                </Row>
            </CardHeader>
            <CardBody>
                {renderDownloadItems()}
            </CardBody>
        </Card>
    )
}
