/*
 * Created by @tranphuquy19 on 18/01/2021
 * @author: tranphuquy19@gmail.com
 */

import React, { useEffect, useState } from 'react';
import {
    Button, Card,
    CardBody, CardFooter,
    CardHeader,
    Container,
    Row
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import ContactState from "../store/states/ContactState";
import Client from '../shared/client';
import { store } from "react-notifications-component";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import RowTableContact from './@RowTableContact';

const ContactPage = () => {
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        Client('contacts', 'GET').then(({ data }) => {
            if (data.content) {
                setContacts(data.content);
            }
        }).catch(e => {
            store.addNotification({
                title: "Fetch contacts failed",
                message: `Has some error while fetching the contacts`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        })
    }, [])


    const onSubmit = () => {
        Client('contact', 'POST', {
            content: contacts
        }).then(({ data }) => {
            setContacts(data.content);
            store.addNotification({
                title: "Update successful",
                message: `Contact was updated successfully.`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            });
        }).catch(e => {
            store.addNotification({
                title: "Error!",
                message: `Failed to update contact: ${contacts.title}.`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        });
    }

    const onAddRows = () => {
        let newRow = ContactState;
        setContacts([...contacts, newRow])
    }

    const renderHeaderColumn = () => {
        let dataColumn = ["#", "Name", "Address Detail", "Image", "Phone Number"
            , "Email", "Location"];
        return dataColumn.map((name, key) => {
            return (
                <th scope="col" className="border-0" key={key}>
                    {name}
                </th>
            )
        })
    }

    const renderBody = () => {
        return contacts.map((_c, index) => {
            if (_c === ContactState) {
                return (
                    <RowTableContact contact={_c} index={index} key={index} state={false} update={updateRow} deleteRow={deleteRow} />
                )
            }
            return (
                <RowTableContact contact={_c} index={index} key={index} state={true} update={updateRow} deleteRow={deleteRow} />
            )
        })
    }

    const updateRow = (index, data) => {
        let newContacts = [...contacts];
        newContacts[index] = data;
        setContacts(newContacts);
    }

    const deleteRow = (index) => {
        let newContacts = [...contacts];
        if (window.confirm(`Are you sure you want to delete ${newContacts[index].name}!`)) {
            newContacts.splice(index, 1);
            setContacts(newContacts);
            renderBody();
        }
    }
    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center'
            }}>
                <PageTitle title="Edit contacts"
                    subtitle="Page Manager"
                    className="text-sm-left mb-3" />
            </Row>
            <Tabs>
                <TabList>
                    <Tab>Editor</Tab>
                </TabList>
                <TabPanel>
                    <Card>
                        <CardHeader style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                            <h6 className="m-0">Headquarters</h6>
                            <Button theme={'success'}
                                onClick={() => onSubmit()}>Update</Button>
                        </CardHeader>
                        <CardBody style={{ paddingTop: '0px' }}>
                            <table className="table mb-0">
                                <thead className="bg-light">
                                    <tr>
                                        {renderHeaderColumn()}
                                        <th scope="col" className="border-0">
                                            <Button pill size="sm" theme="warning" onClick={() => onAddRows()}>
                                                <i className="material-icons" style={{ fontSize: "20px" }}>add</i>
                                            </Button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderBody(contacts)}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </TabPanel>
            </Tabs>
        </Container>
    )
}

export default ContactPage;
