import React, { useEffect, useReducer, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Container, Row } from "shards-react";
import UserForm from "../components/@user/@user-form";
import UsersTable from "../components/@user/@user-table";
import PageTitle from "../components/common/PageTitle";
import client from "../shared/client";
import { UserContext } from "../store/contexts/UsersContext";
import { FETCH_USERS, UserReducer } from "../store/reducers/UsersReducer";
import userState from "../store/states/User";

const UserManagerPage = ({history}) => {
  const [usersData, dispatchUsersData] = useReducer(UserReducer, [userState]);

  useEffect(() => {
    client('users', 'GET').then(({ data }) => {
      dispatchUsersData({
        type: FETCH_USERS,
        payload: data
      })
    }).catch(e => alert(e));
  }, [])

  return (
    <UserContext.Provider
      value={[usersData, dispatchUsersData]}
    >
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4" style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center'
        }}>
          <PageTitle title="Account" subtitle="Page Manager"
            className="text-sm-left mb-3" />
        </Row>
        <Tabs>
          <TabList>
            <Tab>Accounts</Tab>
            <Tab>Create new Account</Tab>
          </TabList>
          <TabPanel>
            <UsersTable history={history} />
          </TabPanel>
          <TabPanel>
            <UserForm user={userState} />
          </TabPanel>
        </Tabs>
      </Container>

    </UserContext.Provider>
  )

}

export default UserManagerPage;
