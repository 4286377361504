/*
 * Created by @tranphuquy19 on 05/12/2020
 * @author: tranphuquy19@gmail.com
 */

import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import EditSliderImagesHomePage
    from "../components/@edit-image-slider-home-page/@EditSliderImagesHomePage";
import EditSliderProductsHomePage
    from "../components/@edit-image-slider-home-page/@EditSliderProductsHomePage";
import { HomeContext } from "../store/contexts/HomeContext";
import { FETCH_DATA, homeReducer } from "../store/reducers/HomeReducer";
import { homeState } from "../store/states/HomeState";
import Client from "../shared/client";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { EditTopProduct } from "../components/@edit-top-product-home-page/@EditTopProductComponent";
import { store } from "react-notifications-component";
import TagPageInfo from "./@TagPageInfo.js"
import { ProductNavbarComponent } from "../components/@product-nav-bar/@ProductNavbarComponent";
import { EditTopNews } from '../components/@edit-top-news/@EditTopNewsComponent';
import { EditTopProject } from "../components/@edit-top-projects/@edit-top-projects";
import OurStoryCard from "../components/@out-story/@our-story-card";
import { HeaderPageImagesCard } from "../components/@header-page-images/HeaderPageImagesCard";
import { EditTopSolution } from '../components/@edit-top-solutions/@edit-top-solutions';

let counter = 0;

const EditHomePage = () => {
    const [homeData, dispatchHomeData] = useReducer(homeReducer, homeState);
    const [isDisableSave, setDisableSave] = useState(true);

    useEffect(() => {
        Client('meta-data')
            .then(({ data }) => {
                data.topProducts = data.topProducts.map(({ model, name }) => {
                    return { value: model, label: `${model} - ${name}` }
                });
                dispatchHomeData({
                    type: FETCH_DATA,
                    payload: data
                });
                counter++;
            })
            .catch(e => {
                store.addNotification({
                    title: "Fetch data failed",
                    message: `Error when fetching data`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            });

        return () => {
        };
    }, [])

    useEffect(() => {
        if (counter++ > 2) setDisableSave(false);
    }, [homeData])

    const onSave = () => {
        Client('meta-data', 'POST', homeData).then(({ data }) => {
            dispatchHomeData({
                type: FETCH_DATA,
                payload: data
            });
            setDisableSave(true)
            store.addNotification({
                title: "Update successful",
                message: `Home page has been successfully updated`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        })
            .catch(e => {
                store.addNotification({
                    title: "Update failed",
                    message: `Home page has failed to update`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            });
    }

    return (
        <HomeContext.Provider value={[homeData, dispatchHomeData]}>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <PageTitle title="Home" subtitle="Page Manager"
                        className="text-sm-left mb-3" />
                    <Button theme="primary" pill className="mr-3"
                        disabled={isDisableSave}
                        onClick={onSave}>Save
                        changes</Button>
                </Row>
                <Tabs>
                    <TabList>
                        <Tab>Slider</Tab>
                        <Tab>Top Products</Tab>
                        <Tab>Top Projects</Tab>
                        <Tab>Top Solutions</Tab>
                        <Tab>Top News&nbsp;&amp;&nbsp;Events</Tab>
                        <Tab>Navigation Bar</Tab>
                        <Tab>Page Info.</Tab>
                    </TabList>
                    <TabPanel>
                        <Row>
                            <Col lg="6" md="12" sm="12" className="mb-4">
                                <EditSliderImagesHomePage />
                            </Col>
                            <Col lg="6" md="12" sm="12" className="mb-4">
                                <EditSliderProductsHomePage {...homeData} />
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-4">
                                <EditTopProduct />
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-4">
                                <EditTopProject />
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-4">
                                <EditTopSolution />
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-4">
                                <EditTopNews />
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-4">
                                <ProductNavbarComponent />
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel>
                        <Row>
                            <Col lg="12" md="12" sm="12" className="mb-1">
                                <TagPageInfo />
                                <OurStoryCard />
                                <HeaderPageImagesCard />
                            </Col>
                        </Row>
                    </TabPanel>
                </Tabs>
            </Container>
        </HomeContext.Provider>
    );
};

export default EditHomePage;
