export const newsState = [{
    id: '',
    title: '',
    description:'',
    content: '',
    status: 0,
    picture_link: '',
    published_time: '',
    creator:'',
    createdAt: '',
    updatedAt: ''
}]