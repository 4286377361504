/*
 * Created by @tranphuquy19 on 05/12/2020
 * @author: tranphuquy19@gmail.com
 */
import userState from "../store/states/User";
import jwt from 'jsonwebtoken';

const storeUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
};

const updateUser = (user) => {
    const {token} = getUserStored();
    user.token = token;
    storeUser(user);
}

const isLogined = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    try {
        return !!user.token;
    } catch (e) {
        return false;
    }
};

const getUserStored = () => {
    if (isLogined()) {
        return JSON.parse(localStorage.getItem('user'));
    } else return userState;
};

const isVerifiedUser = (user) => {
    const {token} = user;
    if (token) {
        try {
            const payload = jwt.decode(token);
            return Date.now().valueOf() < payload.exp * 1000;
        } catch (e) {
            return false;
        }
    } else return false;
}

export {
    storeUser, isLogined, getUserStored, updateUser, isVerifiedUser
};
