/*
 * Created by @tranphuquy19 on 05/12/2020
 * @author: tranphuquy19@gmail.com
 */

import headerHelper from "./header-helper";
import config from "./config"
import axios from 'axios';

let FileUploader = async (endpoint, formData) => {
  const url = `${config.API_URL}/${endpoint}`;
  return await axios.post(url, formData, {
    headers: {
      ...headerHelper(),
      'Content-Type': 'multipart/form-data'
    }
  });
}

export default FileUploader;
