/*
 * Created by @tranphuquy19 on 24/12/2020
 * @author: tranphuquy19@gmail.com
 */

import {ProductState} from "../states/ProductState";

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_KINDS = 'FETCH_KINDS';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const EDIT_CATEGORY_AT_INDEX = 'EDIT_CATEGORY_AT_INDEX';
export const EDIT_KIND_AT_INDEX = 'EDIT_KIND_AT_INDEX';
export const EDIT_PRODUCT_AT_INDEX = 'EDIT_PRODUCT_AT_INDEX';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_KIND = 'ADD_KIND';
export const ADD_PRODUCT = 'ADD_PRODUCT';

export const productsReducer = (state, {type, payload}) => {
    switch (type) {
        case FETCH_CATEGORIES:
            return {...state, productCategories: payload};
        case FETCH_KINDS:
            return {...state, productKinds: payload};
        case FETCH_PRODUCTS:
            return {...state, products: payload};
        case EDIT_CATEGORY_AT_INDEX:
            const {index: _index1, key: _key1, value: _value1} = payload;
            const _categories1 = state['productCategories'];
            _categories1[_index1] = {
                ..._categories1[_index1],
                [_key1]: _value1
            };
            return {...state, productCategories: [..._categories1]};
        case EDIT_KIND_AT_INDEX:
            const {index: _index2, key: _key2, value: _value2} = payload;
            const _categories2 = state['productKinds'];
            _categories2[_index2] = {
                ..._categories2[_index2],
                [_key2]: _value2
            };
            return {...state, productKinds: [..._categories2]};
        case EDIT_PRODUCT_AT_INDEX:
            return {...state};
        case ADD_CATEGORY:
            return {
                ...state,
                productCategories: [
                    ...state.productCategories, {
                        name: '',
                        description: ''
                    }]
            };
        case ADD_KIND:
            return {
                ...state,
                productKinds: [
                    ...state.productKinds, {
                        name: '',
                        description: '',
                        category: {
                            id: '',
                            name: '',
                            description: '',
                        }
                    }]
            };
        case ADD_PRODUCT:
            return {
                ...state,
                products: [
                    ...state.products, {
                        ...ProductState
                    }]
            };
        default:
            return state;
    }
}
