/*
 * Created by @tranphuquy19 on 24/12/2020
 * @author: tranphuquy19@gmail.com
 */
import React, { useContext, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader, FormInput, FormSelect,
    ListGroupItem,
    Row
} from "shards-react";
import {
    REMOVE_SLIDER_IMAGE
} from "../../store/reducers/HomeReducer";
import { ProductsContext } from "../../store/contexts/ProductsContext";
import {
    ADD_KIND,
    EDIT_KIND_AT_INDEX, FETCH_CATEGORIES, FETCH_KINDS, FETCH_PRODUCTS
} from "../../store/reducers/ProductsReducer";
import Client from '../../shared/client';
import { store } from 'react-notifications-component';
import DeleteKindModal from './@DeleteKindModal';

export const EditKinds = () => {
    const [productsData, dispatchProductsData] = useContext(ProductsContext);
    const [modalState, setModalState] = useState(false);
    const [productsDeleted, setProductsDeleted] = useState([]);
    const [kindDeleted, setKindDeleted] = useState('');
    const { productCategories } = productsData;
    const { productKinds } = productsData;

    const toggle = () => {
        setProductsDeleted([]);
        setKindDeleted('');
        setModalState(!modalState);
    }

    const onChange = (e, dispatchType, index) => {
        let { value, name } = e.target;
        if (name === 'category') value = { id: value };
        dispatchProductsData({
            type: dispatchType,
            payload: {
                index,
                key: name,
                value: value
            },
        })
    }

    const fetchAgain = () => {
        Client('pcategory', 'GET').then(({ data }) => {
            dispatchProductsData({
                type: FETCH_CATEGORIES,
                payload: data
            })
        }).catch(e => alert(e));
        Client('pkind', 'GET').then(({ data }) => {
            dispatchProductsData({
                type: FETCH_KINDS,
                payload: data
            })
        }).catch(e => alert(e));
        Client('p', 'GET').then(({ data }) => {
            dispatchProductsData({
                type: FETCH_PRODUCTS,
                payload: data
            })
        }).catch(e => alert(e));
    }

    const onUpdate = async (e, index) => {
        try {
            await Client(`pkind/${productKinds[index].id}`, 'PATCH', productKinds[index]);
            fetchAgain();
            store.addNotification({
                title: "Update successful",
                message: `Product type: ${productKinds[index].name} has been successfully updated`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        } catch (e) {
            store.addNotification({
                title: "Update failed",
                message: `Product type: ${productKinds[index].name} has failed to update`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        }

    }

    const onCreate = async (e, index) => {
        try {
            await Client('pkind', 'POST', productKinds[index]);
            fetchAgain();
            store.addNotification({
                title: "Create successful",
                message: `${productKinds[index].name} has been successfully created`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        } catch (e) {
            store.addNotification({
                title: "Create failed",
                message: `Failed to create product type: ${productKinds[index].name || 'Unknown'}`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        }

    }

    const onDeleteKind = async ({ index }) => {
        try {
            const { data: { products } } = await Client(`pkind/${productKinds[index].id}/delete`, 'GET')
            setProductsDeleted(products);
            setKindDeleted(productKinds[index].id);
            setModalState(true);
            dispatchProductsData({
                type: REMOVE_SLIDER_IMAGE,
                payload: { index }
            })
        } catch (e) {
            alert(e)
        }
    }


    return (
        <>
            <Card small className="h-100">
                <CardHeader className="border-bottom">
                    <Row style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 25px',
                        alignItems: 'center'
                    }}>
                        <h5 className="m-0">Product Types</h5>
                        <Button theme="success" onClick={() => {
                            dispatchProductsData({
                                type: ADD_KIND
                            })
                        }}>Add new item</Button>
                    </Row>
                </CardHeader>
                <CardBody className="pt-0">
                    <ListGroupItem className="px-1" style={{
                        maxHeight: '400px',
                        minHeight: '200',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        paddingTop: 0
                    }}>

                        {productKinds.map((item, index) => {
                            return <div key={index}>
                                <Card small className="mb-1">
                                    <CardHeader className="border-bottom">
                                        <h6 className="m-0">Type {index + 1}</h6>
                                    </CardHeader>
                                    <CardBody className="pt-0">
                                        <FormSelect name="category"
                                            onChange={(e) => {
                                                onChange(e, EDIT_KIND_AT_INDEX, index)
                                            }}
                                            value={item.category.id}
                                            className="mb-2 mt-1">
                                            <option value={''}>--- Product
                                                Categories ---
                                            </option>
                                            {productCategories.map(c => {
                                                return (
                                                    <option value={c.id}
                                                        key={c.id}>{c.name}</option>
                                                )
                                            })}
                                        </FormSelect>
                                        <FormInput
                                            placeholder="Name of Product type"
                                            className="mb-2 mt-1"
                                            name="name" value={item.name}
                                            onChange={(e) => {
                                                onChange(e, EDIT_KIND_AT_INDEX, index)
                                            }}
                                        />
                                        <FormInput
                                            placeholder="Description of Product type"
                                            name="description"
                                            className="mb-2 mt-1"
                                            value={item.description}
                                            onChange={(e) => {
                                                onChange(e, EDIT_KIND_AT_INDEX, index)
                                            }}
                                        />
                                        <div style={{ display: 'flex' }}>
                                            <Button className="mr-1" theme="success"
                                                onClick={(e) => {
                                                    if (item.id) onUpdate(e, index);
                                                    else onCreate(e, index);
                                                }}>{item.id ? 'Update' : 'Create'}</Button>
                                            <Button theme="danger"
                                                style={{ marginLeft: 'auto' }}
                                                onClick={() => onDeleteKind({ index })}>Remove</Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        })}
                    </ListGroupItem>
                </CardBody>
            </Card>
            <DeleteKindModal kindId={kindDeleted} products={productsDeleted} open={modalState} toggle={toggle}/>
        </>
    )
}
