import React, { useEffect, useState, useContext } from 'react';
import { HomeContext } from "../../store/contexts/HomeContext";
import Client from "../../shared/client";
import Select from 'react-select';
import {
    EDIT_TOP_SOLUTIONS
} from "../../store/reducers/HomeReducer";
import _ from 'lodash';
import { UtHomeContext } from '../../store/contexts/UtHomeContext';
import { UT_EDIT_TOP_SOLUTIONS } from '../../store/reducers/UtHomeReducer';

export const UtEditTopServices = () => {
    const [utHomeData, dispatchUtHomeData] = useContext(UtHomeContext);
    const [optionSelected, setOptionSelected] = useState([]);
    const [options, setOptions] = useState([]);

    const fetchSolutions = async () => {
        const { data } = await Client('business-type', 'GET', undefined, true);
        return data;
    }

    useEffect(() => {
        const _fetchSolutions = async () => {
            const _solutions = await fetchSolutions();
            const _options = _solutions.map(({ id, title }) => {
                return { value: id, label: title };
            });
            setOptions(_options);
        }
        _fetchSolutions();
    }, [])

    useEffect(() => {
        const _optionSelected = utHomeData.topSolutions.map(({ value, label }) => {
            return { value, label };
        })
        setOptionSelected((_optionSelected));
    }, [options, utHomeData])

    const onOptionsChange = (selectedOptions) => {
        selectedOptions = selectedOptions || []; // selectedOptions === null nếu ko có phần tử được selected
        const results = selectedOptions.map(({ value }) => {
            return _.find(options, { value });
        })
        dispatchUtHomeData({
            type: UT_EDIT_TOP_SOLUTIONS,
            payload: results
        })
    }

    return (
        <>
            <h5>Chọn các services để hiện thị cho phần footer!</h5>
            <Select options={options} isMulti={true} onChange={onOptionsChange}
                value={optionSelected} />
        </>
    )
}
