/*
 * Created by @tranphuquy19 at 08/03/2021
 * @author: tranphuquy19@gmail.com
 */

import React, {useState, useEffect, useContext} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader
} from "shards-react";
import InternalProjectState from "../../store/states/InternalProjectState";
import Client from "../../shared/client";
import {InternalProjectModal} from "./@internal-project-modal";
import {AuthContext} from "../../store/contexts/AuthContext";
import {CSVLink} from 'react-csv';
import moment from 'moment';

export const InternalProjectsTable = () => {
    const [user,] = useContext(AuthContext);
    const [projects, setProjects] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [project, setProject] = useState(InternalProjectState);
    const [data, setData] = useState([]);

    const fetchedProjects = async () => {
        const {data} = await Client('internal-projects', 'GET');
        setProjects(data);
    }

    useEffect(() => {
        const _data = projects.map(p => {
            return {
                name: p.name,
                location: p.location,
                investor: p.investor,
                design_consultant: p.designConsultant,
                contractor: p.contractor,
                status: p.status,
                start_day: p.startDate,
                creator: p.creator.name,
                creator_email: p.creator.email,
                created_at: moment(p.createdAt).utc(false).format('YYYY-MM-DD hh:mm:ss'),
                updated_at: moment(p.updatedAt).utc(false).format('YYYY-MM-DD hh:mm:ss'),
                // created_at_formatted: moment(p.createdAt).utc(false).format('MMMM Do YYYY, h:mm:ss a'),
                // updated_at_formatted: moment(p.updatedAt).utc(false).format('MMMM Do YYYY, h:mm:ss a'),
            }
        })
        setData(_data);
    }, [projects])

    useEffect(() => {
        fetchedProjects();
    }, [])

    const toggle = () => setOpen(!isOpen);

    const onProjectChange = ({target}) => {
        const {name, value} = target;
        setProject({...project, [name]: value});
    }

    const clearProject = () => {
        setProject(InternalProjectState);
    }

    const createProject = async (_project) => {
        await Client('internal-projects', 'POST', _project);
        fetchedProjects()
    }

    const updateProject = async (_project) => {
        await Client(`internal-projects/${_project.id}`, 'PATCH', _project);
        fetchedProjects()
    }

    const removeProject = async (_project) => {
        if (window.confirm('Are you sure you want to delete this')) {
            await Client(`internal-projects/${_project.id}`, 'DELETE')
            fetchedProjects()
        }
    }

    const onEdit = (project) => {
        setProject(project);
        toggle()
    }

    const renderProjectTableBody = () => {
        return projects.map((value, index) => {
            if (user.roles.includes('ADMIN') || (user.roles.includes('MANAGER') && (user.id === value.creator.id))) {
                return <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.name}</td>
                    <td>{value.location}</td>
                    <td>{value.investor}</td>
                    <td>{value.designConsultant}</td>
                    <td>{value.contractor}</td>
                    <td>{value.status}</td>
                    <td>{value.startDate}</td>
                    <td>
                        <Button theme={'primary'} style={{fontSize: 13}}><i
                            className="material-icons"
                            onClick={() => onEdit(value)}>create</i></Button>
                        <Button theme={'danger'}
                                onClick={() => removeProject(value)}
                                style={{fontSize: 13, marginLeft: 3}}><i
                            className="material-icons">remove_circle_outline</i></Button>
                    </td>
                </tr>
            }
        })
    }

    return (
        <Card small className="mb-4">
            <CardHeader className="border-bottom" style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <h6 className="m-0">Project Manager</h6>
                <div>
                    <Button theme={'success'}
                            className="mr-1">
                        <CSVLink data={data} filename={'projects.csv'} style={{color: 'white', textDecoration: 'none'}}>
                            Download all
                        </CSVLink>
                    </Button>
                    <Button theme={'primary'}
                            onClick={() => toggle()}
                            className="mr-1">Create</Button>
                    <Button theme={'warning'}
                            onClick={() => {
                                fetchedProjects()
                            }}>Refresh</Button>
                </div>
            </CardHeader>
            <CardBody className="p-0 pb-3">
                <table className="table mb-0"
                       style={{maxHeight: '500px', overflowY: 'scroll'}}>
                    <thead className="bg-light">
                    <tr>
                        <th scope="col" className="border-0">
                            #
                        </th>
                        <th scope="col" className="border-0">
                            Project name
                        </th>
                        <th scope="col" className="border-0">
                            Location
                        </th>
                        <th scope="col" className="border-0">
                            Investor
                        </th>
                        <th scope="col" className="border-0">
                            Design Consultant
                        </th>
                        <th scope="col" className="border-0">
                            Contractor
                        </th>
                        <th scope="col" className="border-0">
                            Status
                        </th>
                        <th scope="col" className="border-0">
                            Start Date
                        </th>
                        <th scope="col" className="border-0">
                            &nbsp;
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {renderProjectTableBody()}
                    </tbody>
                </table>
                <InternalProjectModal project={project} toggle={toggle}
                                      clearProject={clearProject}
                                      onProjectChange={onProjectChange}
                                      isOpen={isOpen}
                                      createProject={createProject}
                                      updateProject={updateProject}
                />
            </CardBody>
        </Card>
    )
}
