/*
 * Created by @tranphuquy19 on 27/12/2020
 * @author: tranphuquy19@gmail.com
 */
import React, {useContext, useState, useEffect} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col, FormInput,
    FormSelect,
    Row
} from "shards-react";
import {ProductsContext} from "../../store/contexts/ProductsContext";
import Client from '../../shared/client';
import config from '../../shared/config';
import {ModalProduct} from "./@ModalProduct";
import {ProductState} from "../../store/states/ProductState";
import {
    FETCH_CATEGORIES,
    FETCH_KINDS, FETCH_PRODUCTS
} from "../../store/reducers/ProductsReducer";

export const ProductsTable = () => {
    const [productsData, dispatchProductsData] = useContext(ProductsContext);
    const [productsState, setProductsState] = useState([]);

    const {products, productCategories, productKinds} = productsData;

    const [productNameFilter, setProductNameFilter] = useState('');
    const [productCategoryFilter, setProductCategoryFilter] = useState('');
    const [productKindFilter, setProductKindFilter] = useState('');

    const [productModal, setProductModal] = useState(ProductState);
    const [modalState, setModalState] = useState(false);

    useEffect(() => {
        setProductsState(products);
    }, [products])

    const fetchAgain = () => {
        Client('pcategory', 'GET').then(({data}) => {
            dispatchProductsData({
                type: FETCH_CATEGORIES,
                payload: data
            })
        }).catch(e => alert(e));
        Client('pkind', 'GET').then(({data}) => {
            dispatchProductsData({
                type: FETCH_KINDS,
                payload: data
            })
        }).catch(e => alert(e));
        Client('p', 'GET').then(({data}) => {
            dispatchProductsData({
                type: FETCH_PRODUCTS,
                payload: data
            })
        }).catch(e => alert(e));
    }

    const findProducts = async () => {
        const {data} = await Client('p', 'GET', {
            category: !productCategoryFilter ? undefined : productCategoryFilter,
            kind: !productKindFilter ? undefined : productKindFilter
        })
        const result = productNameFilter ? data.filter(p => p.name.toLowerCase().includes(productNameFilter.toLowerCase()) || p.model.toLowerCase().includes(productNameFilter.toLowerCase())) : data;
        setProductsState(result);
    }

    const openProductModal = (product) => {
        setProductModal(product);
        setModalState(true);
    }

    const getTableBody = () => {
        return productsState.map((p, i) => {
            return (
                <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{p.model}</td>
                    <td>{p.name}</td>
                    <td>{p.kind.category.name}</td>
                    <td>{p.kind.name}</td>
                    <td>
                        <Button
                            onClick={() => openProductModal(p)}>Edit</Button>
                        <Button theme={'success'}
                                className="ml-2"
                                onClick={() => viewProduct(p.model)}>View</Button>
                    </td>
                </tr>
            )
        })
    }

    const toggle = () => {
        setModalState(!modalState);
        fetchAgain();
    }

    const handleFilterOnChange = (e) => {
        const {value, name} = e.target;
        switch (name) {
            case 'productName':
                setProductNameFilter(value);
                break;
            case 'productCategories':
                setProductCategoryFilter(value);
                break;
            case 'productKinds':
                setProductKindFilter(value);
                break;
            default:
                break;
        }
    }

    const viewProduct = (model) => {
        const win = window.open(`${config.API_URL}/product?model=${encodeURIComponent(model)}`, '_blank');
        win.focus();
    }

    return (
        <Card small className="mb-4">
            <CardHeader className="border-bottom">
                <h6 className="m-0">Products</h6>
                <Row>
                    <Col md={3} sm={12}>
                        <FormInput value={productNameFilter}
                                   placeholder="Enter product name | model"
                                   name="productName"
                                   onChange={handleFilterOnChange}/>
                    </Col>
                    <Col md={3} sm={12}>
                        <FormSelect name="productCategories"
                                    onChange={handleFilterOnChange}
                                    value={productCategoryFilter}>
                            <option value={''}>--- Product Categories ---
                            </option>
                            {productCategories.map(c => {
                                return (
                                    <option value={c.name}
                                            key={c.id}>{c.name}</option>
                                )
                            })}
                        </FormSelect>
                    </Col>
                    <Col md={3} sm={12}>
                        <FormSelect name="productKinds"
                                    onChange={handleFilterOnChange}
                                    value={productKindFilter}
                        >
                            <option value={''}>--- Product Types ---</option>
                            {productKinds.map(k => {
                                return (
                                    <option value={k.name}
                                            key={k.id}>{k.name}</option>
                                )
                            })}
                        </FormSelect>
                    </Col>
                    <Col md={1} sm={12} style={{
                        display: 'flex',
                        flexDirection: 'row-reverse'
                    }}>
                        <Button onClick={findProducts}>Find</Button>
                    </Col>
                    <Col md={2} sm={12} style={{
                        display: 'flex',
                        flexDirection: 'row-reverse'
                    }}>
                        <Button theme={'warning'} className="mr-5"
                                onClick={() => openProductModal(ProductState)}>Create</Button>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className="p-0 pb-3">
                <table className="table mb-0"
                       style={{maxHeight: '500px', overflowY: 'scroll'}}>
                    <thead className="bg-light">
                    <tr>
                        <th scope="col" className="border-0">
                            #
                        </th>
                        <th scope="col" className="border-0">
                            Model
                        </th>
                        <th scope="col" className="border-0">
                            Product Name
                        </th>
                        <th scope="col" className="border-0">
                            Category
                        </th>
                        <th scope="col" className="border-0">
                            Type
                        </th>
                        <th scope="col" className="border-0">
                            &nbsp;
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {getTableBody()}
                    </tbody>
                </table>
            </CardBody>
            <ModalProduct product={productModal} toggle={toggle}
                          state={modalState}/>
        </Card>
    )
}
