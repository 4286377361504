export const FETCH_USERS = "FETCH_USERS";

export const UserReducer = (state, { type, payload }) => {
  switch (type) {
    case FETCH_USERS:
      state = payload;
      return state;
    default:
      return state;
  }
}