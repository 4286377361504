import React, { useReducer, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import routes from './routes';
import withTracker from './withTracker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './shards-dashboard/styles/shards-dashboards.1.1.0.min.css';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { authReducer, LOGON } from './store/reducers/AuthReducer';
import { AuthState } from './store/states/AuthState';
import { AuthContext } from './store/contexts/AuthContext';
import { getUserStored, isVerifiedUser } from './shared/auth';
import { MetaDataState } from './store/states/MetaDataState';
import { metaDataReducer } from './store/reducers/MetaDataReducer';
import { MetaDataContext } from './store/contexts/MetaDataContext';

export default () => {
    const [auth, dispatchAuth] = useReducer(authReducer, AuthState);
    const [metaData, dispatchMetaData] = useReducer(metaDataReducer, MetaDataState);
    const [, setNext] = useState(false);

    useEffect(() => {
        const user = getUserStored();
        dispatchAuth({
            type: LOGON,
            payload: user,
        });
    }, []);

    useEffect(() => {
        setNext(isVerifiedUser(auth));
        const next2 = isVerifiedUser(getUserStored());
        if (!next2 && window.location.pathname !== '/login')
            window.location.replace('/login');
        else if (next2 && window.location.pathname === '/login') {
            if (auth.roles.includes('ADMIN'))
                window.location.replace('/edit-homepage');
            else window.location.replace('/register-project');
        }
    }, [auth]);

    return (
        <Router basename={process.env.REACT_APP_BASENAME || ''}>
            <AuthContext.Provider value={[auth, dispatchAuth]}>
                <MetaDataContext.Provider value={[metaData, dispatchMetaData]}>
                    <div>
                        {/*{next ? <Redirect to="/" /> : <Redirect to="/login"/>}*/}
                        <ReactNotification />
                        {routes.map((route, index) => {
                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    component={withTracker(props => {
                                        return (
                                            <route.layout {...props}>
                                                <route.component {...props} />
                                            </route.layout>
                                        );
                                    })}
                                />
                            );
                        })}
                    </div>
                </MetaDataContext.Provider>
            </AuthContext.Provider>
        </Router>
    );
};
