export default function () {
    return [
        {
            title: "Trang Chủ",
            htmlBefore: '<i class="material-icons">home</i>',
            to: "/ut-home",
            display: false
        },
        {
            title: "Dịch Vụ",
            htmlBefore: '<i class="material-icons">support_agent</i>',
            to: "/ut-services",
            display: false
        },
        {
            title: "Dự Án",
            htmlBefore: '<i class="material-icons">work</i>',
            to: "/ut-projects",
            display: false
        },
        {
            title: "Tin Tức",
            htmlBefore: '<i class="material-icons">article</i>',
            to: "/ut-news",
            display: false
        },{
            title: "Sản phẩm",
            htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
            to: "/ut-products",
            display: false
        },
        {
            title: "Bản tin",
            htmlBefore: '<i class="material-icons">unsubscribe</i>',
            to: "/ut-newsletters",
            display: false
        },
        {
            title: "Liên hệ",
            htmlBefore: '<i class="material-icons">contact_page</i>',
            to: "/ut-contact",
            display: false
        },
    ];
}
