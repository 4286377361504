import React, { useEffect, useReducer } from "react";
import { Container, Row, Col } from "shards-react";
import {
    Card,
    CardBody,
    Button,
    Badge
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { ProductsContext } from "../store/contexts/ProductsContext";
import { FETCH_NEWS, newsReducer } from "../store/reducers/NewsReducer";
import { newsState } from "../store/states/NewsState";
import Client from '../shared/client';



const EditNewsPage = () => {
    const [newsData, dispatchNewsData] = useReducer(newsReducer, newsState);

    useEffect(() => {
        Client('news', 'GET').then(({ data }) => {
            dispatchNewsData({
                type: FETCH_NEWS,
                payload: data
            })
        }).catch(e => alert(e));
    }, [])

    return (
        <ProductsContext.Provider
            value={[newsData, dispatchNewsData]}>
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <Col sm={10} lg={10} md={10}>
                        <PageTitle sm="4" title="News &amp; Events" subtitle="Control News &amp; Events Page" className="text-sm-left" /></Col>
                    {/* <Col md={1} sm={1} lg={1}></Col> */}
                    <Col md={2} sm={2} lg={1}>
                        <Button theme={'warning'} block size="md" href="/add-news-events">Create</Button>
                    </Col>
                </Row>

                {/* First Row of Posts */}
                <Row>
                    {newsData.map((news, idx) => (
                        <Col lg="3" md="6" sm="12" className="mb-4" key={news.id}>
                            <Card small className="card-post card-post--1" title={""} href={`/add-news-events?id=${news.id}`}>
                                <div
                                    className="card-post__image"
                                    style={{ backgroundImage: `url(${encodeURI(news.picture_link)})` }}
                                >
                                    <Badge
                                        pill
                                        className={`card-post__category bg-${news.status ? "success" : "warning"}`}
                                    >
                                        {news.status ? "Published" : "Draft"}
                                    </Badge>
                                </div>
                                <CardBody>
                                    <h5 className="card-title">
                                        <a href={`/add-news-events?id=${news.id}`} className="text-fiord-blue">
                                            {news.title}
                                        </a>
                                    </h5>
                                    <b>Author:   {news.creator.name}</b>
                                    <br/><br/>
                                    <p className="card-text d-inline-block mb-3">{news.description.slice(0, 100)}</p>
                                    <br />
                                    <span className="text-muted">Created at: {news.createdAt.slice(0, 10).replace(/-/g, '/')}</span>
                                    <a href={`/add-news-events?id=${news.id}`} className="ml-2 pl-2">Read more</a>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </ProductsContext.Provider>
    )
};

export default EditNewsPage;
