import React, { useState, useEffect } from 'react';
import { Container, Row } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import ProjectState from "../store/states/ProjectState";
import Client from '../shared/client';
import qs from 'query-string';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ProjectComponent from "../components/@project/@project-component";
import { store } from "react-notifications-component";
import UtServiceState from '../store/states/UtServiceState';
import UtServiceComponent from '../components/@ut-business/@ut-service-component';

const UtEditServicePage = ({ location }) => {
    const [serviceState, setServiceState] = useState(UtServiceState);

    useEffect(() => {
        const { id } = qs.parse(location.search);
        if (id) {
            Client(`business-type/${id}`, 'GET', undefined, true).then(({ data }) => {
                setServiceState(data);
            }).catch(e => {
                store.addNotification({
                    title: "Error!",
                    message: `Yêu cầu thất bại: ${e}.`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            })
        }
    }, [location])

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center'
            }}>
                <PageTitle title="Dịch vụ"
                    subtitle="Chỉnh sửa"
                    className="text-sm-left mb-3" />
                <div style={{ width: '100%', marginTop: '4px' }}>
                    <Tabs>
                        <TabList>
                            <Tab>Cập nhật Dịch vụ</Tab>
                        </TabList>
                        <TabPanel>
                            <UtServiceComponent service={serviceState} />
                        </TabPanel>
                    </Tabs>
                </div>

            </Row>
        </Container>
    )
}

export default UtEditServicePage;
