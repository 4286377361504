import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import Client from '../../shared/client';
import { UtHomeContext } from '../../store/contexts/UtHomeContext';
import { UT_EDIT_TOP_NEWS } from '../../store/reducers/UtHomeReducer';

export const UtEditTopNews = () => {
    const [homeData, dispatchHomeData] = useContext(UtHomeContext);
    const [optionSelected, setOptionSelected] = useState([]);
    const [options, setOptions] = useState([]);

    const fetchNews = async () => {
        const { data } = await Client('news', 'GET', undefined, true);
        return data;
    };

    useEffect(() => {
        const _fetchNews = async () => {
            const news = await fetchNews();
            const data = news.map(value => {
                return {
                    value: value.id,
                    label: value.title,
                };
            });
            setOptions(data);
        };
        _fetchNews();
    }, []);

    useEffect(() => {
        const _optionsSelected = homeData.topNews.map(
            ({ value, label }) => {
                return { value, label };
            },
        );
        setOptionSelected(_optionsSelected);
    }, [options, homeData]);

    const onOptionsChange = selectedOptions => {
        selectedOptions = selectedOptions || []; // selectedOptions === null nếu ko có phần tử được selected
        const results = selectedOptions.map(({ value }) => {
            return _.find(options, { value });
        });
        dispatchHomeData({
            type: UT_EDIT_TOP_NEWS,
            payload: results,
        });
    };

    return (
        <>
            <h5>Select News&amp;Events to show on the Home page!</h5>
            <Select
                options={options}
                isMulti={true}
                onChange={onOptionsChange}
                value={optionSelected}
            />
        </>
    );
};
