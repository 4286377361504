/*
 * Created by @tranphuquy19 on 18/01/2023
 * @author: tranphuquy19@gmail.com
 */

export const UtNewsLettersState = [
    // {
    //     id: '',
    //     email: '',
    //     createdDate: new Date(),
    //     updatedDate: new Date(),
    // }
];
