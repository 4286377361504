/*
 * Created by @tranphuquy19 on 29/12/2020
 * @author: tranphuquy19@gmail.com
 */

import {AuthState} from "../states/AuthState";

export const LOGON = 'LOGON';
export const LOGOFF = 'LOGOFF';

export const authReducer = (state, action) => {
    switch (action.type) {
        case LOGON:
            const {payload: _payload1} = action;
            return {..._payload1};
        case LOGOFF:
            window.localStorage.removeItem('user');
            return {...AuthState};
        default:
            return state;
    }
}
