/*
 * Created by @tranphuquy19 on 20/02/2021
 * @author: tranphuquy19@gmail.com
 */
import React, { useContext, useState } from 'react';

import {
    Card,
    CardHeader,
    Col,
    Form,
    FormInput,
    ListGroup,
    ListGroupItem,
    Row,
    FormCheckbox,
    Collapse,
} from 'shards-react';
import { UtHomeContext } from '../../store/contexts/UtHomeContext';
import {
    UT_ABOUT_US,
    UT_EDIT_OUR_STORY,
    UT_OTHER_VALUES,
    UT_OUR_VALUES,
    UT_TOGGLE_METRICS,
    UT_TOGGLE_OUR_VALUES,
} from '../../store/reducers/UtHomeReducer';
import CustomFileUpload from '../components-overview/CustomFileUpload';

export const UtOtherValues = () => {
    const [utHomeData, dispatchUtHomeData] = useContext(UtHomeContext);
    const [open, setOpen] = useState(false);

    const onStoryTextChange = e => {
        const {
            target: { name, value },
        } = e;
        dispatchUtHomeData({
            type: UT_OTHER_VALUES,
            payload: {
                ...utHomeData.otherValues,
                [name]: value,
            },
        });
    };

    return (
        <Card className="mb-4">
            <CardHeader className="border-bottom">
                <h6
                    className="m-0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setOpen(!open)}
                >
                    Other Values
                    <i className="material-icons">
                        {open ? 'expand_less' : 'expand_more'}
                    </i>
                </h6>
            </CardHeader>
            <ListGroup flush>
                <Collapse open={open}>
                    <ListGroupItem className="p-3">
                        <FormCheckbox
                            toggle
                            checked={utHomeData.isShowMetrics}
                            onChange={() =>
                                dispatchUtHomeData({
                                    type: UT_TOGGLE_METRICS,
                                })
                            }
                        >
                            Hiển thị các thông số
                        </FormCheckbox>
                        <Row>
                            <Col>
                                <Form>
                                    <Row>
                                        <Col md="3" className="form-group">
                                            <label htmlFor="totalProjects">
                                                Số dự án
                                            </label>
                                            <FormInput
                                                name="totalProjects"
                                                id="totalProjects"
                                                type="text"
                                                placeholder="1"
                                                value={
                                                    utHomeData.otherValues
                                                        .totalProjects
                                                }
                                                onChange={e =>
                                                    onStoryTextChange(e)
                                                }
                                                required
                                                disabled={
                                                    !utHomeData.isShowMetrics
                                                }
                                            />
                                        </Col>
                                        <Col md="3" className="form-group">
                                            <label htmlFor="staffMembers">
                                                Số nhân viên
                                            </label>
                                            <FormInput
                                                name="staffMembers"
                                                id="staffMembers"
                                                type="text"
                                                placeholder="1"
                                                value={
                                                    utHomeData.otherValues
                                                        .staffMembers
                                                }
                                                onChange={e =>
                                                    onStoryTextChange(e)
                                                }
                                                required
                                                disabled={
                                                    !utHomeData.isShowMetrics
                                                }
                                            />
                                        </Col>
                                        <Col md="3" className="form-group">
                                            <label htmlFor="totalHours">
                                                Giờ làm việc
                                            </label>
                                            <FormInput
                                                name="totalHours"
                                                id="totalHours"
                                                type="text"
                                                placeholder="1"
                                                value={
                                                    utHomeData.otherValues
                                                        .totalHours
                                                }
                                                onChange={e =>
                                                    onStoryTextChange(e)
                                                }
                                                required
                                                disabled={
                                                    !utHomeData.isShowMetrics
                                                }
                                            />
                                        </Col>
                                        <Col md="3" className="form-group">
                                            <label htmlFor="countries">
                                                Quốc gia
                                            </label>
                                            <FormInput
                                                name="countries"
                                                id="countries"
                                                type="text"
                                                placeholder="1"
                                                value={
                                                    utHomeData.otherValues
                                                        .countries
                                                }
                                                onChange={e =>
                                                    onStoryTextChange(e)
                                                }
                                                required
                                                disabled={
                                                    !utHomeData.isShowMetrics
                                                }
                                            />
                                        </Col>
                                        <hr
                                            style={{
                                                width: '100%',
                                                height: '1px',
                                                backgroundColor: '#e1e5eb',
                                            }}
                                        />
                                        <Col md="12" className="form-group">
                                            <label htmlFor="criteria">
                                                "Chúng tôi là chuyên gia
                                                trong..." &nbsp;{' '}
                                                <strong>
                                                    Đường dẫn URL - Tiêu đề - Mô
                                                    tả (Bỏ trống Đường dẫn URL
                                                    để không hiển thị)
                                                </strong>
                                            </label>
                                            <Row id="criteria">
                                                <Col md="2" sm="6">
                                                    <FormInput
                                                        name="item1Link"
                                                        id="item1Link"
                                                        type="text"
                                                        placeholder="Đường dẫn URL"
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item1Link
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="4" sm="6">
                                                    <FormInput
                                                        name="item1Title"
                                                        id="item1Title"
                                                        type="text"
                                                        placeholder="Home Construction"
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item1Title
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="6" sm="12">
                                                    <FormInput
                                                        name="item1Description"
                                                        id="item1Description"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item1Description
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>

                                                <Col md="2" sm="6">
                                                    <FormInput
                                                        name="item2Link"
                                                        id="item2Link"
                                                        type="text"
                                                        placeholder="Đường dẫn URL"
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item2Link
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="4" sm="6">
                                                    <FormInput
                                                        name="item2Title"
                                                        id="item2Title"
                                                        type="text"
                                                        placeholder="Home Construction"
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item2Title
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="6" sm="12">
                                                    <FormInput
                                                        name="item2Description"
                                                        id="item2Description"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item2Description
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>

                                                <Col md="2" sm="6">
                                                    <FormInput
                                                        name="item3Link"
                                                        id="item3Link"
                                                        type="text"
                                                        placeholder="Đường dẫn URL"
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item3Link
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="4" sm="6">
                                                    <FormInput
                                                        name="item3Title"
                                                        id="item3Title"
                                                        type="text"
                                                        placeholder="Home Construction"
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item3Title
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="6" sm="12">
                                                    <FormInput
                                                        name="item3Description"
                                                        id="item3Description"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item3Description
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>

                                                <Col md="2" sm="6">
                                                    <FormInput
                                                        name="item4Link"
                                                        id="item4Link"
                                                        type="text"
                                                        placeholder="Đường dẫn URL"
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item4Link
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="4" sm="6">
                                                    <FormInput
                                                        name="item4Title"
                                                        id="item4Title"
                                                        type="text"
                                                        placeholder="Home Construction"
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item4Title
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="6" sm="12">
                                                    <FormInput
                                                        name="item4Description"
                                                        id="item4Description"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item4Description
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>

                                                <Col md="2" sm="6">
                                                    <FormInput
                                                        name="item5Link"
                                                        id="item5Link"
                                                        type="text"
                                                        placeholder="Đường dẫn URL"
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item5Link
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="4" sm="6">
                                                    <FormInput
                                                        name="item5Title"
                                                        id="item5Title"
                                                        type="text"
                                                        placeholder="Home Construction"
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item5Title
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="6" sm="12">
                                                    <FormInput
                                                        name="item5Description"
                                                        id="item5Description"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item5Description
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>

                                                <Col md="2" sm="6">
                                                    <FormInput
                                                        name="item6Link"
                                                        id="item6Link"
                                                        type="text"
                                                        placeholder="Đường dẫn URL"
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item6Link
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="4" sm="6">
                                                    <FormInput
                                                        name="item6Title"
                                                        id="item6Title"
                                                        type="text"
                                                        placeholder="Home Construction"
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item6Title
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                                <Col md="6" sm="12">
                                                    <FormInput
                                                        name="item6Description"
                                                        id="item6Description"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData
                                                                .otherValues
                                                                .item6Description
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </Collapse>
            </ListGroup>
        </Card>
    );
};
