
export const FETCH_UT_PROJECTS = 'FETCH_UT_PROJECTS';

export const utProjectsReducer = (state, {type, payload}) => {
    switch(type) {
        case FETCH_UT_PROJECTS: {
            return [...payload];
        }
        default: return state;
    }
}
