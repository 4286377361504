/*
 * Created by @tranphuquy19 on 05/12/2020
 * @author: tranphuquy19@gmail.com
 */
import React, {useState, useContext} from 'react';
import {
    Button, Col,
    Form,
    FormGroup, FormInput,
    InputGroup,
} from "shards-react";
import {storeUser} from "../shared/auth";
import Client from '../shared/client';
import {AuthContext} from "../store/contexts/AuthContext";
import {LOGON} from "../store/reducers/AuthReducer";

function Login() {
    const [, dispatchAuth] = useContext(AuthContext);
    const [loginInfo, setLoginInfo] = useState({
        email: '',
        password: '',
    });

    const onChange = (e) => {
        const {name, value} = e.target;
        setLoginInfo({
            ...loginInfo,
            [`${name}`]: value
        })
    }

    const onSubmit = () => {
        Client('user/logon', 'POST', loginInfo).then(({data}) => {
            storeUser(data);
            dispatchAuth({
                type: LOGON,
                payload: data
            })
        }).catch(() => alert("error"))
    }

    return (
        <Col sm="12" md="6" style={{height: '500px', position: 'relative'}}>
            <div style={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                backgroundColor: '#fff',
                padding: '3rem',
                borderRadius: '10px',
                width: '30rem'
            }}>
                <h3 className="text-muted d-block mb-2"
                    style={{textAlign: 'center'}}>Login</h3>
                <Form>
                    <FormGroup>
                        <InputGroup className="mb-3">
                            <FormInput placeholder="example@rizo-light.com"
                                       name="email" value={loginInfo.email}
                                       type="email" onChange={onChange}/>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <FormInput
                            type="password"
                            placeholder="Password"
                            value={loginInfo.password}
                            name="password"
                            onChange={onChange}
                        />
                    </FormGroup>
                    <Button onClick={onSubmit}>Login</Button>
                </Form>
            </div>
        </Col>
    );
}

export default Login;
