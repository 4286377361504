import React, { useState } from "react";
import { Button, CardImg, FormInput, FormTextarea } from "shards-react";
import CustomFileUpload from "../components/components-overview/CustomFileUpload";

const RowTableContact = ({ contact, index, state, update, deleteRow }) => {
  const [onStateRow, setOnStateRow] = useState(state);
  const [contactData, setContactData] = useState(contact);

  const onEdit = () => {
    setOnStateRow(!onStateRow);
  }
  const onCancel = () => {
    setOnStateRow(!onStateRow);
  }

  const onDelete = () => {
    deleteRow(index);
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setContactData({ ...contactData, [name]: value })
  }

  const onSave = () => {
    update(index, contactData);
    setOnStateRow(!onStateRow);
  }

  const onRender = () => {
    if (onStateRow) {
      return (
        <tr style={{overflow: 'scroll',}}>
          <td>{index + 1}</td>
          <td>{contact.name}</td>
          <td><p style={{margin: "1.1px", whiteSpace: "pre-line"}}>{contact.detail}</p></td>
          <td><CardImg src={contact.image} style={{ maxWidth: "150px", maxHeight: "100px" }} /></td>
          <td>{contact.phoneNumber}</td>
          <td>{contact.email}</td>
          <td>{contact.location}</td>
          <td>
            <Button size="sm" className="m-1" onClick={() => { onEdit() }}>
              <i className="material-icons">border_color</i>
            </Button>
            <Button onClick={() => onDelete()} pill theme="danger" size="sm" className="m-1">
              <i className="material-icons">remove</i>
            </Button>
          </td>
        </tr>
      )
    }
    return (
      <tr>
        <td ><p className="mt-3">{index + 1}</p></td>
        <td><FormInput size="sm" className="mt-2"
          name="name" value={contactData.name}
          placeholder="Location Name"
          onChange={(e) => onChange(e)}
        /></td>
        <td><FormTextarea size="sm" className="mt-2"
          name="detail" value={contactData.detail}
          placeholder="Location Detail"
          onChange={(e) => onChange(e)} /></td>
        <td><CustomFileUpload
          size="sm"
          link={contactData.image}
          onFileChange={(fileUrl) => {
            onChange({
              target: {
                name: 'image',
                value: fileUrl
              }
            })
          }} /></td>
        <td><FormInput size="sm" className="mt-2"
          name="phoneNumber" value={contactData.phoneNumber}
          placeholder="Phone Number"
          onChange={(e) => onChange(e)} /></td>
        <td><FormInput size="sm" className="mt-2"
          name="email" value={contactData.email}
          placeholder="Email"
          onChange={(e) => onChange(e)} /></td>
        <td><FormInput size="sm" className="mt-2"
          name="location" value={contactData.location}
          placeholder="Location"
          onChange={(e) => onChange(e)} /></td>
        <td>
          <Button pill squared theme="success" size="sm" onClick={() => onSave()}>
            <i className="material-icons">done</i>
          </Button>
          <Button pill outline theme="danger" size="sm" onClick={() => { onCancel() }}><i className="material-icons">clear</i></Button>
        </td>
      </tr>
    )
  }
  return (
    <>
      {onRender()}
    </>
  )
}

export default RowTableContact;