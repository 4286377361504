export const utProductsState = [
    // {
    //     id: '',
    //     title: '',
    //     description: '',
    //     content: '',
    //     status: 0,
    //     picture_link: '',
    //     creator: '',
    //     createdAt: new Date(),
    //     updatedAt: new Date(),
    // },
];
