/*
 * Created by @tranphuquy19 on 05/12/2020
 * @author: tranphuquy19@gmail.com
 */

import React, {useContext} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormInput,
    ListGroupItem, Row
} from "shards-react";
import CustomFileUpload from "../components-overview/CustomFileUpload";
import {HomeContext} from "../../store/contexts/HomeContext";
import {
    EDIT_ITEM_IMAGE_AT_INDEX, MOVE_TO_TOP_SLIDER_IMAGE, REMOVE_SLIDER_IMAGE,
} from "../../store/reducers/HomeReducer";

function EditSliderImagesHomePage() {
    const [homeData, dispatchHomeData] = useContext(HomeContext);
    const {sliderImages} = homeData;

    const onChange = (e, index) => {
        const {value, name} = e.target;
        dispatchHomeData({
            type: EDIT_ITEM_IMAGE_AT_INDEX,
            payload: {
                index,
                key: name,
                value: value
            },
        })
    }

    return (
        <Card small className="h-100">
            <CardHeader className="border-bottom">
                <Row style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 25px',
                    alignItems: 'center'
                }}>
                    <h5 className="m-0">Slider Images</h5>
                    <Button theme="success" onClick={() => {
                        dispatchHomeData({
                            type: 'ADD_SLIDER_IMAGE'
                        })
                    }}>Add new item</Button>
                </Row>
            </CardHeader>
            <CardBody className="pt-0">
                <ListGroupItem className="px-1" style={{
                    maxHeight: '400px',
                    minHeight: '200',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    paddingTop: 0
                }}>

                    {sliderImages.map((item, index) => {
                        return <div key={index}>
                            <Card small className="mb-1">
                                <CardHeader className="border-bottom">
                                    <h6 className="m-0">Image {index + 1}</h6>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    <FormInput placeholder="Head 1"
                                               className="mb-2 mt-1"
                                               name="head1" value={item.head1}
                                               onChange={(e) => {
                                                   onChange(e, index)
                                               }}
                                    />
                                    <FormInput placeholder="Head 2" name="head2"
                                               value={item.head2}
                                               onChange={(e) => {
                                                   onChange(e, index)
                                               }}
                                    />
                                    <CustomFileUpload {...item}
                                                      onFileChange={(fileUrl) => {
                                                          onChange({
                                                              target: {
                                                                  name: 'link',
                                                                  value: fileUrl
                                                              }
                                                          }, index)
                                                      }}/>
                                    <Button className="mr-1" theme="danger"
                                            onClick={() => {
                                                if (window.confirm('Are you sure you want to delete this. Nothing changes until you hit Save changes button')) {
                                                    dispatchHomeData({
                                                        type: REMOVE_SLIDER_IMAGE,
                                                        payload: {index}
                                                    })
                                                }
                                            }}>Remove</Button>
                                    <Button onClick={() =>
                                        dispatchHomeData({
                                            type: MOVE_TO_TOP_SLIDER_IMAGE,
                                            payload: {index}
                                        })}>Move to Top</Button>
                                </CardBody>
                            </Card>
                        </div>
                    })}
                </ListGroupItem>
            </CardBody>
        </Card>
    );
}

export default EditSliderImagesHomePage;
