/*
 * Created by @tranphuquy19 on 15/01/2024
 * @author: tranphuquy19@gmail.com
 */
import User from "./User";

const UtServiceState = {
    id: '',
    title: '',
    image: {
        fileUrl: '',
        originalName: ''
    },
    content: '',
    startDate: new Date(),
    utBusinessTypeId: '',
    creator: User,
}

export default UtServiceState;
