import React, {useState, useContext} from 'react';
import {Button, Card, CardBody, CardHeader, Tooltip} from "shards-react";
import SolutionContext from "../../store/contexts/SolutionsContext";
import {FETCH_SOLUTIONS} from "../../store/reducers/SolutionsReducer";
import Client from '../../shared/client';
import {AuthContext} from "../../store/contexts/AuthContext";
import config from "../../shared/config";
import moment from "moment";
import {store} from "react-notifications-component";

export const SolutionTable = ({history, ...e}) => {
    const [solutionData, dispatchSolutionData] = useContext(SolutionContext);
    const [user,] = useContext(AuthContext);
    const [tooltipState, setTooltipState] = useState(false);
    const [itemHovered, setItemHovered] = useState('');

    const stripHtml = (html) => {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    const onRefresh = () => {
        Client('solutions', 'GET').then(({data}) => {
            dispatchSolutionData({
                type: FETCH_SOLUTIONS,
                payload: [...data]
            })
            store.addNotification({
                title: "Refreshing solutions successful",
                message: `The solutions table was updated`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            });
        }).catch(e => {
            store.addNotification({
                title: "Error!",
                message: `Failed to refresh solutions: ${e}.`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        })
    }

    const onEdit = (id) => {
        history.push(`/edit-solution?id=${id}`)
    }

    const renderEditBtn = ({id, creator: {id: creatorId}}) => {
        if (user.roles.includes('ADMIN') || (user.roles.includes('MANAGER') && (user.id === creatorId))) {
            return (
                <Button
                    onClick={() => {
                        onEdit(id)
                    }}>Edit</Button>
            )
        }
        return (
            <Button
                disabled={true}
                onClick={() => {
                    onEdit(id)
                }}>Edit</Button>
        )
    }

    const viewSolution = (model) => {
        const win = window.open(`${config.API_URL}/solution?id=${encodeURIComponent(model)}`, '_blank');
        win.focus();
    }

    const getTableBody = () => {
        return solutionData.map((p, i) => {
            return (
                <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{p.title.slice(0, 15)}</td>
                    <td>{stripHtml(p.content).slice(0, 50) + '...'}</td>
                    <td>{p.creator.name}</td>
                    <td>{p.startDate}</td>
                    <td id={`tt-${p.id}`}
                        onMouseEnter={() => setItemHovered(p.id)}
                        onMouseLeave={() => setItemHovered('')}
                    >{moment(p.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td>
                        {renderEditBtn(p)}

                        <Button theme={'success'}
                                className="ml-2"
                                onClick={() => {
                                    viewSolution(p.id)
                                }}>View</Button>
                    </td>
                    <Tooltip target={`#tt-${p.id}`}
                             open={itemHovered === p.id && tooltipState}
                             toggle={() => setTooltipState(!tooltipState)}
                    >
                        <b>Created
                            at: {moment(p.createdAt).format('YYYY-MM-DD HH:mm:ss')}</b>
                    </Tooltip>
                </tr>
            )
        })
    }

    return (
        <Card small className="mb-4">
            <CardHeader className="border-bottom" style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <h6 className="m-0">Solutions</h6>
                <Button theme={'warning'}
                        onClick={() => onRefresh()}>Refresh</Button>
            </CardHeader>
            <CardBody className="p-0 pb-3">
                <table className="table mb-0"
                       style={{maxHeight: '500px', overflowY: 'scroll'}}>
                    <thead className="bg-light">
                    <tr>
                        <th scope="col" className="border-0">
                            #
                        </th>
                        <th scope="col" className="border-0">
                            Title
                        </th>
                        <th scope="col" className="border-0">
                            Content
                        </th>
                        <th scope="col" className="border-0">
                            Creator
                        </th>
                        <th scope="col" className="border-0">
                            Start Date
                        </th>
                        <th scope="col" className="border-0">
                            Updated At
                        </th>
                        <th scope="col" className="border-0">
                            &nbsp;
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {getTableBody()}
                    </tbody>
                </table>
            </CardBody>
        </Card>
    )
}
