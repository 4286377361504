import SolutionState from './SolutionState';
import User from './User';

export const UtProjectState = {
    title: '',
    image: {
        fileUrl: '',
        originalName: '',
    },
    content: '',
    creator: User,
    businessType: SolutionState,
    id: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    businessTypeId: '',
};
