/*
 * Created by @tranphuquy19 on 24/12/2020
 * @author: tranphuquy19@gmail.com
 */

export const ProductsState = {
  productCategories: [],
  productKinds: [],
  products: [],
}
