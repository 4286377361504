/*
 * Created by @tranphuquy19 on 30/12/2020
 * @author: tranphuquy19@gmail.com
 */
import React, {useEffect, useState, useContext} from 'react';
import Select from 'react-select';
import {HomeContext} from "../../store/contexts/HomeContext";
import Client from '../../shared/client';
import _ from 'lodash';
import {EDIT_TOP_PRODUCTS} from "../../store/reducers/HomeReducer";

export const EditTopProduct = () => {
    const [homeData, dispatchHomeData] = useContext(HomeContext);
    const [optionSelected, setOptionSelected] = useState([]);
    const [options, setOptions] = useState([]);

    const fetchProducts = async () => {
        const {data} = await Client('p', 'GET');
        return data;
    }

    useEffect(() => {
        const _fetchProducts = async () => {
            const _products = await fetchProducts();
            const _options = _products.map(({model, name}) => {
                return {value: model, label: `${model} - ${name}`}
            });
            setOptions(_options);
        }
        _fetchProducts();
    }, []);

    useEffect(() => {
        const _optionsSelected = homeData.topProducts.map(({value, label}) => {
            return {value, label};
        });
        setOptionSelected(_optionsSelected);
    }, [options, homeData])

    const onOptionsChange = (selectedOptions) => {
        selectedOptions = selectedOptions || []; // selectedOptions === null nếu ko có phần tử được selected
        const results = selectedOptions.map(({value}) => {
            return _.find(options, {value});
        })
        dispatchHomeData({
            type: EDIT_TOP_PRODUCTS,
            payload: results
        })
    }

    return (
        <>
            <h5>Select products to show on the Home page!</h5>
            <Select options={options} isMulti={true} onChange={onOptionsChange}
                    value={optionSelected}/>
        </>
    )
}
