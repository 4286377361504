/*
 * Created by @tranphuquy19 on 20/02/2021
 * @author: tranphuquy19@gmail.com
 */
import React, { useContext, useState } from 'react';

import {
    Card,
    CardHeader,
    Col,
    Form,
    FormCheckbox,
    FormInput,
    ListGroup,
    ListGroupItem,
    Row,
    Collapse,
} from 'shards-react';
import { UtHomeContext } from '../../store/contexts/UtHomeContext';
import {
    UT_OUR_VALUES,
    UT_TOGGLE_OUR_VALUES,
} from '../../store/reducers/UtHomeReducer';

export const UtOurValues = () => {
    const [utHomeData, dispatchUtHomeData] = useContext(UtHomeContext);
    const [open, setOpen] = useState(false);

    const onStoryTextChange = e => {
        const {
            target: { name, value },
        } = e;
        dispatchUtHomeData({
            type: UT_OUR_VALUES,
            payload: {
                ...utHomeData.ourValues,
                [name]: value,
            },
        });
    };

    return (
        <Card className="mb-4">
            <CardHeader className="border-bottom d-flex flex-row">
                <FormCheckbox
                    toggle
                    checked={utHomeData.isShowOurValues}
                    onChange={() =>
                        dispatchUtHomeData({
                            type: UT_TOGGLE_OUR_VALUES,
                        })
                    }
                ></FormCheckbox>
                <h6
                    className="m-0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setOpen(!open)}
                >
                    Our values
                    <i className="material-icons">
                        {open ? 'expand_less' : 'expand_more'}
                    </i>
                </h6>
            </CardHeader>
            <ListGroup flush>
                <Collapse open={open}>
                    <ListGroupItem className="p-3">
                        <Row>
                            <Col>
                                <Form>
                                    <Row form>
                                        <Col md="12" className="form-group">
                                            <label htmlFor="ourValuesTitle">
                                                Tiêu đề text
                                            </label>
                                            <FormInput
                                                name="title"
                                                id="ourValuesTitle"
                                                type="text"
                                                placeholder="WE DELIVER LANDMARK PROJECTS"
                                                value={
                                                    utHomeData.ourValues.title
                                                }
                                                onChange={e =>
                                                    onStoryTextChange(e)
                                                }
                                                required
                                                disabled={
                                                    !utHomeData.isShowOurValues
                                                }
                                            />
                                        </Col>
                                        <Col md="12" className="form-group">
                                            <label htmlFor="ourValuesDescription">
                                                Mô tả
                                            </label>
                                            <FormInput
                                                name="description"
                                                id="ourValuesDescription"
                                                type="text"
                                                placeholder="Type your description"
                                                value={
                                                    utHomeData.ourValues
                                                        .description
                                                }
                                                onChange={e =>
                                                    onStoryTextChange(e)
                                                }
                                                required
                                                disabled={
                                                    !utHomeData.isShowOurValues
                                                }
                                            />
                                        </Col>
                                        <Col md="12" className="form-group">
                                            <label htmlFor="criteria">
                                                3 Giá trị
                                            </label>
                                            <Row id="criteria">
                                                <Col md="2" sm="12">
                                                    <FormInput
                                                        name="value1Title"
                                                        id="value1Title"
                                                        type="text"
                                                        placeholder="safety"
                                                        value={
                                                            utHomeData.ourValues
                                                                .value1Title
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowOurValues
                                                        }
                                                    />
                                                </Col>
                                                <Col md="10" sm="12">
                                                    <FormInput
                                                        name="value1"
                                                        id="value1"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData.ourValues
                                                                .value1
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowOurValues
                                                        }
                                                    />
                                                </Col>
                                                <Col md="2" sm="12">
                                                    <FormInput
                                                        name="value2Title"
                                                        id="value2Title"
                                                        type="text"
                                                        placeholder="customer service"
                                                        value={
                                                            utHomeData.ourValues
                                                                .value2Title
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowOurValues
                                                        }
                                                    />
                                                </Col>
                                                <Col md="10" sm="12">
                                                    <FormInput
                                                        name="value2"
                                                        id="value2"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData.ourValues
                                                                .value2
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowOurValues
                                                        }
                                                    />
                                                </Col>
                                                <Col md="2" sm="12">
                                                    <FormInput
                                                        name="value3Title"
                                                        id="value3Title"
                                                        type="text"
                                                        placeholder="integrity"
                                                        value={
                                                            utHomeData.ourValues
                                                                .value3Title
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowOurValues
                                                        }
                                                    />
                                                </Col>
                                                <Col md="10" sm="12">
                                                    <FormInput
                                                        name="value3"
                                                        id="value3"
                                                        type="text"
                                                        placeholder=""
                                                        value={
                                                            utHomeData.ourValues
                                                                .value3
                                                        }
                                                        onChange={e =>
                                                            onStoryTextChange(e)
                                                        }
                                                        required
                                                        disabled={
                                                            !utHomeData.isShowOurValues
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </Collapse>
            </ListGroup>
        </Card>
    );
};
