import React from 'react';
import FileUploader from '../../shared/file-uploader';

const CustomFileUpload = ({
    link,
    fileName,
    onFileChange,
    marginText,
    title,
    disabled = false,
    endpoint = 'uploader',
}) => {
    const onChange = e => {
        const formData = new FormData();
        const file = e.target.files[0];
        formData.append('file', file);
        FileUploader(endpoint, formData)
            .then(({ data: { fileUrl, originalName } }) => {
                onFileChange(fileUrl, originalName);
            })
            .catch(() => alert('Upload error'));
    };

    return (
        <div
            className={`custom-file ${marginText || 'mt-2 mb-3'}`}
            style={{ zIndex: 0 }}
            title={title}
        >
            <input
                type="file"
                className="custom-file-input"
                id="customFile2"
                onChange={onChange}
                disabled={disabled}
            />
            <label
                className="custom-file-label"
                htmlFor="customFile2"
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {fileName || link || 'Choose file...'}
            </label>
        </div>
    );
};

export default CustomFileUpload;
