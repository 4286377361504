
export const UPDATE_SIDEBAR_TAB_INDEX = "UPDATE_SIDEBAR_TAB_INDEX";

export const metaDataReducer = (state, { type, payload }) => {
    switch (type) {
        case UPDATE_SIDEBAR_TAB_INDEX:
            return {
                ...state,
                sidebarTabIndex: payload
            };
        default:
            return state;
    }
}
