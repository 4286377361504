/*
 * Created by @tranphuquy19 on 08/12/2020
 * @author: tranphuquy19@gmail.com
 */

export const homeState = {
    sliderImages: [],
    sliderProducts: [],
    homeTitle: "",
    homeDescription: "",
    offices: [
        {
            organizationName: "",
            email: "",
            phoneNumber: "",
            address: "",
            fax: "",
            openTime: ""
        }
    ],
    tags: [],
    topProducts: [],
    topProjects: [],
    topSolutions: [],
    topNews: [],
    productsNavbar: {
        col1: {
            categories: [],
            kinds: []
        },
        col2: {
            categories: [],
            kinds: []
        },
        col3: {
            categories: [],
            kinds: []
        }
    },
    ggAddressLink: '',
    fbLink: '',
    ytLink: '',
    twitterLink: '',
    vietnameseTags: '',
    ourStory: {
        storyText: '',
        storyImage: { fileUrl: '', originalName: '' },
        storyLink: ''
    },
    headerImages: {
        productPage: { fileUrl: '', originalName: '' },
        projectPage: { fileUrl: '', originalName: '' },
        downloadPage: { fileUrl: '', originalName: '' },
        newsPage: { fileUrl: '', originalName: '' },
        contactPage: { fileUrl: '', originalName: '' },
        solutionPage: { fileUrl: '', originalName: '' }
    }
}
