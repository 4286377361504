/*
 * Created by @tranphuquy19 at 08/03/2021
 * @author: tranphuquy19@gmail.com
 */

import React from 'react';
import {Container, Row} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {InternalProjectsTable} from "../components/@internal-projects/@internal-project-table";

export const RegisterProjectPage = ({location}) => {

    return (
        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center'
            }}>
                <PageTitle title="Out project"
                           subtitle="Project Manager"
                           className="text-sm-left mb-3"/>
            </Row>
            <Tabs>
                <TabList>
                    <Tab>Your Projects</Tab>
                </TabList>
                <TabPanel>
                    <InternalProjectsTable/>
                </TabPanel>
            </Tabs>
        </Container>
    )
}
