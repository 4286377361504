import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'shards-react';

import SidebarMainNavbar from './SidebarMainNavbar';
import SidebarNavItems from './SidebarNavItems';
import UtSidebarNavItems from './UtSidebarNavItems';

import { Store } from '../../../flux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { UPDATE_SIDEBAR_TAB_INDEX } from '../../../store/reducers/MetaDataReducer';

class MainSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuVisible: false,
            sidebarNavItems: Store.getSidebarItems(),
        };

        this.onChange = this.onChange.bind(this);
    }

    componentWillMount() {
        Store.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        Store.removeChangeListener(this.onChange);
    }

    onChange() {
        console.log('MainSidebar onChange', this.props);
        this.setState({
            ...this.state,
            menuVisible: Store.getMenuState(),
            sidebarNavItems: Store.getSidebarItems(),
        });
    }

    render() {
        const classes = classNames(
            'main-sidebar',
            'px-0',
            'col-12',
            this.state.menuVisible && 'open',
        );

        return (
            <Col
                tag="aside"
                className={classes}
                lg={{ size: 2 }}
                md={{ size: 3 }}
            >
                <SidebarMainNavbar hideLogoText={this.props.hideLogoText} />
                {/* <SidebarSearch /> */}
                <Tabs
                    selectedIndex={this.props.metaProps[0].sidebarTabIndex}
                    onSelect={(index, lastIndex, event) => {
                        this.props.metaProps[1]({
                            type: UPDATE_SIDEBAR_TAB_INDEX,
                            payload: index,
                        });
                    }}
                >
                    <TabList>
                        <Tab>RiZO-Light</Tab>
                        <Tab>UTEC</Tab>
                    </TabList>
                    <TabPanel>
                        <SidebarNavItems />
                    </TabPanel>
                    <TabPanel>
                        <UtSidebarNavItems />
                    </TabPanel>
                </Tabs>
            </Col>
        );
    }
}

MainSidebar.propTypes = {
    /**
     * Whether to hide the logo text, or not.
     */
    hideLogoText: PropTypes.bool,
    metaProps: PropTypes.array,
};

MainSidebar.defaultProps = {
    hideLogoText: false,
    metaProps: [{ sidebarTabIndex: 1 }, null],
};

export default MainSidebar;
