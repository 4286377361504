import React, { useEffect, useReducer } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container, Row } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import 'react-tabs/style/react-tabs.css';
import ProjectComponent from '../components/@project/@project-component';
import { ProjectTable } from '../components/@project/@project-table';
import Client from '../shared/client';
import ProjectState from '../store/states/ProjectState';
import {
    utProjectsReducer,
    FETCH_UT_PROJECTS,
} from '../store/reducers/UtProjectsReducer';
import {UtProjectsContext} from '../store/contexts/UtProjectsContext';
import { UtProjectsTable } from '../components/@ut-projects/@ut-projects-table';
import UtProjectComponent from '../components/@ut-projects/@ut-project-component';
import { UtProjectState } from '../store/states/UtProjectState';

export const UtProjectsPage = ({ history }) => {
    const [utProjectsData, dispatchUtProjectsData] = useReducer(
        utProjectsReducer,
        [],
    );

    useEffect(() => {
        const _fetchData = async () => {
            const { data } = await Client('business', 'GET', undefined, true);
            dispatchUtProjectsData({
                type: FETCH_UT_PROJECTS,
                payload: [...data],
            });
        };
        _fetchData();
    }, []);

    return (
        <UtProjectsContext.Provider
            value={[utProjectsData, dispatchUtProjectsData]}
        >
            <Container fluid className="main-content-container px-4">
                <Row
                    noGutters
                    className="page-header py-4"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                    }}
                >
                    <PageTitle
                        title="Dự án"
                        subtitle="Quản lí"
                        className="text-sm-left mb-3"
                    />
                    {/* <Button theme="primary" pill className="mr-3"
                    disabled={isDisableSave}
                    onClick={onSave}>Save
                        changes</Button> */}
                </Row>
                <Tabs>
                    <TabList>
                        <Tab>Projects</Tab>
                        <Tab>Create new project</Tab>
                    </TabList>
                    <TabPanel>
                        <UtProjectsTable history={history} />
                    </TabPanel>
                    <TabPanel>
                        {/* <ProjectComponent project={ProjectState} /> */}
                        <UtProjectComponent project={UtProjectState} />
                    </TabPanel>
                </Tabs>
            </Container>
        </UtProjectsContext.Provider>
    );
};
