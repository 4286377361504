import {Col, Container, Row} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import React, {useReducer, useEffect} from "react";
import {DownloadsComponent} from "../components/@downloads/@download-component";
import {
    downloadsReducer,
    FETCH_DOWNLOADS
} from "../store/reducers/DownloadsReducer";
import {DownloadsContext} from "../store/contexts/DowloadsContext";
import Client from "../shared/client";
import DownloadState from "../store/states/DownloadState";

export const DownloadsPage = ({location}) => {
    const [downloads, dispatchDownloads] = useReducer(downloadsReducer, [DownloadState]);

    useEffect(() => {
        const _fetchData = async () => {
            const {data} = await Client('downloads', 'GET');
            dispatchDownloads({
                type: FETCH_DOWNLOADS,
                payload: data
            })
        }
        _fetchData();
    }, [])

    return (
        <DownloadsContext.Provider value={[downloads, dispatchDownloads]}>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <PageTitle title="Downloads page"
                               subtitle="Page Manager"
                               className="text-sm-left mb-3"/>
                </Row>
                <Tabs>
                    <TabList>
                        <Tab>Catalogs</Tab>
                    </TabList>
                    <TabPanel>
                        <Row>
                            <Col lg="8" md="12" sm="12" className="mb-4">
                                <DownloadsComponent/>
                            </Col>
                            <Col lg="4" md="12" sm="12" className="mb-4">
                                <span> Configs</span>
                            </Col>
                        </Row>
                    </TabPanel>
                </Tabs>
            </Container>
        </DownloadsContext.Provider>
    )
}
