/*
 * Created by @tranphuquy19 on 05/12/2020
 * @author: tranphuquy19@gmail.com
 */
import axios from 'axios';
import configs from "./config";
import headerHelper from "./header-helper";

let client = (endpoint, method = 'GET', body, isUt = false) => {
    return axios({
        method: method,
        url: `${configs.API_URL}${isUt ? '/ut' : ''}/api/${endpoint}`,
        data: body,
        params: method === 'GET' ? body : undefined,
        headers: headerHelper()
    });
};


export default client;
