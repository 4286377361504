import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Editor as ClassicEditor } from 'ckeditor5-custom-build/build/ckeditor';
import config from '../../shared/config';
import './reset.css';

const UtTextEditor = ({ value, onChange }) => {
    const custom_config = {
        extraPlugins: [MyCustomUploadAdapterPlugin],
        allowedContent: 'p b i; a[!href] table',
        // image: {
        //     toolbar: [
        //         'imageStyle:alignLeft',
        //         'imageStyle:alignCenter',
        //         'imageStyle:alignRight',
        //         'imageStyle:inline',
        //         '|',
        //         'imageResize',
        //         '|',
        //         'imageTextAlternative',
        //     ],
        //     styles: ['alignLeft', 'alignCenter', 'alignRight'],
        //     resizeOptions: [
        //         {
        //             name: 'imageResize:original',
        //             label: 'Original',
        //             value: null,
        //         },
        //         {
        //             name: 'imageResize:50',
        //             label: '50%',
        //             value: '50',
        //         },
        //         {
        //             name: 'imageResize:75',
        //             label: '75%',
        //             value: '75',
        //         },
        //     ],
        //     balloonToolbar: [
        //         'imageStyle:full',
        //         'imageStyle:side',
        //         '|',
        //         'imageTextAlternative',
        //         'imageAlignLeft',
        //         'imageAlignCenter',
        //         'imageAlignRight',
        //     ],
        // },
        // toolbar: {
        //     items: [
        //         'heading',
        //         'fontFamily',
        //         'fontSize',
        //         'alignment',
        //         'bold',
        //         'italic',
        //         'underline',
        //         'highlight',
        //         'fontColor',
        //         'fontBackgroundColor',
        //         '|',
        //         'link',
        //         'bulletedList',
        //         'numberedList',
        //         'subscript',
        //         'superscript',
        //         '|',
        //         'indent',
        //         'outdent',
        //         'specialCharacters',
        //         'removeFormat',
        //         'strikethrough',
        //         'horizontalLine',
        //         '|',
        //         'imageUpload',
        //         'blockQuote',
        //         'insertTable',
        //         'mediaEmbed',
        //         'undo',
        //         'redo',
        //         'exportPdf',
        //         'MathType',
        //         'ChemType',
        //     ],
        // },
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        language: 'en',
    };

    return (
        <div className="yui3-cssreset">
            <CKEditor
                required
                editor={ClassicEditor}
                config={custom_config}
                data={value}
                onChange={(event, editor) => {
                    onChange(editor);
                }}
            />
        </div>
    );
};

function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        return new MyUploadAdapter(loader);
    };
}

class MyUploadAdapter {
    constructor(props) {
        this.loader = props;
        this.url = config.API_URL + '/ut-uploader';
    }

    upload() {
        return new Promise((resolve, reject) => {
            this._initRequest();
            this._initListeners(resolve, reject);
            this._sendRequest();
        });
    }

    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());

        xhr.open('POST', this.url, true);
        xhr.responseType = 'json';
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        // xhr.setRequestHeader('Authorization', getToken())
    }

    _initListeners(resolve, reject) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${loader.file.name}.`;

        xhr.addEventListener('error', () => reject(genericErrorText));
        xhr.addEventListener('abort', () => reject());
        xhr.addEventListener('load', () => {
            const response = xhr.response;
            if (!response || response.error) {
                return reject(
                    response && response.error
                        ? response.error.message
                        : genericErrorText,
                );
            }

            // If the upload is successful, resolve the upload promise with an object containing
            // at least the "default" URL, pointing to the image on the server.
            resolve({
                default: response.fileUrl,
            });
        });

        if (xhr.upload) {
            xhr.upload.addEventListener('progress', evt => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    _sendRequest() {
        const data = new FormData();

        this.loader.file.then(result => {
            data.append('file', result);
            this.xhr.send(data);
        });
    }
}

export default UtTextEditor;
