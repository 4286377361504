import React, { useState, useEffect, useContext } from 'react';
import { store } from 'react-notifications-component';
import { Button, Card, CardBody, CardHeader } from 'shards-react';
import Client from '../../shared/client';
import { AuthContext } from '../../store/contexts/AuthContext';
import { UserContext } from '../../store/contexts/UsersContext';
import { FETCH_USERS } from '../../store/reducers/UsersReducer';
import AccountState from '../../store/states/AccountState';
import UserEditModal from './@user-edit-modal';

const UsersTable = () => {
  const [usersData, dispatchUsersData] = useContext(UserContext);
  const [user,] = useContext(AuthContext);
  const [modalState, setModalState] = useState(false);
  const [modalUser, setModalUser] = useState(AccountState);

  const toggle = () => {
    setModalState(!modalState);
  }

  const onRefresh = () => {
    Client('users', 'GET').then(({ data }) => {
      dispatchUsersData({
        type: FETCH_USERS,
        payload: [...data]
      })
      store.addNotification({
        title: "Refreshing accounts successful",
        message: `The accounts table was updated`,
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      });
    }).catch(e => {
      store.addNotification({
        title: "Error!",
        message: `Failed to refresh account: ${e}.`,
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          // onScreen: true
        }
      });
    })
  }

  const onEdit = (_id) => {
    setModalState(true);
    let u = usersData.find(u => u.id == _id);
    setModalUser(u);
  }

  const onDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this User!")) {
      Client("user/" + id, 'DELETE').then(() => {
        store.addNotification({
          title: `Delete accounts ${id} successful`,
          message: `The accounts table was updated`,
          type: "warning",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            // onScreen: true
          }
        });
        onRefresh();
      }).catch(e => {
        store.addNotification({
          title: "Error!",
          message: `Failed to delete account: ${e}.`,
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            // onScreen: true
          }
        });
      })
    }

  }

  const renderEditBtn = ({ id }) => {
    if (user.roles.includes('ADMIN')) {
      return (
        <>
          <Button
            pill
            onClick={() => {
              onEdit(id)
            }}>Edit</Button>
        </>

      )
    }
    return (
      <>
        <Button
          disabled={true}
          pill>Edit</Button>
      </>
    )

  }

  const renderDeleteBtn = ({ id }) => {
    if (user.roles.includes('ADMIN') && user.id != id) {
      return (
        <Button
          theme="danger"
          pill
          onClick={() => {
            onDelete(id)
          }}>Delete</Button>
      )
    } return (
      <Button
        disabled={true}
        theme="danger"
        pill>Delete</Button>
    )
  }
  const getTableBody = () => {
    return usersData.map((u, i) => {
      return (
        <tr key={i}>
          <td>{i + 1}</td>
          <td>{u.name}</td>
          <td>{u.email}</td>
          <td>{u.roles.join(",\t")}</td>
          <td>{u.phoneNumber}</td>
          <td>{u.address}</td>
          <td>
            {renderEditBtn(u)}
            {renderDeleteBtn(u)}
          </td>
        </tr>
      )
    })
  }
  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom" style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <h6 className="m-0">User Manager</h6>
        <Button theme={'warning'}
          onClick={() => onRefresh()}>Refresh</Button>
      </CardHeader>
      <CardBody className="p-0 pb-3">
        <table className="table mb-0"
          style={{ maxHeight: '500px', overflowY: 'scroll' }}>
          <thead className="bg-light">
            <tr>
              <th scope="col" className="border-0">
                #
                        </th>
              <th scope="col" className="border-0">
                Name
                        </th>
              <th scope="col" className="border-0">
                Email
                        </th>
              <th scope="col" className="border-0">
                Role
                        </th>
              <th scope="col" className="border-0">
                Phone Number
                        </th>
              <th scope="col" className="border-0">
                Address
                        </th>
              <th scope="col" className="border-0">
                &nbsp;
                        </th>
            </tr>
          </thead>
          <tbody>
            {getTableBody()}
          </tbody>
        </table>
      </CardBody>
      <UserEditModal open={modalState} user={modalUser} toggle={toggle} />
    </Card>
  )
}

export default UsersTable;