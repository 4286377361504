import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Row,
} from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import Client from '../shared/client';
import { UtNewsLettersState } from '../store/states/UtNewsLettersState';
import config from '../shared/config';

export const UtNewsLettersPage = ({ location }) => {
    const [nls, setNls] = useState(UtNewsLettersState);

    useEffect(() => {
        const fetchData = () => {
            Client(`newsletters`, 'GET', undefined, true)
                .then(({ data }) => {
                    setNls(data);
                })
                .catch(e => {
                    store.addNotification({
                        title: 'Error!',
                        message: `Có lỗi trong quá trình truy vấn`,
                        type: 'danger',
                        insert: 'bottom',
                        container: 'bottom-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 2000,
                            // onScreen: true
                        },
                    });
                });
        };
        fetchData();
    }, [location]);

    const getTableBody = () => {
        return nls.map((nl, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{nl.name}</td>
                    <td>{nl.email}</td>
                    <td>{nl.address}</td>
                    <td>{nl.subject}</td>
                    <td>{nl.content}</td>
                    <td>
                        {moment(nl.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                    </td>
                    <td>
                        <Button
                            theme="danger"
                            onClick={() => {
                                deleteNewsLetter(nl.id);
                            }}
                        >
                            Xóa
                        </Button>
                    </td>
                </tr>
            );
        });
    };

    const deleteNewsLetter = id => {
        Client(`newsletters/${id}`, 'DELETE', undefined, true)
            .then(({ data }) => {
                store.addNotification({
                    title: 'Success!',
                    message: `Đã xóa thành công`,
                    type: 'success',
                    insert: 'bottom',
                    container: 'bottom-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    },
                });
                setNls(nls.filter(nl => nl.id !== id));
            })
            .catch(e => {
                store.addNotification({
                    title: 'Error!',
                    message: `Có lỗi trong quá trình xóa`,
                    type: 'danger',
                    insert: 'bottom',
                    container: 'bottom-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    },
                });
            });
    };

    const downloadCsv = () => {
        // Open new tab to download csv. /newsletters.csv
        window.open(`${config.API_URL}/ut/api/newsletters.csv`, '_blank');
    };

    return (
        <Container fluid className="main-content-container px-4">
            <Row
                noGutters
                className="page-header py-4"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                }}
            >
                <PageTitle
                    title="News Letters"
                    subtitle="Danh sách Đăng ký bản tin"
                    className="text-sm-left mb-3"
                />
                {/* Fix max width editor */}
                <div style={{ width: '100%' }}>
                    <Card small className={'mb-4'}>
                        <CardHeader>
                            <Button
                                theme="success"
                                onClick={() => {
                                    downloadCsv();
                                }}
                            >
                                Download Danh sách
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <table
                                className="table mb-0"
                                style={{
                                    maxHeight: '500px',
                                    overflowY: 'scroll',
                                }}
                            >
                                <thead className="bg-light">
                                    <tr>
                                        <th scope="col" className="border-0">
                                            #
                                        </th>
                                        <th scope="col" className="border-0">
                                            Tên
                                        </th>
                                        <th scope="col" className="border-0">
                                            Email
                                        </th>
                                        <th scope="col" className="border-0">
                                            Địa chỉ
                                        </th>
                                        <th scope="col" className="border-0">
                                            Tiêu đề
                                        </th>
                                        <th scope="col" className="border-0">
                                            Nội dung
                                        </th>
                                        <th scope="col" className="border-0">
                                            Ngày đăng ký
                                        </th>
                                        <th scope="col" className="border-0">
                                            &nbsp;
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{getTableBody()}</tbody>
                            </table>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </Container>
    );
};
