import React, {useContext} from 'react';
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormGroup,
    FormInput
} from "shards-react";
import {HomeContext} from '../store/contexts/HomeContext';
import {
    EDIT_SOCIAL_LINKS,
    EDIT_OFFICES_INFO
} from '../store/reducers/HomeReducer';

const TagPageInfo = () => {
    const [homeData, dispatchHomeData] = useContext(HomeContext);

    function onChangeInput(e) {
        const {value, name} = e.target;
        if (name === 'ggAddressLink' || name === 'fbLink' || name === 'ytLink' || name === 'twitterLink' || name === 'vietnameseTags')
            dispatchHomeData({
                type: EDIT_SOCIAL_LINKS,
                payload: {name, value}
            })
        else
            dispatchHomeData(
                {
                    type: EDIT_OFFICES_INFO,
                    payload: {
                        indexOffice: 1,
                        nameOffice: name,
                        valueOffice: value
                    }
                }
            )
    }

    return (
        <Card className="mb-4">
            <CardHeader className="border-bottom">
                <h6 className="m-0">Page Info.</h6>
            </CardHeader>
            <ListGroup flush>
                <ListGroupItem className="p-3">
                    <Row>
                        <Col>
                            <Form>
                                {/*Home content*/}
                                <Row form>
                                    {/* Home title */}
                                    <Col md="6" className="form-group">
                                        <label htmlFor="feHomeTitle">Home
                                            Title</label>
                                        <FormInput
                                            name="homeTitle"
                                            id="feHomeTitle"
                                            type="tel"
                                            placeholder="Home Title Name"
                                            value={homeData.homeTitle}
                                            onChange={onChangeInput}
                                            required
                                        />
                                    </Col>
                                    {/* Home description */}
                                    <Col md="6" className="form-group">
                                        <label htmlFor="feHomeDescription">Home
                                            Description</label>
                                        <FormInput
                                            name="homeDescription"
                                            id="feHomeDescription"
                                            placeholder="Home Title Descrip Tion"
                                            value={homeData.homeDescription}
                                            onChange={onChangeInput}
                                        />
                                    </Col>
                                </Row>
                                <Row form>
                                    {/* Organization's Name */}
                                    <Col md="6" className="form-group">
                                        <label htmlFor="feOrganizationName">Organization
                                            Name</label>
                                        <FormInput
                                            name="organizationName"
                                            id="feOrganizationName"
                                            placeholder="Organization's Name"
                                            value={homeData.offices[0].organizationName}
                                            onChange={onChangeInput}
                                            autoComplete="current-password"
                                        />
                                    </Col>
                                    {/* Email */}
                                    <Col md="6" className="form-group">
                                        <label htmlFor="feEmail">Email</label>
                                        <FormInput
                                            name="email"
                                            type="email"
                                            id="feEmail"
                                            placeholder="Email Address"
                                            value={homeData.offices[0].email}
                                            onChange={onChangeInput}
                                        />
                                    </Col>
                                </Row>
                                <Row form>
                                    {/* Phone Number */}
                                    <Col md="6" className="form-group">
                                        <label htmlFor="fePhoneNumber">Phone
                                            Number</label>
                                        <FormInput
                                            name="phoneNumber"
                                            id="fePhoneNumber"
                                            type="tel"
                                            placeholder="+ 08 888 888"
                                            value={homeData.offices[0].phoneNumber}
                                            onChange={onChangeInput}
                                            required
                                        />
                                    </Col>
                                    {/* Fax */}
                                    <Col md="6" className="form-group">
                                        <label htmlFor="feFax">Fax</label>
                                        <FormInput
                                            name="fax"
                                            id="feFax"
                                            placeholder="Fax Number"
                                            value={homeData.offices[0].fax}
                                            onChange={onChangeInput}
                                        />
                                    </Col>
                                </Row>
                                {/*  Address*/}
                                <FormGroup>
                                    <label htmlFor="feAddress">Address</label>
                                    <FormInput
                                        name="address"
                                        id="feAddress"
                                        placeholder="Address"
                                        value={homeData.offices[0].address}
                                        onChange={onChangeInput}
                                    />
                                </FormGroup>
                                <Row form>
                                    <Col md="6" className="form-group">
                                        <label htmlFor="feOpenTime">Open
                                            Time</label>
                                        <FormInput
                                            name="openTime"
                                            id="feOpenTime"
                                            placeholder="Mon - Sat: 10 am - 6 pm | Sun: 12pm - 2 pm"
                                            value={homeData.offices[0].openTime}
                                            onChange={onChangeInput}
                                        />
                                    </Col>
                                    <Col md="6" className="form-group">
                                        <label htmlFor="feTags">Tags</label>
                                        <FormInput
                                            name="tags"
                                            id="feTags"
                                            placeholder="Tags"
                                            value={homeData.tags[0].content}
                                            onChange={onChangeInput}
                                        />
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md="6" className="form-group">
                                        <label htmlFor="feOpenTime">Google maps
                                            link</label>
                                        <FormInput
                                            name="ggAddressLink"
                                            id="feOpenTime"
                                            placeholder="https://goo.gl"
                                            value={homeData.ggAddressLink}
                                            onChange={onChangeInput}
                                        />
                                    </Col>
                                    <Col md="6" className="form-group">
                                        <label htmlFor="feOpenTime">Fan page
                                            link</label>
                                        <FormInput
                                            name="fbLink"
                                            id="feOpenTime"
                                            placeholder="https://www.facebook.com/"
                                            value={homeData.fbLink}
                                            onChange={onChangeInput}
                                        />
                                    </Col>
                                    <Col md="6" className="form-group">
                                        <label htmlFor="feOpenTime">Youtube channel
                                            link</label>
                                        <FormInput
                                            name="ytLink"
                                            id="feOpenTime"
                                            placeholder="https://youtube.com/channel"
                                            value={homeData.ytLink}
                                            onChange={onChangeInput}
                                        />
                                    </Col>
                                    <Col md="6" className="form-group">
                                        <label htmlFor="feOpenTime">Twitter page
                                            link</label>
                                        <FormInput
                                            name="twitterLink"
                                            id="feOpenTime"
                                            placeholder="https://twitter.com"
                                            value={homeData.twitterLink}
                                            onChange={onChangeInput}
                                        />
                                    </Col>
                                    <Col md={12} className="form-group">
                                        <label htmlFor="feOpenTime">Vietnamese tags (keywords)</label>
                                        <FormInput
                                            name="vietnameseTags"
                                            id="feOpenTime"
                                            placeholder="Rizo-lighting"
                                            value={homeData.vietnameseTags}
                                            onChange={onChangeInput}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </ListGroupItem>
            </ListGroup>
        </Card>
    );
}

export default TagPageInfo;
