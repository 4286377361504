import React, { useEffect, useState, useContext } from 'react';
import { HomeContext } from '../../store/contexts/HomeContext';
import Client from '../../shared/client';
import Select from 'react-select';
import { EDIT_TOP_PROJECTS } from '../../store/reducers/HomeReducer';
import _ from 'lodash';
import { UtHomeContext } from '../../store/contexts/UtHomeContext';
import { UT_EDIT_TOP_PROJECTS } from '../../store/reducers/UtHomeReducer';

export const UtEditTopProject = () => {
    const [utHomeData, dispatchUtHomeData] = useContext(UtHomeContext);
    const [optionSelected, setOptionSelected] = useState([]);
    const [options, setOptions] = useState([]);

    const fetchProjects = async () => {
        const { data } = await Client('business', 'GET', undefined, true);
        return data;
    };

    useEffect(() => {
        const _fetchProjects = async () => {
            const _projects = await fetchProjects();
            const _options = _projects.map(({ id, title }) => {
                return { value: id, label: title };
            });
            setOptions(_options);
        };
        _fetchProjects();
    }, []);

    useEffect(() => {
        const _optionSelected = utHomeData.topProjects.map(
            ({ value, label }) => {
                return { value, label };
            },
        );
        setOptionSelected(_optionSelected);
    }, [options, utHomeData]);

    const onOptionsChange = selectedOptions => {
        selectedOptions = selectedOptions || []; // selectedOptions === null nếu ko có phần tử được selected
        const results = selectedOptions.map(({ value }) => {
            return _.find(options, { value });
        });
        dispatchUtHomeData({
            type: UT_EDIT_TOP_PROJECTS,
            payload: results,
        });
    };

    const onFooterOptionChange = selectedOptions => {
        selectedOptions = selectedOptions || []; // selectedOptions === null nếu ko có phần tử được selected
        const results = selectedOptions.map(({ value }) => {
            return _.find(options, { value });
        });
        dispatchUtHomeData({
            type: UT_EDIT_TOP_PROJECTS,
            payload: results,
        });
    }

    return (
        <>
            <h5>Chọn các dự án để hiện thị trên home page!</h5>
            <Select
                options={options}
                isMulti={true}
                onChange={onOptionsChange}
                value={optionSelected}
            />
        </>
    );
};
