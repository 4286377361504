import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    ListGroup,
    ListGroupItem,
    Button,
    Container,
    Row,
    Col,
    Form,
    FormInput,
    FormCheckbox,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormTextarea
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import CustomFileUpload
    from "../components/components-overview/CustomFileUpload";
import Client from '../shared/client';
import TextEditor from "./@TextEditor";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import vi from 'date-fns/locale/vi';
import { Redirect } from "react-router";
import { store } from "react-notifications-component";
import { AuthContext } from "../store/contexts/AuthContext";
import qs from 'query-string';
registerLocale('vi', vi)

const AddUpdateNewsPage = ({ location }) => {
    const [id, setId] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [_title, setTitle] = useState('');
    const [_description, setDescription] = useState('');
    const [_content, setContent] = useState('');
    const [_status, setStatus] = useState(false);
    const [_picture_link, setPictureLink] = useState('');
    const [_published_time, setPublishedTime] = useState(null);
    const [modalStatus, setModalStatus] = useState(false);
    const [auth,] = useContext(AuthContext);
    const [_permission, setPermission] = useState(true);


    useEffect(() => {
        const { id: _id } = qs.parse(location.search);
        setId(_id);
    }, [location])

    useEffect(() => {
        const { id } = qs.parse(location.search);
        const _fetchData = async () => {
            if (id) {
                await Client("news/" + id, 'GET').then(({
                    data: {
                        news,
                        permission
                    }
                }) => {
                    setTitle(news.title);
                    setDescription(news.description);
                    setContent(news.content);
                    setStatus(news.status);
                    setPictureLink(news.picture_link);
                    setPermission(permission);
                    setPublishedTime(news.published_time ? new Date(news.published_time.slice(0, 10)) : null);
                }).catch((e) => {
                    setRedirect(true);
                });
            }
        }
        _fetchData();

    }, []);

    const onChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'file':
                setPictureLink(value);
                break;
            case 'title':
                setTitle(value);
                break;
            case 'description':
                setDescription(value);
                break;
            case 'content':
                setContent(value);
                break;
            case 'status':
                // const timeUpdate = value ? new Date(new Date().toString().split('GMT')[0] + 'UTC').toISOString().split('.')[0].replace('T', ' ') : null
                setStatus(value);
                if (!_published_time) {
                    setPublishedTime(new Date())
                }
                break;
            default:
                break;
        }
    }

    const save = async () => {
        if (!_title) {
            store.addNotification({
                title: `You must create least one title`,
                // message: `Error when ${!id ? "created" : "updated"} data`,
                message: `Error data format`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                }
            });
            return;
        }
        const data = {
            title: _title,
            description: _description,
            content: _content,
            picture_link: _picture_link,
            status: _status,
            creator: auth,
            published_time: _published_time ? new Date(_published_time.toString().split('GMT')[0] + 'UTC').toISOString().split('.')[0].replace('T', ' ') : null
        }
        let METHOD = id ? 'PATCH' : 'POST';
        if (id) {
            delete data.creator
        }
        await Client(`news/${id ? id : ""}`, METHOD, data).then(() => {
            store.addNotification({
                title: ` ${!id ? "Create " : "Update"} successful`,
                message: `News has been successfully ${!id ? "created" : "updated"}`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            });
            setTimeout(() => {
                setRedirect(true)
            }, 3000);
        }).catch((e) => {
            store.addNotification({
                title: `${!id ? "Create " : "Update"} data unsuccessful`,
                // message: `Error when ${!id ? "created" : "updated"} data`,
                message: `${e}`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        });
    }

    const onDelete = async () => {
        if (id) {
            await Client('news/' + id, 'DELETE').then(() => {
                store.addNotification({
                    title: ` Delete successful`,
                    message: `News has been successfully deleted`,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                });
                setTimeout(() => {
                    setRedirect(true)
                }, 3000);
            }).catch((e) => {
                store.addNotification({
                    title: `Delete data unsuccessful`,
                    // message: `Error when deleted data`,
                    message: `${e.message}`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            });
        }
    }
    const toggle = () => {
        setModalStatus(!modalStatus);
    }

    if (redirect) {
        return (<Redirect to='/edit-news' />)
    }

    return (
        <Container fluid className="main-content-container">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title={!id ? "Create News" : "Update News"}
                    subtitle="News &amp; Event Post"
                    className="text-sm-left" />
            </Row>

            <Row>
                {/* Editor */}
                <Col lg="9" md="12">
                    <Card small className="mb-3">
                        <CardBody>
                            <Form className="add-new-post">
                                <FormInput size="lg" className="mb-3"
                                    name="title" value={_title}
                                    placeholder="Your Post Title"
                                    onChange={(e) => {
                                        onChange(e)
                                    }} />
                                <FormTextarea size="lg" className="mb-3"
                                    name="description"
                                    value={_description}
                                    placeholder="Your Post Description"
                                    onChange={(e) => {
                                        onChange(e)
                                    }} />
                                <CustomFileUpload name="picture_link"
                                    link={_picture_link}
                                    onFileChange={(fileUrl) => {
                                        onChange({
                                            target: {
                                                name: 'file',
                                                value: fileUrl
                                            }
                                        })
                                    }} />
                                <TextEditor value={_content}
                                    onChange={onChange} />
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                {/* Sidebar Widgets */}
                <Col lg="3" md="12">
                    <Card small className="mb-3">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Actions</h6>
                        </CardHeader>

                        <CardBody className="p-0">
                            <ListGroup flush>
                                <ListGroupItem className="p-3">
                                    <span className="d-flex mb-2">
                                        <i className="material-icons mr-1">flag</i>
                                        <strong className="mr-1">Status:</strong>
                                        <strong className={_status ? "text-success" : "text-danger"}>{_status ? "Publish" : "Draft"}</strong>
                                        <FormCheckbox name="status" className="ml-5" checked={_status}
                                            onChange={(e) => {
                                                onChange({
                                                    target: {
                                                        name: 'status',
                                                        value: !_status
                                                    }
                                                })
                                            }} />
                                    </span>
                                    <span className="mb-2">
                                        <i className="material-icons mr-1">calendar_today</i>
                                        <strong
                                            className="mr-1">Publish_Time:</strong>
                                        <DatePicker selected={_published_time}
                                            onChange={date => setPublishedTime(date)}
                                            className="form-control"
                                            locale={vi}
                                            dateFormat="y-MM-dd"
                                        />
                                        {/* _published_time */}
                                    </span>
                                </ListGroupItem>
                                <ListGroupItem className="d-flex px-3 border-0">
                                    <Button disabled={!_permission}
                                        theme="primary" size="sm"
                                        onClick={() => {
                                            save()
                                        }}>
                                        <i className="material-icons">save</i> Save</Button>
                                    <Button disabled={!_permission}
                                        theme="danger" size="sm"
                                        className="ml-auto" onClick={() => {
                                            toggle()
                                        }}>
                                        <i className="material-icons">delete_forever</i>Delete</Button>
                                </ListGroupItem>
                            </ListGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal open={modalStatus} toggle={toggle} fade={false}>
                <ModalHeader>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete the news?</ModalBody>
                <ModalFooter className="justify-content-between">
                    <ListGroup flush>
                        <ListGroupItem className="border-0">
                            <Button theme="danger" size="sm" className="mx-5"
                                onClick={() => {
                                    onDelete()
                                }}>
                                <i className="material-icons">delete_forever</i>Delete</Button>
                            <Button outline theme="info" size="sm"
                                className="mx-5" onClick={() => {
                                    toggle()
                                }}>
                                <i className="material-icons">cancel</i> Cancel</Button>
                        </ListGroupItem>
                    </ListGroup>
                </ModalFooter>
            </Modal>
        </Container>
    )
};

export default AddUpdateNewsPage;
