import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "shards-react";
import config from '../../shared/config';
import Client from '../../shared/client';
import { store } from 'react-notifications-component';

const DeleteKindModal = ({ kindId, products, open, toggle }) => {
    const [btnText, setBtnText] = useState("I'm sure");
    const onDelete = async () => {
        try {
            if (window.confirm("[Dangerous] Confirm again? You cannot undo the deletions!")) {
                setBtnText('Deleting...');
                await Client(`pkind/${kindId}`, 'DELETE');
                setBtnText('Delete');
                toggle();
                store.addNotification({
                    title: "Delete successful",
                    message: `Product type and its products have been successfully deleted`,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
                setTimeout(() => {
                    window.location.reload();
                }, 500)
            }
        } catch (e) {
            store.addNotification({
                title: "Delete failed",
                message: `Can't delete product type`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        }
    }

    return (
        <Modal size="lg" open={open} toggle={toggle}>
            <ModalHeader>There are <b style={{ color: 'red' }}>{products.length} products</b> will be deleted! <br /> Are you sure to delete this?</ModalHeader>
            <ModalBody>
                <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    {products.map((product, index) => {
                        return <div key={index}>
                            <a href={`${config.API_URL}/product?model=${product.model}`} target="_blank"><b>[{product.model}]</b> {product.name}</a>
                            <br />
                        </div>
                    })}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button theme="danger" onClick={() => onDelete()}>{btnText}</Button>
                <Button theme="primary" onClick={() => toggle()}>Not now</Button>
            </ModalFooter>
        </Modal>
    )
}

export default DeleteKindModal;