import React, { useContext, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    Col,
    Form,
    FormInput,
    ListGroup,
    ListGroupItem,
    Row,
    FormCheckbox,
    Collapse,
} from 'shards-react';
import { UtHomeContext } from '../../store/contexts/UtHomeContext';
import {
    UT_ADD_HAPPY_CLIENT,
    UT_EDIT_HAPPY_CLIENT_AT_INDEX,
    UT_REMOVE_HAPPY_CLIENT_AT_INDEX,
    UT_TOGGLE_SHOW_HAPPY_CLIENTS,
} from '../../store/reducers/UtHomeReducer';
import CustomFileUpload from '../components-overview/CustomFileUpload';
export const UtHappyClients = () => {
    const [utHomeData, dispatchUtHomeData] = useContext(UtHomeContext);
    const [open, setOpen] = useState(false);
    const { happyClients } = utHomeData;

    const onItemChange = (e, index) => {
        const { value, name } = e.target;
        dispatchUtHomeData({
            type: UT_EDIT_HAPPY_CLIENT_AT_INDEX,
            payload: {
                index,
                key: name,
                value: value,
            },
        });
    };

    return (
        <Card className="mb-4">
            <CardHeader className="border-bottom">
                <Row
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 25px',
                        alignItems: 'center',
                    }}
                >
                    <div className="d-flex flex-row">
                        <FormCheckbox
                            toggle
                            checked={utHomeData.isShowHappyClients}
                            onChange={() =>
                                dispatchUtHomeData({
                                    type: UT_TOGGLE_SHOW_HAPPY_CLIENTS,
                                })
                            }
                        ></FormCheckbox>
                        <h6
                            className="m-0"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setOpen(!open)}
                        >
                            Happy Clients
                            <i className="material-icons">
                                {open ? 'expand_less' : 'expand_more'}
                            </i>
                        </h6>
                    </div>
                    <Button
                        theme="success"
                        onClick={() => {
                            dispatchUtHomeData({
                                type: UT_ADD_HAPPY_CLIENT,
                            });
                        }}
                        disabled={!utHomeData.isShowHappyClients}
                    >
                        Thêm
                    </Button>
                </Row>
            </CardHeader>
            <ListGroup flush>
                <Collapse open={open}>
                    <ListGroupItem
                        className="p-3"
                        style={{
                            maxHeight: '370px',
                            overflow: 'hidden scroll',
                        }}
                    >
                        {happyClients.map((item, index) => (
                            <Row key={index} className="mt-2">
                                <Col>
                                    <Card className="p-4">
                                        <Form>
                                            <Row>
                                                <Col
                                                    md="6"
                                                    className="form-group"
                                                >
                                                    <label htmlFor="feFirstName">
                                                        Tên doanh nghiệp/Tư nhân
                                                    </label>
                                                    <FormInput
                                                        id="feFirstName"
                                                        name="name"
                                                        value={item.name}
                                                        onChange={e =>
                                                            onItemChange(
                                                                e,
                                                                index,
                                                            )
                                                        }
                                                        disabled={
                                                            !utHomeData.isShowHappyClients
                                                        }
                                                    />
                                                </Col>
                                                <Col
                                                    md="6"
                                                    className="form-group"
                                                >
                                                    <label htmlFor="fecompanyUrl">
                                                        Liên kết URL
                                                    </label>
                                                    <FormInput
                                                        id="fecompanyUrl"
                                                        name="companyUrl"
                                                        value={item.companyUrl}
                                                        onChange={e =>
                                                            onItemChange(
                                                                e,
                                                                index,
                                                            )
                                                        }
                                                        disabled={
                                                            !utHomeData.isShowHappyClients
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    md="6"
                                                    className="form-group"
                                                >
                                                    <label htmlFor="felogo">
                                                        Logo
                                                    </label>
                                                    <CustomFileUpload
                                                        id="felogo"
                                                        name="logo"
                                                        endpoint="ut-uploader"
                                                        title="Kích thước ảnh 200x80 px"
                                                        fileName={item.logo}
                                                        link={item.fileUrl}
                                                        onFileChange={fileUrl => {
                                                            onItemChange(
                                                                {
                                                                    target: {
                                                                        name:
                                                                            'logo',
                                                                        value: fileUrl,
                                                                    },
                                                                },
                                                                index,
                                                            );
                                                        }}
                                                        disabled={
                                                            !utHomeData.isShowHappyClients
                                                        }
                                                    />
                                                </Col>
                                                <Col md="6"></Col>
                                                <Col md="11"></Col>
                                                <Col
                                                    md="1"
                                                    className="form-group"
                                                >
                                                    <Button
                                                        theme="danger"
                                                        onClick={() => {
                                                            dispatchUtHomeData({
                                                                type: UT_REMOVE_HAPPY_CLIENT_AT_INDEX,
                                                                payload: {
                                                                    index,
                                                                },
                                                            });
                                                        }}
                                                        disabled={
                                                            !utHomeData.isShowHappyClients
                                                        }
                                                    >
                                                        Xóa
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                        ))}
                    </ListGroupItem>
                </Collapse>
            </ListGroup>
        </Card>
    );
};
