/*
 * Created by @tranphuquy19 on 29/12/2020
 * @author: tranphuquy19@gmail.com
 */
export const AuthState = {
    id: '',
    email: '',
    iat: '',
    exp: '',
    roles: []
}
