// import { newsState, NewsState } from "../states/NewsState";

export const UT_FETCH_PRODUCTS = "UT_FETCH_PRODUCTS";
export const UT_FETCH_PRODUCTS_BY_ID = "UT_FETCH_PRODUCTS_BY_ID";
export const UT_ADD_PRODUCTS = "UT_ADD_PRODUCTS";
export const UT_UPDATE_PRODUCTS = "UT_UPDATE_PRODUCTS";
export const UT_DELETE_PRODUCTS = "UT_DELETE_PRODUCTS";

export const utProductsReducer = (state, { type, payload }) => {
    switch (type) {
        case UT_FETCH_PRODUCTS:
            state = payload;
            return state;
        case UT_FETCH_PRODUCTS_BY_ID:
            return {
                ...state
            }
        case UT_ADD_PRODUCTS:
            return {
                ...state
            };
        case UT_UPDATE_PRODUCTS:
            return {
                ...state
            };
        case UT_DELETE_PRODUCTS:
            return {
                ...state
            };
        default:
            return state;
    }
}
