/*
 * Created by @tranphuquy19 at 07/03/2021
 * @author: tranphuquy19@gmail.com
 */
import React, {useContext} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    FormInput,
    Row
} from "shards-react";
import CustomFileUpload from "../components-overview/CustomFileUpload";
import {DownloadsContext} from "../../store/contexts/DowloadsContext";
import {
    REMOVE_CATALOG_AT_INDEX,
    UPDATE_CATALOG_AT_INDEX
} from "../../store/reducers/DownloadsReducer";
import Client from "../../shared/client";
import {store} from "react-notifications-component";

const FileItem = ({download, index: itemIndex}) => {
    const [, dispatchDownloads] = useContext(DownloadsContext);

    const update = async () => {
        try {
            await Client(`download/${download.id}`, 'PATCH', download);
            store.addNotification({
                title: "Update successful",
                message: `The catalogue was updated successfully.`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        } catch (e) {
            store.addNotification({
                title: "Update catalogue failed",
                message: `Has some error while updating the catalogue`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        }
    }

    const create = async () => {
        try {
            await Client(`downloads`, 'POST', download);
            store.addNotification({
                title: "Create successful",
                message: `The catalogue was created successfully.`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: true
                }
            })
        } catch (e) {
            store.addNotification({
                title: "Create catalogue failed",
                message: `Has some error while creating the catalogue`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    // onScreen: true
                }
            });
        }
    }

    const del = async () => {
        if (window.confirm('Are you sure you want to delete this!')) {
            try {
                await Client(`download/${download.id}`, 'DELETE');
                store.addNotification({
                    title: "Delete successful",
                    message: `The catalogue was deleted successfully.`,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                })
            } catch (e) {
                store.addNotification({
                    title: "Delete catalogue failed",
                    message: `Has some error while deleting the catalogue`,
                    type: "danger",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        // onScreen: true
                    }
                });
            }
            dispatchDownloads({
                type: REMOVE_CATALOG_AT_INDEX,
                payload: itemIndex
            })
        }
    }

    const addNewFile = () => {
        dispatchDownloads({
            type: UPDATE_CATALOG_AT_INDEX,
            payload: {
                index: itemIndex,
                value: {
                    ...download,
                    files: [...download.files, {fileUrl: '', originalName: ''}]
                },
            }
        })
    }

    const renderFiles = () => {
        return download.files.map((item, fileIndex) => {
            return <Row key={fileIndex} style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
                marginBottom: '5px'
            }}>
                <Col sm={10} md={10} lg={10}>
                    <CustomFileUpload
                        marginText={{marginTop: '15px'}}
                        fileName={item.originalName}
                        link={item.fileUrl}
                        onFileChange={(fileUrl, originalName) => {
                            const _files = download.files;
                            _files[fileIndex] = {
                                fileUrl, originalName
                            }
                            dispatchDownloads({
                                type: UPDATE_CATALOG_AT_INDEX,
                                payload: {
                                    index: itemIndex,
                                    value: {
                                        ...download,
                                        files: _files
                                    },
                                }
                            })
                        }}
                    />
                </Col>
                <Col sm={2} md={2} lg={2}>
                    <Button theme="danger" onClick={() => {
                        const _files = download.files;
                        _files.splice(fileIndex, 1);
                        dispatchDownloads({
                            type: UPDATE_CATALOG_AT_INDEX,
                            payload: {
                                index: itemIndex,
                                value: {
                                    ...download,
                                    files: _files
                                },
                            }
                        })
                    }}>Remove</Button>
                </Col>
            </Row>
        })
    }

    return (
        <Card className={'mt-1'}>
            <CardBody style={{paddingBottom: 0}}>
                <Row>
                    <Col md={8} sm={8} lg={8}>
                        <FormInput type="text" placeholder="Title of catalogue"
                                   value={download.title}
                                   onChange={({target}) => {
                                       dispatchDownloads({
                                           type: UPDATE_CATALOG_AT_INDEX,
                                           payload: {
                                               index: itemIndex,
                                               value: {
                                                   ...download,
                                                   title: target.value
                                               },
                                           }
                                       })
                                   }}/>
                    </Col>
                    <Col md={4} sm={4} lg={4}>
                        <Button theme={'success'} onClick={() => addNewFile()}>Add
                            new file</Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={1} md={1} lg={1}
                         style={{display: 'flex', alignItems: 'center'}}>
                        <h6>Image</h6>
                    </Col>
                    <Col sm={11} md={11} lg={11}>
                        <CustomFileUpload
                            onFileChange={(fileUrl, originalName) => {
                                dispatchDownloads({
                                    type: UPDATE_CATALOG_AT_INDEX,
                                    payload: {
                                        index: itemIndex,
                                        value: {
                                            ...download,
                                            image: {
                                                fileUrl, originalName
                                            }
                                        }
                                    }
                                })
                            }}
                            fileName={download.image.originalName}
                            link={download.image.fileUrl}/>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={12} md={12} lg={12} style={{
                        border: '1px solid #eee',
                        borderRadius: '5px'
                    }}>
                        {renderFiles()}
                    </Col>
                </Row>
            </CardBody>
            <CardFooter style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            }}>
                <Button theme="primary"
                        onClick={() => !!download.id ? update() : create()}>{!!download.id ? 'Update' : 'Create'}</Button>
                <Button theme="danger" className="ml-1" onClick={() => {
                    del()
                }}>Delete</Button>
            </CardFooter>
        </Card>
    )
}

export default FileItem;
