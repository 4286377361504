import React, { useContext, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    Col,
    Form,
    FormInput,
    ListGroup,
    ListGroupItem,
    Row,
    FormCheckbox,
    Collapse,
} from 'shards-react';
import CustomFileUpload from '../components-overview/CustomFileUpload';
import { UtHomeContext } from '../../store/contexts/UtHomeContext';
import {
    UT_EDIT_TESTIMONIAL_AT_INDEX,
    UT_ADD_TESTIMONIAL,
    UT_REMOVE_TESTIMONIAL_AT_INDEX,
    UT_TOGGLE_TESTIMONIALS,
} from '../../store/reducers/UtHomeReducer';

export const UtTestimonials = () => {
    const [utHomeData, dispatchUtHomeData] = useContext(UtHomeContext);
    const [open, setOpen] = useState(false);

    const { testimonials } = utHomeData;

    const onItemChange = (e, index) => {
        const { value, name } = e.target;
        dispatchUtHomeData({
            type: UT_EDIT_TESTIMONIAL_AT_INDEX,
            payload: {
                index,
                key: name,
                value: value,
            },
        });
    };

    return (
        <Card className="mb-4">
            <CardHeader className="border-bottom">
                <Row
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 25px',
                        alignItems: 'center',
                    }}
                >
                    <div className="d-flex flex-row">
                        <FormCheckbox
                            toggle
                            checked={utHomeData.isShowTestimonials}
                            onChange={() =>
                                dispatchUtHomeData({
                                    type: UT_TOGGLE_TESTIMONIALS,
                                })
                            }
                        ></FormCheckbox>
                        <h6
                            className="m-0"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setOpen(!open)}
                        >
                            Nhận xét từ các đối tác
                            <i className="material-icons">
                                {open ? 'expand_less' : 'expand_more'}
                            </i>
                        </h6>
                    </div>
                    <Button
                        theme="success"
                        onClick={() => {
                            dispatchUtHomeData({
                                type: UT_ADD_TESTIMONIAL,
                            });
                        }}
                        disabled={!utHomeData.isShowTestimonials}
                    >
                        Thêm
                    </Button>
                </Row>
            </CardHeader>
            <ListGroup flush>
                <Collapse open={open}>
                    <ListGroupItem
                        className="p-3"
                        style={{
                            maxHeight: '370px',
                            overflow: 'hidden scroll',
                        }}
                    >
                        {testimonials.map((item, index) => {
                            return (
                                <Row key={index} className="mt-2">
                                    <Col>
                                        <Card className="p-4">
                                            <Form>
                                                <Row>
                                                    <Col
                                                        md="4"
                                                        className="form-group"
                                                    >
                                                        <label htmlFor="teName">
                                                            Tên đối tác [
                                                            {index + 1}]
                                                        </label>
                                                        <FormInput
                                                            id="teName"
                                                            name="name"
                                                            value={item.name}
                                                            onChange={e => {
                                                                onItemChange(
                                                                    e,
                                                                    index,
                                                                );
                                                            }}
                                                            disabled={
                                                                !utHomeData.isShowTestimonials
                                                            }
                                                        />
                                                    </Col>
                                                    <Col
                                                        md="4"
                                                        className="form-group"
                                                    >
                                                        <label htmlFor="tePosition">
                                                            Chức vụ
                                                        </label>
                                                        <FormInput
                                                            id="tePosition"
                                                            name="position"
                                                            value={
                                                                item.position
                                                            }
                                                            onChange={e => {
                                                                onItemChange(
                                                                    e,
                                                                    index,
                                                                );
                                                            }}
                                                            disabled={
                                                                !utHomeData.isShowTestimonials
                                                            }
                                                        />
                                                    </Col>
                                                    <Col
                                                        md="4"
                                                        className="form-group"
                                                    >
                                                        <label htmlFor="te">
                                                            Avatar
                                                        </label>
                                                        <CustomFileUpload
                                                            marginText={`mb-1`}
                                                            endpoint="ut-uploader"
                                                            title="image size: 80x80 px"
                                                            onFileChange={fileUrl => {
                                                                onItemChange(
                                                                    {
                                                                        target: {
                                                                            name:
                                                                                'avatar',
                                                                            value: fileUrl,
                                                                        },
                                                                    },
                                                                    index,
                                                                );
                                                            }}
                                                            disabled={
                                                                !utHomeData.isShowTestimonials
                                                            }
                                                            fileName={
                                                                item.avatar
                                                            }
                                                            link={item.fileUrl}
                                                        />
                                                    </Col>
                                                    <Col
                                                        md="12"
                                                        className="form-group"
                                                    >
                                                        <label htmlFor="teContent">
                                                            Lời nhận xét
                                                        </label>
                                                        <FormInput
                                                            id="teContent"
                                                            name="content"
                                                            value={item.content}
                                                            onChange={e => {
                                                                onItemChange(
                                                                    e,
                                                                    index,
                                                                );
                                                            }}
                                                            disabled={
                                                                !utHomeData.isShowTestimonials
                                                            }
                                                        />
                                                    </Col>
                                                    <Col md="11"></Col>
                                                    <Col
                                                        md="1"
                                                        className="form-group"
                                                    >
                                                        <Button
                                                            theme="danger"
                                                            onClick={() => {
                                                                dispatchUtHomeData(
                                                                    {
                                                                        type: UT_REMOVE_TESTIMONIAL_AT_INDEX,
                                                                        payload: {
                                                                            index,
                                                                        },
                                                                    },
                                                                );
                                                            }}
                                                            disabled={
                                                                !utHomeData.isShowTestimonials
                                                            }
                                                        >
                                                            Xóa
                                                        </Button>
                                                    </Col>
                                                    <hr />
                                                </Row>
                                            </Form>
                                        </Card>
                                    </Col>
                                </Row>
                            );
                        })}
                    </ListGroupItem>
                </Collapse>
            </ListGroup>
        </Card>
    );
};
