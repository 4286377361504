/*
 * Created by @tranphuquy19 on 28/02/2021
 * @author: tranphuquy19@gmail.com
 */
import React, {useContext} from 'react';
import {
    Card,
    CardHeader,
    Col,
    Form,
    ListGroup,
    ListGroupItem,
    Row
} from "shards-react";
import CustomFileUpload from "../components-overview/CustomFileUpload";
import {HomeContext} from "../../store/contexts/HomeContext";
import {
    EDIT_HEADER_IMAGES,
} from "../../store/reducers/HomeReducer";

export const HeaderPageImagesCard = () => {
    const [homeData, dispatchHomeData] = useContext(HomeContext);

    const onFileChange = (fileUrl, originalName, page) => {
        dispatchHomeData({
            type: EDIT_HEADER_IMAGES,
            payload: {
                page,
                image: {fileUrl, originalName}
            }
        })
    }

    const {
        contactPage,
        downloadPage,
        newsPage,
        projectPage,
        productPage,
        solutionPage,
    } = homeData.headerImages;

    return (
        <Card className="mb-4">
            <CardHeader className="border-bottom">
                <h6 className="m-0">Header Page Images</h6>
            </CardHeader>
            <ListGroup flush>
                <ListGroupItem className="p-3">
                    <Row>
                        <Col>
                            <Form>
                                <Row form>
                                    <Col md="6" sm="12" className="form-group">
                                        <label htmlFor="feHomeTitle">Contact page</label>
                                        <CustomFileUpload
                                            marginText={`mb-1`}
                                            onFileChange={(fileUrl, originalName) => onFileChange(fileUrl, originalName, 'contactPage')}
                                            fileName={contactPage.originalName}
                                            link={contactPage.fileUrl}/>
                                    </Col>
                                    <Col md="6" sm="12" className="form-group">
                                        <label htmlFor="feHomeTitle">Download page</label>
                                        <CustomFileUpload
                                            marginText={`mb-1`}
                                            onFileChange={(fileUrl, originalName) => onFileChange(fileUrl, originalName, 'downloadPage')}
                                            fileName={downloadPage.originalName}
                                            link={downloadPage.fileUrl}/>
                                    </Col>
                                    <Col md="6" sm="12" className="form-group">
                                        <label htmlFor="feHomeTitle">News page</label>
                                        <CustomFileUpload
                                            marginText={`mb-1`}
                                            onFileChange={(fileUrl, originalName) => onFileChange(fileUrl, originalName, 'newsPage')}
                                            fileName={newsPage.originalName}
                                            link={newsPage.fileUrl}/>
                                    </Col>
                                    <Col md="6" sm="12"
                                         className="form-group">
                                        <label htmlFor="feHomeTitle">Project page</label>
                                        <CustomFileUpload
                                            marginText={`mb-1`}
                                            onFileChange={(fileUrl, originalName) => onFileChange(fileUrl, originalName, 'projectPage')}
                                            fileName={projectPage.originalName}
                                            link={projectPage.fileUrl}/>
                                    </Col>
                                    <Col md="6" sm="12"
                                         className="form-group">
                                        <label htmlFor="feHomeTitle">Product page</label>
                                        <CustomFileUpload
                                            marginText={`mb-1`}
                                            onFileChange={(fileUrl, originalName) => onFileChange(fileUrl, originalName, 'productPage')}
                                            fileName={productPage.originalName}
                                            link={productPage.fileUrl}/>
                                    </Col>
                                    <Col md="6" sm="12"
                                         className="form-group">
                                        <label htmlFor="feHomeTitle">Solution page</label>
                                        <CustomFileUpload
                                            marginText={`mb-1`}
                                            onFileChange={(fileUrl, originalName) => onFileChange(fileUrl, originalName, 'solutionPage')}
                                            fileName={solutionPage.originalName}
                                            link={solutionPage.fileUrl}/>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </ListGroupItem>
            </ListGroup>
        </Card>
    )
}
