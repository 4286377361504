import React, {useEffect, useReducer} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {Container, Row} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import 'react-tabs/style/react-tabs.css';
import ProjectComponent from '../components/@project/@project-component';
import {ProjectTable} from "../components/@project/@project-table";
import {
    FETCH_PROJECTS,
    projectsReducer
} from "../store/reducers/ProjectsReducer";
import ProjectsContext from "../store/contexts/ProjectsContext";
import Client from "../shared/client";
import ProjectState from "../store/states/ProjectState";

const Project = ({history}) => {
    const [projectsData, dispatchProjectData] = useReducer(projectsReducer, []);

    useEffect(() => {
        const _fetchData = async () => {
            const {data} = await Client('project', 'GET');
            dispatchProjectData({
                type: FETCH_PROJECTS,
                payload: [...data]
            });
        }
        _fetchData();
    }, [])

    return (
        <ProjectsContext.Provider value={[projectsData, dispatchProjectData]}>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center'
                }}>
                    <PageTitle title="Projects" subtitle="Page Manager"
                               className="text-sm-left mb-3"/>
                    {/* <Button theme="primary" pill className="mr-3"
                    disabled={isDisableSave}
                    onClick={onSave}>Save
                        changes</Button> */}
                </Row>
                <Tabs>
                    <TabList>
                        <Tab>Projects</Tab>
                        <Tab>Create new project</Tab>
                    </TabList>
                    <TabPanel>
                        <ProjectTable history={history}/>
                    </TabPanel>
                    <TabPanel>
                        <ProjectComponent project={ProjectState}/>
                    </TabPanel>
                </Tabs>
            </Container>
        </ProjectsContext.Provider>
    )
}

export default Project;
